import plaa from "../configs/plaa-config";

export const fetchPLAA = async (input: RequestInfo, init?: RequestInit) => {
  const defaultInit: RequestInit = {
    headers: {
      'Authorization': plaa.config.ApiKeyValue,
      'Content-Type': 'application/json',
    }
  };
  init = { ...defaultInit, ...init };
  return fetch(input, init);
};
