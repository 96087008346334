import { useNavigate } from 'react-router-dom';
import { useState, useCallback, useEffect, useRef } from 'react';
import { usePolling } from '../hooks';
import { SEO } from '../../../components';
import { Button, Container, Modal } from '../../../components/Elements';
import { useAppDispatch, useAppSelector } from '../../../redux';

export const FeedbackStatus = () => {
  const [strokeDasharray, setStrokeDasharray] = useState('0 283');
  const navigate = useRef(useNavigate());
  const dispatch = useAppDispatch();
  const {
    websocket,
    feedbackTimer: { timeElapsed },
    videoUpload: { status, lastRecording },
  } = useAppSelector((state) => state);
  const [startPolling, maxAttemptsExceeded] = usePolling(3, 15);

  const calculateStrokeDasharray = useCallback(() => {
    if (timeElapsed < 1 || !lastRecording) return;
    const { processingTime } = lastRecording;

    const rawTimeFraction = timeElapsed / processingTime;
    const timeFraction = rawTimeFraction - (1 / processingTime) * (1 - rawTimeFraction);
    setStrokeDasharray(`${(timeFraction * 283).toFixed(0)} 283`);
  }, [timeElapsed, lastRecording]);

  // Update the animation based on the time remaining processing time
  useEffect(() => {
    calculateStrokeDasharray();
  }, [calculateStrokeDasharray]);

  // Manually fetch the feedback if the calculated processing time finishes before
  // getting the message data from the websocket
  useEffect(() => {
    if (lastRecording && timeElapsed >= lastRecording.processingTime) {
      // If MMAI is still processing Feedback when the Processing Time completes
      // Start polling until we get a success,fail, or exceed that max poll attempts
      startPolling();
    }
  }, [timeElapsed, lastRecording, dispatch, startPolling]);

  // If the AiFeedback has been set, route to AiFeedback
  useEffect(() => {
    if (
      websocket &&
      websocket.messageData &&
      (websocket.messageData.status === 'Accepted' || websocket.messageData.status === 'Done') &&
      websocket.messageData.filename === lastRecording?.fileName.split('.')[0]
    ) {
      navigate.current('/feedback/ai-feedback');
    }
  }, [websocket, lastRecording]);

  return (
    <Container className="grid justify-items-center">
      {lastRecording && (
        <SEO
          title={`Processing: ${
            timeElapsed <= lastRecording.processingTime &&
            Math.floor((timeElapsed / lastRecording.processingTime) * 100)
          }%`}
        />
      )}

      <Modal isOpen={maxAttemptsExceeded} onClose={() => {}}>
        <div className="grid justify-items-center text-center">
          <h3 className="text-center text-xl font-bold">
            An error occurred while processing your feedback
          </h3>
          <Button className="mt-8 w-full max-w-[320px]" onClick={() => navigate.current('/record')}>
            Back to Mock Interview
          </Button>
        </div>
      </Modal>

      <p className="mt-[30px] max-w-[357px] text-center text-lg text-navy-500">
        Nice work on reviewing your performance. Your personalized feedback is on it's way!
      </p>

      {status === 'succeeded' && (
        <div className="relative mt-14">
          <svg
            className=" w-[222px] -scale-x-100"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g className="fill-none stroke-none">
              <circle className="stroke-[#99A9b8] stroke-[10px]" cx="50" cy="50" r="45" />
              <path
                id="base-timer-path-remaining"
                strokeDasharray={strokeDasharray}
                className={`stroke origin-center rotate-90 stroke-primary stroke-[10px] transition-all duration-1000 ease-linear`}
                style={{
                  strokeLinecap: 'butt',
                }}
                d="
                M 50, 50
                m -45, 0
                a 45,45 0 1,0 90,0
                a 45,45 0 1,0 -90,0
              "
              ></path>
            </g>
          </svg>

          {lastRecording && timeElapsed > 0 && (
            <div className="absolute top-1/2 left-1/2 z-50 w-[160px] -translate-x-1/2  -translate-y-1/2 text-navy-600">
              <p className="text-center leading-none">
                <span className="text-[50px] font-bold">
                  {timeElapsed < lastRecording.processingTime &&
                    Math.floor((timeElapsed / lastRecording.processingTime) * 100)}
                  {timeElapsed >= lastRecording.processingTime && 99}
                </span>

                <span className="absolute top-3 text-[36px] font-light">%</span>
              </p>
              {timeElapsed >= lastRecording.processingTime && (
                <p className="mt-1 text-center leading-tight">Just a few more seconds...</p>
              )}
            </div>
          )}
        </div>
      )}
    </Container>
  );
};
