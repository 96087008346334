import { useEffect } from 'react';
import { useTrackEvent } from '../../../hooks';
import { Container } from '../../../components/Elements';
import { HistoryList } from '../components';

export const VideoArchive = () => {
  const { trackEvent } = useTrackEvent();

  useEffect(() => {
    trackEvent('interview_archive', 'archive_start');
  }, [trackEvent]);

  return (
    <section>
      <Container className="mt-[73px] grid justify-items-center">
        <h1 className="text-center text-2xl uppercase tracking-wide text-navy-500">
          Interview Archive
        </h1>

        <HistoryList />
      </Container>
    </section>
  );
};
