import { ReactNode } from 'react';
import { FieldError } from 'react-hook-form';

const variants = {
  white: 'text-white text-lg',
  navy: 'text-navy-700',
};

type FieldWrapperProps = {
  label?: string;
  className?: string;
  children: ReactNode;
  error?: FieldError | undefined;
  variant?: keyof typeof variants;
};

export type FieldWrapperPassThroughProps = Omit<FieldWrapperProps, 'className' | 'children'>;

export const FieldWrapper = (props: FieldWrapperProps) => {
  const { label, className = '', children, variant = 'navy' } = props;
  return (
    <div className={className}>
      <label htmlFor={label} className={`ml-3 font-public-sans font-light ${variants[variant]}`}>
        {label}
      </label>
      <div className="relative mt-[6px]">{children}</div>
    </div>
  );
};
