import { PersonalizedFeedback } from "../types"

export const getFillerWordFeedback = (fillerScore: string): PersonalizedFeedback => {
  const heading = 'Filler Words';
  const optimalLowerBound = 0;
  const optimalUpperBound = 6;
  const fillerScoreNum = Math.round(+fillerScore);
  const subHeading = `You used filler words approximately `

  const staticFields = {
    heading,
    subHeading,
    optimalLowerBound,
    optimalUpperBound,
    percentageScore: fillerScoreNum,
  };

  if (+fillerScore <= 4) {
    return {
      ...staticFields,
      icon: 'optimal',
      scoreMessage: 'excellent',
      tipHeader: 'Good Job!',
      tips: [
        'Great job regulating your word choice! Using filler words can distract your audience and impact your credibility.',
        'An intentional pause on the other hand, can make you appear more deliberate and thoughtful!'
      ],
    };
  } else if (+fillerScore <= 6) {
    return {
      ...staticFields,
      icon: 'optimal',
      scoreMessage: 'acceptable',
      tipHeader: 'How To Improve',
      tips: [
        'Your usage of filler words is  normal and would not be considered as distracting.',
        'To improve, continue to be mindful of your words as you speak.',
      ],
    };
  } else if (+fillerScore <= 8) {
    return {
      ...staticFields,
      icon: 'almost',
      scoreMessage: 'almost',
      tipHeader: 'How To Improve',
      tips: [
        'Rewatch your video and notice the filler words you are using.',
        'For your next recording slow down your speech so you can be more aware of what you are saying.',
      ],
    };
  } else {
    return {
      ...staticFields,
      icon: 'high',
      scoreMessage: 'too high',
      tipHeader: 'Interview Advice',
      tips: [
        'Using filler words too often can distract your audience and make you appear less credible.',
        'Try pausing briefly before your next recording to collect your thoughts and reduce your nervousness.'
      ],
    };
  }
};