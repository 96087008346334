import { ReactComponent as HeaderText } from '../assets/header-authentic.svg';
import { ReactComponent as HeaderText2 } from '../../../assets/icons/app_logo.svg';
import { ReactComponent as DescriptionText } from '../assets/header-description.svg';

export const LoginHeader = () => {
  return (
    <h1 className="mt-10 flex flex-col items-center text-center text-blue-100">
      <span className="sr-only text-8xl">Authentic Interview Prep</span>
      <HeaderText />
      {/* <HeaderText2 className='w-64 h-64'/> */}
      <span className="sr-only text-lg">Your interview practice companion</span>
      <DescriptionText className="mt-3" />
    </h1>
  );
};
