/**
 * Returns an estimated MMAI processing time.
 *
 * @param videoDuration in seconds
 * @returns processing time in seconds
 */
export const determineProcessingTime = (videoDuration: number): number => {
  const padding = 10;

  if (videoDuration < 60) {
    return Math.round(videoDuration * 0.75 + padding);
  }

  return Math.round(videoDuration * 0.45 + padding);
};

const miscUtils = { determineProcessingTime };

export default miscUtils;
