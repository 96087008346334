import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

const variants = {
  red: 'text-red-600',
  yellow: 'text-yellow-300',
};

type AuthErrorProps = {
  message: string;
  variant?: keyof typeof variants;
  className?: string;
};

export const AuthError = ({ message, variant = 'red', className = '' }: AuthErrorProps) => {
  return (
    <p className={`mb-3 ml-2 flex text-lg ${variants[variant]} ${className}`}>
      <ExclamationTriangleIcon className="mr-1 h-8 w-8 min-w-[27px]" />
      <span>{message}</span>
    </p>
  );
};
