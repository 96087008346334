import moment from "moment-timezone";

export const locale = window.navigator.language;
export const platform = window.navigator.platform;
export const timezone = () => moment.tz.guess();
export const isSafari =
  navigator.userAgent.indexOf("Safari") !== -1 &&
  navigator.userAgent.indexOf("Chrome") === -1;
export const isIOS = () => {};
export const mimeType = isSafari ? `video/mp4` : `video/webm;codecs=h264`;

const info = {
  locale,
  platform,
  timezone,
  isSafari,
  mimeType,
};

export default info;
