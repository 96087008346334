import {
  ChatBubbleLeftEllipsisIcon,
  ClockIcon,
  FilmIcon,
  ArrowRightOnRectangleIcon,
  ArrowPathIcon,
} from '@heroicons/react/24/solid';

type SideActionsBarProps = {
  promptHeight: number;
  onShowPrompts: () => void;
  onShowDuration: () => void;
  onShowTimer: () => void;
  countdownTime: number;
  availableDeviceIds: string[];
  onSwitchCamera?: () => void;
  onShowExit: () => void;
};

export const SideActionsBar = ({
  promptHeight,
  onShowPrompts,
  onShowDuration,
  onShowTimer,
  countdownTime,
  onSwitchCamera,
  availableDeviceIds,
  onShowExit,
}: SideActionsBarProps) => {
  const handleSwitchCamera = () => {
    if (onSwitchCamera) {
      onSwitchCamera();
    }
  };

  return (
    <div
      style={{ top: promptHeight || 0 }}
      className="absolute right-1 z-30 mt-2 rounded-full bg-black/40 px-1 py-4"
    >
      <ul className="grid gap-4">
        <li>
          <button onClick={onShowPrompts} className="grid justify-items-center gap-[2px]">
            <ChatBubbleLeftEllipsisIcon className="w-7" />
            <label className="text-xs font-semibold tracking-wide">Prompts</label>
          </button>
        </li>

        <li>
          <button onClick={onShowDuration} className="grid w-full justify-items-center gap-[2px]">
            <FilmIcon className="w-7" />
            <label className="text-xs font-semibold tracking-wide">Duration</label>
          </button>
        </li>

        <li>
          <button onClick={onShowTimer} className="grid w-full justify-items-center gap-[2px]">
            <div className="relative">
              <ClockIcon className="w-7" />
              <span
                style={{ textShadow: '1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000' }}
                className="absolute bottom-0 right-0 z-30 translate-y-2 translate-x-1/4 font-bold text-white"
              >
                {countdownTime / 1000}
              </span>
            </div>
            <label className="text-xs font-semibold tracking-wide">Timer</label>
          </button>
        </li>

        {availableDeviceIds && availableDeviceIds.length >= 2 && (
          <li>
            <button
              onClick={handleSwitchCamera}
              className="grid w-full justify-items-center gap-[2px]"
            >
              <ArrowPathIcon className="w-7" />
              <label className="text-xs font-semibold tracking-wide">Flip</label>
            </button>
          </li>
        )}

        <li className='sm:hidden'>
          <button onClick={onShowExit} className="grid w-full justify-items-center gap-[2px]">
            <ArrowRightOnRectangleIcon className="w-7" />
            <label className="text-xs font-semibold tracking-wide">Exit</label>
          </button>
        </li>
      </ul>
    </div>
  );
};
