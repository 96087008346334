import { configureStore, combineReducers } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { websocketReducer } from './reducers';

import {
  videoUploadSlice,
  feedbackTimerSlice,
  userSlice,
  promptSlice,
  userHistorySlice,
} from './slices';

// Middleware
import websocketMiddleware from './middleware/websocket';

const middleware = [websocketMiddleware, 
  // logger
];

// Redux Persist selected prompt
const promptPersistConfig = {
  key: 'selectedPrompt',
  storage,
  whitelist: ['selectedPrompt'],
};

export const rootReducer = combineReducers({
  websocket: websocketReducer,

  prompts: persistReducer(promptPersistConfig, promptSlice.reducer),
  user: userSlice.reducer,
  feedbackTimer: feedbackTimerSlice.reducer,
  videoUpload: videoUploadSlice.reducer,
  userHistory: userHistorySlice.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);
