import { useRef, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { SEO } from '../../../components';
import { useAuth } from '../context';
import { Button, InputField, FieldError } from '../../../components/Elements';
import { AuthHeading, AuthError, BackToLogin } from '../components';

type FormValues = {
  username: string;
};

export const ResetPassword = () => {
  const {
    resetForgotPassword,
    sendForgotPasswordCode,
    loginState: { isLoggedIn },
    forgotPasswordState: { searchingUser, errorMessage, forgotPasswordError },
  } = useAuth();

  const navigate = useRef(useNavigate());

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormValues>();

  useEffect(() => {
    if (isLoggedIn) navigate.current('/auth-redirect');

    return () => resetForgotPassword();
  }, [isLoggedIn, resetForgotPassword]);

  const resetPasswordHandler: SubmitHandler<FormValues> = ({ username }) => {
    sendForgotPasswordCode(username);
  };

  return (
    <main className="relative min-h-screen bg-white">
      <SEO title="Reset Password" />

      <BackToLogin />

      <div className="container mx-auto grid place-items-center px-3">
        <AuthHeading>Reset Password</AuthHeading>

        <form className="mt-11 w-full max-w-[334px]" onSubmit={handleSubmit(resetPasswordHandler)}>
          {forgotPasswordError && <AuthError message={errorMessage} />}

          <InputField
            label="User Name*"
            registration={register('username', {
              required: true,
            })}
          />
          {errors.username?.type === 'required' && <FieldError message="User name is required" />}

          <p className="mt-4 font-raleway">
            We will send a verification code to the email or phone number on file.
          </p>

          <Button className="mt-8 w-full" isLoading={searchingUser} type="submit">
            Send Code
          </Button>
        </form>
      </div>
    </main>
  );
};
