export const data = {
    PageHeader: `END USER LICENSE AGREEMENT`,
    Updated: `Last Updated: January 25, 2022`,
    Intro: `This End User License Agreement (“EULA”) governs the use and provision of the mobile application, current known as “AunthentiC” and any successor versions, including all content, media, features, functionalities and any other components and/or software modules included or made available as part of such application and any upgrades or updates to the application that may be made available from time to time (together, the “App”).  This EULA is a legally binding agreement between the user of the App (“you”) and Educational Testing Service (“ETS”). `,
    Intro2: `BY INSTALLING, DOWNLOADING, ACCESSING OR USING THE APP, YOU ARE AGREEING TO BE LEGALLY BOUND BY THE TERMS OF THIS EULA.  IF YOU DO NOT AGREE TO BE BOUND BY THE TERMS OF THIS EULA, YOU THEN HAVE NO RIGHTS TO THE APP AND SHOULD THEREFORE NOT INSTALL, DOWNLOAD, ACCESS OR USE THE APP.`,
    UsageHeader: `USAGE DATA`,
    UsageText: `The App will collect data as part of your use of the App.  This data will consist of your 
    interactions with the App and any information or content that you upload, submit, send or receive within 
    the App (“Usage Data”). You retain ownership of any intellectual property rights that you hold in that 
    Usage Data, except that you hereby grant to ETS and its subcontractors and vendors a worldwide, non-exclusive, 
    perpetual and irrevocable license to use, host, store, reproduce, modify, create derivative works, 
    communicate, publish, publicly perform, publicly display and distribute such Usage Data for the purpose 
    of providing you the App and improving and developing the App and ETS’s other existing or new products 
    and services.  Our use of the Usage Data will be in accordance with, and you hereby consent to our use of 
    your Usage Data in accordance with, this EULA, ETS’s Privacy Policy `,
    UsageLink: `https://www.ets.org/legal/privacy `,
    UsageText2: `(“ETS Privacy Policy”) and any written consent form that is made available to you in 
    connection with your use of the App (“Consent Form”).`,
    UsageText3: `ETS may (itself or have a third party, through manual or automated means) modify Usage Data to remove any personal information or other information that may be used to identify you or any other individual to create de-identified Usage Data.  You authorize ETS to use and exploit such de-identified Usage Data for any lawful purpose, and you disclaim all rights or interests in the de-identified Usage Data (including any rights to get paid).`,
    UsageText4: `ETS may request that you submit additional information that may include whether you are a student, 
    your proficiency in English, your feedback or other demographic data. Providing such information is entirely 
    voluntary and is not required or necessary for your use of the App. If you elect to provide such information, 
    such information shall be considered de-identified Usage Data.`,
    GeneralDataUsageHeader: `General Data Usage:`,
    GeneralDataUsageText: `The data collected from this app will be used in accordance with ETS’ Privacy Notice 
    (https://www.ets.org/legal/privacy) for the following purposes: (1) to improve the app; (2) for research purposes; 
    and (3) creating reports containing de-identified and aggregated data.  By using this app, you acknowledge and 
    accept that your feedback may by quoted (without your name or identifying information) in summary reports aimed 
    at understanding the app’s effectiveness.`,
    VideoDataUsageHeader: `Video Data Usage`,
    VideoDataText: `As a general user of this app and/or as a research participant using this app, I give permission to: (1) include my image and voice in video-recordings for use by ETS staff and project partners (e.g., for assessment development, product development, research, and/or training purposes); (2) include my image and voice in video-recordings for public distribution (e.g., for use in presentations at conferences, at other public presentations, and for professional development); and (3) include my image and voice in video-recordings that are shared with other users of this mobile app for the purpose of, but not limited to, providing example responses and solicited peer responses.  In all the beforementioned situations, I understand that my name will not appear with my video data.`,
    AccountHeader: `YOUR APP ACCOUNT`,
    AccountText: `You may need to set up and use an account to use the App. To protect your account, it is your 
    responsibility to keep your password and username confidential. You are responsible for the activity that 
    happens on or through your App account. You must ensure that any account information you submit to ETS is and 
    remains true, accurate, and complete.
    \nIf you are under 16 years of age, your must have the consent of your parent or legal guardian to create an 
    account to use the App on your behalf and you must only use the App with the consent or under the supervision, 
    as required by all applicable laws, of your parent or legal guardian who agrees to be bound by the terms of this 
    EULA and the ETS Privacy Notice.
    \nWe may suspend or permanently disable or delete your App account if we determine you have breached this EULA. 
    Please contact ETS if you believe ETS has disabled your account by mistake.`,
    LicenseHeader: `LICENSE GRANT; OWNERSHIP`,
    LicenseText: `Subject to the terms of this EULA and your compliance with the terms of this EULA, ETS grants you a 
    limited, non-exclusive, non-sublicensable and non-transferable license to download, install and use the App solely 
    in the form made available to you by ETS, for your personal and non-commercial use exclusively for your own benefit, 
    on a mobile device owned or otherwise controlled by you, strictly in accordance with this EULA and any App documentation 
    made available to you to you.`,
    LicenseText2: `The App is licensed, not sold, to you.  ETS reserves and retains its entire right, title and interest in 
    and to the App, including all rights therein or relating thereto, except as expressly granted to you in the above paragraph.`,
    RestrictionsHeader: `RESTRICTIONS ON USE`,
    RestrictionsText: `In addition, you may not and may not attempt to:`,
    RestrictionsText2: `Share, distribute, lend, lease, sell, sublicense, rent, assign, publish, transfer or otherwise make available the App, 
    in any manner whatsoever, to any third party for any reason (including by making the App available on a network);`,
    RestrictionsText3: `Modify, translate, adapt or otherwise create derivative works or improvements of the App;`,
    RestrictionsText4: `Reverse engineer, decompile, disassemble, decode or otherwise attempt to derive or gain access to the source code of the App;`,
    RestrictionsText5: `Remove, disable, circumvent or otherwise create or implement any workaround to any copy protection, rights management or 
    security features in or protecting the App;`,
    RestrictionsText6: `Attempt to alter, delete, obscure or remove any trademark, copyright, patent or other intellectual property or proprietary 
    rights notice contained within the App; or`,
    RestrictionsText7: `Make use of the App in any manner not stipulated within this EULA or the documentation accompanying the App.`,
    UpdatesHeader: `CHANGES; UPDATES`,
    UpdatesText: `ETS may add or remove functionalities or features, and ETS may suspend or stop providing the App altogether at any time.`,
    UpdatesText2: `ETS may find the need from time to time to make available for the App, certain updates, upgrades, bug fixes, patches, 
    other error corrections and new features (“Updates”). Updates may modify or delete in their entirety certain features and functionality 
    of the App. ETS has no obligation to provide Updates or to continue to provide or enable any particular features or functionality. 
    ETS may make Updates available to you conditional upon your acceptance of another agreement or additional terms.`,
    UpdatesText3: `Based on your device settings, when your device is connected to the internet either (a) the App will automatically 
    download and install all available Updates; or (b) you may receive notice of or be prompted to download and install available Updates. 
    You shall promptly download and install all Updates and acknowledge and agree that the App or portions thereof may not properly operate 
    should you fail to do so.  All Updates will be deemed part of the App and be subject to all terms of this EULA and any additional terms 
    provided with the Updates.`,
    TerminationHeader: `TERM AND TERMINATION`,
    TerminationText: `The term of this EULA commences when you acknowledge your acceptance of this EULA and will continue in effect until 
    terminated as set forth in this paragraph.  If you breach the EULA at any time, this EULA will terminate immediately and automatically 
    without any notice.  ETS may also terminate the EULA at any time without notice. Upon termination, all rights granted to you under the 
    EULA will terminate and you must cease all use of the App and delete all copies of the App from your device. Termination will not limit 
    any of ETS’s rights or remedies at law or in equity.`,
    DisclaimerHeader: `DISCLAIMER OF WARRANTY`,
    DisclaimerText: `TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW: (a) THE APP IS PROVIDED TO YOU ON AN “AS IS” AND “AS AVAILABLE” BASIS; 
    (b) ETS MAKES NO REPRESENTATIONS OR WARRANTIES WITH RESPECT TO THE APP OR THE RESULTS OF USING THE APP AND ASSUMES NO RESPONSIBILITY FOR 
    THE CONSEQUENCES OF ANY ERRORS OR OMISSIONS IN CONNECTION WITH YOUR USE OF THE APP AND DISCLAIMS ALL WARRANTIES, REPRESENTATIONS AND 
    GUARANTEES OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THOSE ACCURACY, TIMELINESS, TITLE, COMPLETENESS, USEFULNESS, 
    NON-INFRINGEMENT, MERCHANTABILITY OR FITNESS FOR ANY PARTICULAR PURPOSE OR ANY ARISING FROM COURSE OF PERFORMANCE, COURSE OF DEALING OR USAGE OF TRADE.`,
    DisclaimerText2: `WITHOUT LIMITATION TO THE FOREGOING, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ETS DOES NOT WARRANT THAT THE OPERATION OF THE 
    APP WILL MEET YOUR REQUIREMENTS OR ACHIEVE ANY INTENDED RESULTS, BE ERROR FREE OR UNINTERRUPTED OR BE PROVIDED OR AVAILABLE WITHOUT INTERRUPTION, OR 
    THAT DEFECTS OR ERRORS CAN OR WILL BE CORRECTED OR THE APPROPRIATENESS OF THE APP FOR ANY PARTICULAR SYSTEM OR ACCURACY OF THE APP’S CONTENT OR RESULTS 
    OR THAT THE USE OF THE APP WILL NOT CAUSE DAMAGE TO YOUR DEVICE, INTERFERE WITH THE USE OF YOUR DEVICE OR CAUSE LOSS OF USAGE DATA OR OTHER DATA FROM YOUR DEVICE.`,
    ExclusionHeader: `EXCLUSION AND LIMITATION OF LIABILITY `,
    ExclusionText: `TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL ETS BE LIABLE FOR ANY INDIRECT, CONSEQUENTIAL, INCIDENTAL, EXEMPLARY, PUNITIVE 
    OR SPECIAL DAMAGES OR LOSS OF PROFITS, LOSS OF GOODWILL, MOBILE DEVICE FAILURE OR MALFUNCTION, LOSS OF CONTRACTS, BUSINESS INTERRUPTIONS, OR LOSS OF OR CORRUPTION 
    OF DATA, HOWEVER CAUSED AND WHETHER ARISING UNDER CONTRACT OR TORT OR OTHERWISE, INCLUDING NEGLIGENCE, AND ANY LOSS OR DAMAGE RELATED TO ANY THIRD-PARTY SOFTWARE, 
    THAT ARISES OUT OF OR RELATES TO YOUR USE OF THE APP.`,
    ExclusionText2: `TO THE EXTENT ETS’S LIABILITY HAS NOT BEEN EXCLUDED ABOVE, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE TOTAL AGGREGATE AMOUNT THAT ETS
    SHALL BE LIABLE FOR UNDER THIS AGREEMENT SHALL BE LIMITED TO A TOTAL AMOUNT EQUAL TO GREATER OF THE AMOUNT YOU PAID FOR THE APP (INCLUDING ANY IN-APP PURCHASES) 
    IN THE TWELVE (12) MONTH PERIOD PRECEDING THE DATE THE FIRST EVENT THAT GAVE RISE TO THE CLAIM (IF ANY) AND FIFTY U.S. DOLLARS (USD 50).`,
    ComplianceHeader: `COMPLIANCE WITH LAWS`,
    ComplianceText: `You will comply with all applicable laws when you use the App, including export laws, restrictions and all regulations of the U.S. Department 
    of Commerce, U.S. Department of Treasury, and any other U.S. or foreign agency or authority. You shall not  export or re-export, nor allow the export or 
    re-export of the App in violation of any such law, restriction or regulation, including export or re-export to any country subject to any and all applicable U.S. 
    trade embargoes or to any prohibited destination, in any group specified in the current “Supplement No. 1 to Part 740 of the Commerce Control List specified 
    in the then current Supplement No. 1 to Part 738 of the U.S. Export Administration Regulations (or any successor supplement or regulations).” `,
    AssignmentHeader: `ASSIGNMENT`,
    AssignmentText: `You may not transfer or assign any part of this EULA to a third party without ETS’s prior written consent. ETS may transfer 
    or assign any part of this EULA or its entirety to a third party without providing notice to you and without your consent. Subject to the foregoing, 
    this EULA shall be legally binding upon and inure to the benefit of ETS and you and each of our respective successors and permitted assigns.`,
    WaiverHeader: `SEVERABILITY; WAIVER`,
    WaiverText: `Should any part of this EULA be deemed invalid or unenforceable, such determination will not affect the validity or enforceability 
    of any other part of this EULA.  If there is any waiver of any breach or failure to enforce any of the provisions of this EULA, it shall not be deemed 
    as a future waiver of such provisions or a waiver of any other provision of this EULA.`,
    EulaHeader: `MODIFICATIONS TO THIS EULA`,
    EulaText: `ETS may modify this EULA or any additional terms that apply to the App, for example, to reflect changes to the App or the availability of the App. 
    It is your responsibility to look for changes to this EULA on a regular basis. However, ETS will endeavor to post a notice of modifications to this EULA 
    on this page or on the App. Unless otherwise stated in such notice, changes will be effective immediately. If you do not agree to any such changes, 
    you should immediately discontinue your use of the App.`,
    GoverningHeader: `GOVERNING LAW AND VENUE`,
    GoverningText: `The laws of New Jersey, U.S.A., excluding New Jersey’s conflict of laws rules, will apply to any disputes arising out of or relating to this 
    EULA or the App. All claims arising out of or relating to this EULA or the App will be litigated exclusively in the federal or state courts of New Jersey, 
    and you and ETS consent to personal jurisdiction in those courts.`
}