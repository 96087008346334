import { useEffect, useState, useCallback } from 'react';
import { EyeIcon, EyeSlashIcon, DocumentTextIcon, UserIcon } from '@heroicons/react/24/outline';
import { NoSymbolIcon } from '@heroicons/react/24/solid';

const COLOR_CODES = {
  green: {
    color: 'text-green-500',
  },
  warning: {
    color: 'text-primary',
    threshold: process.env.REACT_APP_MIN_VIDEO_DURATION || 30,
  },
};

type BottomActionsBarProps = {
  hideDisplay: boolean;
  showTips: boolean;
  showFaceOverlay: boolean;
  isRecording: boolean;
  isRunningCountdown: boolean;
  isCameraOn: boolean;
  streamIsReady: boolean;
  toggleHideDisplay: () => void;
  toggleTips: () => void;
  toggleFaceOverlay: () => void;
  onStart: () => void;
  onStop: () => void;
  onReset: () => void;
  countdown: string;
  timeLimit: number;
  timeElapsedSec: number;
};

export const BottomActionsBar = ({
  hideDisplay,
  showTips,
  showFaceOverlay,
  isRecording,
  isRunningCountdown,
  isCameraOn,
  streamIsReady,
  toggleFaceOverlay,
  toggleHideDisplay,
  toggleTips,
  onStart,
  onStop,
  onReset,
  countdown,
  timeLimit,
  timeElapsedSec,
}: BottomActionsBarProps) => {
  const [strokeDasharray, setStrokeDasharray] = useState('0 283');

  const calculateStrokeDasharray = useCallback(() => {
    if (timeElapsedSec < 1) return;

    const timeLimitSec = timeLimit / 1000;
    const rawTimeFraction = timeElapsedSec / timeLimitSec;
    const timeFraction = rawTimeFraction - (1 / timeLimitSec) * (1 - rawTimeFraction);
    setStrokeDasharray(`${(timeFraction * 283).toFixed(0)} 283`);
  }, [timeLimit, timeElapsedSec]);

  useEffect(() => {
    calculateStrokeDasharray();
  }, [calculateStrokeDasharray]);

  useEffect(() => {
    setStrokeDasharray('0 283');
  }, [timeLimit]);

  const setTimerColor = () => {
    const { green, warning } = COLOR_CODES;

    if (timeElapsedSec < warning.threshold) {
      return warning.color;
    }

    return green.color;
  };

  return (
    <div className="absolute bottom-2 z-50 grid h-[80px] w-full grid-cols-[1fr_81px_1fr] items-center justify-between rounded-[60px] bg-blue-gradient/90 px-2 lg:bottom-0 lg:translate-y-full lg:rounded-tl-none lg:rounded-tr-none lg:rounded-br-3xl lg:rounded-bl-3xl lg:shadow-md lg:shadow-black/50">
      <div className="grid grid-cols-3 place-items-center gap-1">
        <button
          className={`transition-all duration-200 ${hideDisplay ? 'text-white' : 'text-white/50'}`}
          onClick={toggleHideDisplay}
        >
          {hideDisplay ? <EyeSlashIcon className="w-8" /> : <EyeIcon className="w-8" />}
        </button>
        <button
          className={`transition-all duration-200 ${showTips ? 'text-white' : 'text-white/50'}`}
          onClick={toggleTips}
        >
          <DocumentTextIcon className="w-8" />
        </button>
        <button
          className={`transition-all duration-200 ${
            showFaceOverlay ? 'text-white' : 'text-white/50'
          }`}
          onClick={toggleFaceOverlay}
        >
          <UserIcon className="w-8" />
        </button>
      </div>

      <div className="grid place-items-center">
        <div className="relative w-[69px]">
          <svg className="-scale-x-100" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <g className="fill-neutral-500 stroke-none">
              {!isRecording && !isRunningCountdown && (
                <circle className="stroke-neutral-500 stroke-[10px]" cx="50" cy="50" r="45" />
              )}
              {(isRecording || isRunningCountdown) && (
                <path
                  id="base-timer-path-remaining"
                  strokeDasharray={strokeDasharray}
                  className={`stroke origin-center rotate-90 stroke-current stroke-[10px] transition-all duration-1000 ease-linear ${setTimerColor()}`}
                  style={{
                    strokeLinecap: 'round',
                  }}
                  d="
                    M 50, 50
                    m -45, 0
                    a 45,45 0 1,0 90,0
                    a 45,45 0 1,0 -90,0
                  "
                ></path>
              )}
            </g>
          </svg>
          <div className="absolute inset-0 top-0 z-20 grid place-content-center">
            {isCameraOn && streamIsReady && !isRecording && !isRunningCountdown && (
              <button
                onClick={onStart}
                className="aspect-square w-[55px] rounded-full bg-primary"
              />
            )}
            {(isRecording || isRunningCountdown) && (
              <button
                className={`aspect-square w-7 rounded bg-current transition-all duration-1000 ${setTimerColor()}`}
                onClick={onStop}
                disabled={isRunningCountdown}
              />
            )}
          </div>
        </div>
      </div>

      <div className="grid grid-cols-2 place-items-center gap-2">
        <p
          className={`ml-3 font-public-sans text-lg font-semibold tracking-wide transition-all duration-1000 ${setTimerColor()}`}
        >
          {countdown}
        </p>
        {isRecording && (
          <button onClick={onReset} disabled={isRunningCountdown}>
            <NoSymbolIcon className="w-8" />
          </button>
        )}
      </div>
    </div>
  );
};
