import { ToastContainer } from 'react-toastify';
import { HelmetProvider } from 'react-helmet-async';

// routing
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';

// Features
import {
  AuthProvider,
  Login,
  Signup,
  Verify,
  ResetPassword,
  ConfirmPassword,
  ProtectedRoutes,
  AccountSettings,
  AuthRedirect,
} from './features/auth';
import { Onboarding } from './features/onboarding';
import { Goals, PromptGeneration, PromptSelection } from './features/goals';
import { Tips, Permissions, Record } from './features/interview';
import { Reflection, FeedbackStatus, SelfAssessment, Survey } from './features/reflection';
import { AiFeedback, EndScreen, Feedback, FeedbackSurvey } from './features/feedback';
import { VideoArchive } from './features/video-archive';
import { Dashboard } from './features/dashboard';

import { ScrollToTop } from './components/ScrollToTop';

export const App = () => {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <ScrollToTop />
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          closeOnClick
          draggable
          pauseOnHover={false}
          theme="light"
        />
        <AuthProvider>
          <Routes>
            <Route path="/" element={<Navigate to="/login" />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/signup/verify" element={<Verify />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/reset-password/confirm" element={<ConfirmPassword />} />

            <Route element={<ProtectedRoutes />}>
              <Route path="/auth-redirect" element={<AuthRedirect />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/onboarding" element={<Onboarding />} />

              <Route path="/goals" element={<Goals />}>
                <Route path="generate-prompts" element={<PromptGeneration />} />
                <Route path="select-prompt" element={<PromptSelection />} />
              </Route>

              <Route path="/recording-tips" element={<Tips />} />
              <Route path="/permissions" element={<Permissions />} />
              <Route path="/record" element={<Record />} />

              <Route path="/reflection" element={<Reflection />}>
                <Route path="survey" element={<Survey />} />
                <Route path="performance" element={<SelfAssessment />} />
                <Route path="feedback-status" element={<FeedbackStatus />} />
              </Route>

              <Route path="/feedback" element={<Feedback />}>
                <Route path="ai-feedback" element={<AiFeedback />} />
                <Route path="survey" element={<FeedbackSurvey />} />
                <Route path="end" element={<EndScreen />} />
              </Route>

              <Route path="/history" element={<VideoArchive />} />
              <Route path="/settings" element={<AccountSettings />} />
            </Route>

            <Route path="*" element={<h3>Sorry, Page Not Found.</h3>} />
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
};
