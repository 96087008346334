import { useState } from 'react';
import PasswordChecklist from 'react-password-checklist';
import { Auth } from 'aws-amplify';
import { useForm, SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';
import { FieldError, InputField, Modal } from '../../../components/Elements';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { PASSWORD_REGEX } from '../../../constants';

type FormValues = {
  oldPassword: string;
  newPassword: string;
  repeatNewPassword: string;
};

export const ChangePassword = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    reset,
    formState: { errors, dirtyFields },
  } = useForm<FormValues>();
  const watchNewPassword = watch('newPassword');

  const openChangePasswordHandler = () => {
    setIsOpen(true);
  };

  const closeChangePasswordHandler = () => {
    setErrorMessage('');
    setIsLoading(false);
    reset();
    setIsOpen(false);
  };

  const updatePasswordHandler: SubmitHandler<FormValues> = async ({
    oldPassword,
    newPassword,
    repeatNewPassword,
  }) => {
    try {
      setIsLoading(true);
      setErrorMessage('');

      const currentUser = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(currentUser, oldPassword.trim(), newPassword.trim());

      toast.success('Password Changed');
      setIsLoading(false);
      closeChangePasswordHandler();
    } catch (error) {
      if (error instanceof Error) {
        console.log(error);
        setErrorMessage(error.message);
      }
      setIsLoading(false);
    }
  };

  return (
    <>
      <h3 className="">Password</h3>
      <button
        className="mt-2 rounded border border-navy-500 px-3 py-2 text-sm"
        onClick={openChangePasswordHandler}
      >
        Change Password
      </button>

      <Modal isOpen={isOpen} onClose={closeChangePasswordHandler}>
        <button onClick={closeChangePasswordHandler} className="absolute right-2 top-2">
          <XMarkIcon className="w-5" />
        </button>

        <div className="grid min-w-[300px] place-items-center pt-4">
          <h1 className="text-center text-lg font-bold">Change Password</h1>

          {errorMessage && (
            <p className="mt-4 max-w-[300px] text-center text-red-700">{errorMessage}</p>
          )}

          <form onSubmit={handleSubmit(updatePasswordHandler)} className="w-full">
            <InputField
              type="password"
              className="mt-4"
              label="Old Password"
              registration={register('oldPassword', {
                required: true,
              })}
            />
            {errors.oldPassword?.type === 'required' && <FieldError message="Required" />}

            <InputField
              type="password"
              className="mt-4"
              label="New Password"
              registration={register('newPassword', {
                required: true,
                pattern: PASSWORD_REGEX,
                validate: {
                  passwordDifferent: (value) =>
                    value !== getValues().oldPassword || 'Same Password as Old Error'
                }
              })}
            />
            {errors.newPassword?.type === 'required' && <FieldError message="Required" />}
            {errors.newPassword?.type === 'passwordDifferent' && <FieldError message="New Password must be different" />}
            {dirtyFields.newPassword && (
              <div role="alert" className="mt-2 ml-2 flex text-gray-600">
                <PasswordChecklist
                  className="[&>li]:items-center"
                  value={watchNewPassword}
                  rules={['capital', 'lowercase', 'minLength', 'specialChar', 'number']}
                  minLength={8}
                  iconSize={14}
                />
              </div>
            )}

            <InputField
              type="password"
              className="mt-4"
              label="Repeat Password"
              registration={register('repeatNewPassword', {
                required: true,
                validate: {
                  passwordEqual: (value) =>
                    value === getValues().newPassword || 'Repeat Password Error',
                },
              })}
            />
            {errors.repeatNewPassword?.type === 'required' && <FieldError message="Required" />}
            {errors.repeatNewPassword?.type === 'passwordEqual' && (
              <FieldError message="Your new password does not match" />
            )}

            <button disabled={isLoading} type="submit" className="mt-6 w-full rounded-[25px] bg-navy-500 h-[42px] px-4 text-white disabled:bg-neutral-600">
              Change Password
            </button>
          </form>
        </div>
      </Modal>
    </>
  );
};
