import { useState } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';
import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

const variants = {
  white: '',
  navy: 'border border-navy-500',
};

export type InputFieldProps = FieldWrapperPassThroughProps & {
  type?: 'text' | 'email' | 'password';
  className?: string;
  registration: Partial<UseFormRegisterReturn>;
};

export const InputField = (props: InputFieldProps) => {
  const { type = 'text', label, className, registration, error, variant = 'navy' } = props;
  const [showPassword, setShowPassword] = useState(false);

  const getType = (): string => {
    if (type === 'password') {
      if (showPassword) {
        return 'text';
      }
    }

    return type;
  };

  return (
    <FieldWrapper label={label} error={error} variant={variant} className={className}>
      <input
        id={label}
        type={getType()}
        autoComplete={`${type === 'password' ? 'current-password' : 'on'}`}
        className={`block w-full appearance-none rounded-[25px] py-2 px-5 text-black placeholder-gray-400  focus:border-blue-500 focus:outline-none focus:ring-blue-500 ${
          variants[variant]
        } ${type === 'password' && 'pr-11'}`}
        {...registration}
      />
      {type === 'password' && (
        <button
          className="absolute right-2 top-1/2 z-30 grid aspect-square w-8 -translate-y-1/2 place-items-center text-neutral-500"
          type="button"
          onClick={() => setShowPassword((prev) => !prev)}
        >
          <span className="sr-only">Toggle Show/Hide Password</span>
          {showPassword ? <EyeIcon className="w-6" /> : <EyeSlashIcon className="w-6" />}
        </button>
      )}
    </FieldWrapper>
  );
};
