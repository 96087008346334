import { axios } from '../configs';
import { HistoryCountGetResponse, HistoryGetResponse, ParsedFeedback } from './types';

export const HistoryAPI = {
  getRecordingHistory: async function (filename: string = '', created: string = '') {
    try {
      const response = await axios.get(`/user/history?filename=${filename}&created=${created}`);
      const { history } = response.data as HistoryGetResponse;

      const parsedData = history.Items.map((historyItem) => {
        const parsedFeedback = JSON.parse(historyItem.raw) as ParsedFeedback;
        historyItem.parsedFeedback = parsedFeedback;

        return historyItem;
      });

      history.Items = parsedData;

      return history;
    } catch (err) {
      throw err;
    }
  },

  getRecordingCount: async function () {
    try {
      const response = await axios.get('/user/history/count');
      return response.data as HistoryCountGetResponse;
    } catch (err) {
      throw err;
    }
  },
};
