import { ReactComponent as FaceSVG } from '../assets/face.svg';

type FaceOverlayProps = {
  showFaceOverlay: boolean;
};

export const FaceOverlay = ({ showFaceOverlay }: FaceOverlayProps) => {
  return (
    <div
      className={`absolute top-1/2 left-0 right-0 z-10 flex -translate-y-[calc(50%_+_15px)] justify-center transition-opacity duration-200 ${
        showFaceOverlay ? 'opacity-100' : 'opacity-0'
      } scale-90 md:scale-100`}
    >
      <FaceSVG className="w-[95%]" />
    </div>
  );
};
