import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/Elements';
import { useTrackEvent } from '../../../hooks';
import { ReactComponent as DownArrow } from '../../../assets/icons/down-arrow.svg';

type TipCardProps = {
  header: string;
  color: string;
  image: string;
  tips: string[];
  toggle: (index: number) => void;
  open: boolean;
  index: number;
  length: number;
};

export const TipCard = ({
  header,
  color,
  image,
  tips,
  toggle,
  open,
  index,
  length,
}: TipCardProps) => {
  const navigate = useNavigate();
  const { trackEvent } = useTrackEvent();

  const nextScreenHandler = () => {
    trackEvent('interview_practice', 'rubric_read_end');
    navigate('/permissions');
  };

  const colorVariants: any = {
    purple: 'bg-tips-purple',
    orange: 'bg-tips-orange',
    yellow: 'bg-tips-yellow',
    green: 'bg-tips-green',
    lightBlue: 'bg-lightblue-500',
    red: 'bg-tips-red',
    navy: 'bg-navy-400',
  };

  return (
    <div className="mt-1 flex w-full flex-col">
      <h3
        onClick={() => toggle(index)}
        className={`${open ? colorVariants[color] : 'bg-navy-200'} ${
          open ? 'rounded-t-[5px]' : 'rounded-[5px]'
        } h-50px flex justify-center text-xl font-bold uppercase text-white`}
      >
        {header}
      </h3>
      {open && (
        <>
          <div className="flex h-[400px] bg-no-repeat" style={{ backgroundImage: `url(${image})` }}>
            <div className="flex w-full flex-col justify-end opacity-[90%]">
              <ul
                className={`${colorVariants[color]} mx-auto mb-[10px] flex w-[95%] list-disc flex-col rounded-[5px] border-2 border-solid border-white py-2 pr-2 pl-7 text-white`}
              >
                {tips.map((item) => (
                  <li className="text-white-500 text-md" key={item}>
                    <span className="text-white">{item}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          {index + 1 === length ? (
            <Button onClick={nextScreenHandler} className="mt-8 w-full max-w-[320px]">
              Next
            </Button>
          ) : (
            <Button
              variant="none"
              onClick={() => toggle(index + 1)}
              className={`mx-auto my-2 w-full ${colorVariants[color]} h-[50px] w-[100px] text-white drop-shadow-md`}
            >
              <DownArrow className="w-[30px]" />
            </Button>
          )}
        </>
      )}
    </div>
  );
};
