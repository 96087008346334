import { FeedbackRecordResponse } from "./types/FeedbackRecordResponse";
import fetchAuth from "../../utils/fetchAuth";
import { FeedbackRecord } from "./types/FeedbackRecord";

class FeedbackService {
  static updateRecord = async ({
    filename,
    userId,
    status = "initial",
    created = Date.now().toString(),
    updated = Date.now().toString(),
    promptRecord = {},
    duration = 0,
  }: FeedbackRecord): Promise<FeedbackRecordResponse> => {
    try {
      let resp = await fetchAuth("/feedback", {
        method: "POST",
        body: JSON.stringify({
          filename,
          userId,
          status,
          created,
          updated,
          promptRecord,
          duration,
        }),
      });
      let { message, timestamp, feedback } = await resp.json();
      console.log(feedback?.Attributes);
      return {
        message,
        timestamp,
        feedback: feedback?.Attributes,
      };
    } catch (err) {
      throw err;
    }
  };
}

export default FeedbackService;
