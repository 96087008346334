import { useRef, useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import PasswordChecklist from 'react-password-checklist';
import { SEO } from '../../../components';
import { useAuth } from '../context';
import { Button, InputField, FieldError } from '../../../components/Elements';
import { Eula, AuthHeading, AuthError, BackToLogin } from '../components';
import { PASSWORD_REGEX } from '../../../constants';

type FormValues = {
  username: string;
  password: string;
  email: string;
};

export const Signup = () => {
  const [agreeEula, setAgreeEula] = useState(false);
  const navigate = useRef(useNavigate());

  const {
    loginState: { isLoggedIn },
    signupState: { attemptingSignup, signupError, signupErrorMessage },
    signUp,
    resetSignUp,
  } = useAuth();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, dirtyFields },
  } = useForm<FormValues>();

  const watchPassword = watch('password');

  useEffect(() => {
    if (isLoggedIn) navigate.current('/auth-redirect');

    return () => resetSignUp();
  }, [isLoggedIn, resetSignUp]);

  const agreeEulaHandler = () => {
    setAgreeEula(true);
  };

  const createAccountHandler: SubmitHandler<FormValues> = async ({ email, password, username }) => {
    signUp(username, email, password);
  };

  return (
    <main className="relative min-h-screen bg-white">
      <SEO title='Sign Up'/>

      <BackToLogin />
      <div className="container mx-auto grid place-items-center px-3">
        <AuthHeading>Create New Account</AuthHeading>

        <form className="mt-11 w-full max-w-[334px]" onSubmit={handleSubmit(createAccountHandler)}>
          {signupError && <AuthError message={signupErrorMessage} />}

          <InputField
            label="Username*"
            registration={register('username', {
              required: true,
            })}
          />

          {errors.username?.type === 'required' && <FieldError message="User name is required" />}

          <InputField
            className="mt-4"
            label="Email Address*"
            type="email"
            registration={register('email', {
              required: true,
            })}
          />
          {errors.email?.type === 'required' && <FieldError message="Email is required" />}

          <InputField
            label="Password*"
            className="mt-4"
            type="password"
            registration={register('password', {
              required: true,
              pattern: PASSWORD_REGEX,
            })}
          />
          {errors.password?.type === 'required' && <FieldError message="Password is required" />}
          {dirtyFields.password && (
            <div role="alert" className="mt-2 ml-2 flex font-medium">
              <PasswordChecklist
                className="[&>li]:items-center"
                value={watchPassword}
                rules={['capital', 'lowercase', 'minLength', 'specialChar', 'number']}
                minLength={8}
                iconSize={14}
              />
            </div>
          )}

          <div className="mt-12 flex items-center text-lg text-blue-900">
            <input
              className="mr-2 w-5 accent-[#FFC967]"
              type="checkbox"
              checked={agreeEula}
              onChange={() => setAgreeEula(!agreeEula)}
            />
            <p className="mr-1 font-light ">I agree to the</p>
            <Eula onAgree={agreeEulaHandler} />
          </div>

          <Button
            className="mt-11 w-full"
            disabled={!agreeEula}
            type="submit"
            isLoading={attemptingSignup}
          >
            Create Account
          </Button>
        </form>
      </div>
    </main>
  );
};
