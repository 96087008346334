import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { AccountDropdown } from '../AccountDropdown';
import { Menu } from '../Menu';

type TopBarProps = {
  className?: string;
};

export const TopBar = ({ className = '' }: TopBarProps) => {
  const [scrolled, setScrolled] = useState(false);
  const { pathname } = useLocation();

  const changeBackground = () => {
    if (window.scrollY >= 25) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    // adding the event when scroll change background
    window.addEventListener('scroll', changeBackground);

    return () => window.removeEventListener('scroll', changeBackground);
  }, []);

  return (
    <div
      className={`fixed top-0 left-0 right-0 z-50 flex h-12 items-center justify-between px-3 transition-all duration-200 md:left-56 ${
        pathname === '/record' && 'hidden lg:flex'
      } 
      ${className}
      ${scrolled && 'bg-navy-500'}
      `}
    >
      <Menu scrolled={scrolled} className="md:invisible" />
      <AccountDropdown scrolled={scrolled} />
    </div>
  );
};
