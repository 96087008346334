import { useState, useEffect, useRef } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate, useLocation } from 'react-router-dom';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import ReactInputVerificationCode, {
  ReactInputVerificationCodeProps,
} from 'react-input-verification-code';
import { useAuth } from '../context';
import { Button } from '../../../components/Elements';
import { AuthHeading, AuthError, BackToLogin } from '../components';
import './Verify.css';

const customProps: ReactInputVerificationCodeProps = {
  autoFocus: true,
};

export const Verify = () => {
  const [code, setCode] = useState('');
  const [newCodeSent, setNewCodeSent] = useState(false);
  const navigate = useRef(useNavigate());
  const { state } = useLocation();
  const {
    confirmSignUp,
    resetVerifySignUp,
    verifySignupState: { attemptingVerify, verifyError, verifyErrorMessage },
  } = useAuth();

  useEffect(() => {
    if (!state || !state.username) {
      navigate.current('/signup');
    }

    return () => {
      resetVerifySignUp();
    };
  }, [state, resetVerifySignUp]);

  const verifyCodeHandler = () => {
    confirmSignUp(state.username, code);
  };

  const resendCodeHandler = async () => {
    try {
      await Auth.resendSignUp(state.username);
      setNewCodeSent(true);

      setTimeout(() => setNewCodeSent(false), 8000);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="relative min-h-screen bg-white">
      <BackToLogin />

      <div className="container mx-auto flex max-w-[334px] flex-col items-center px-3">
        <AuthHeading>Verify Code</AuthHeading>
        {state && state?.email && (
          <p className="mt-11 font-light">A code has been sent to {state.email}</p>
        )}

        {verifyError && (
          <AuthError className="mt-4 mb-0 ml-0 text-center" message={verifyErrorMessage} />
        )}

        <div className="custom-styles mt-6">
          <ReactInputVerificationCode
            length={6}
            {...customProps}
            placeholder=""
            value={code}
            onChange={(e) => setCode(e)}
          />
        </div>

        <div className="mt-12 flex items-center">
          <p className="mr-1 font-light ">Didn't receive code?</p>
          <button
            onClick={resendCodeHandler}
            type="button"
            className="font-raleway font-bold hover:underline"
            style={{paddingTop: '3px'}}
          >
            Request again
          </button>
        </div>

        {newCodeSent && (
          <p className="mt-3 flex text-center font-light text-blue-900">
            <ExclamationTriangleIcon className="mr-1 h-6 w-6" />
            <span>A new code has been sent</span>
          </p>
        )}

        <Button
          className="mt-11 w-full"
          disabled={code.length !== 6}
          onClick={verifyCodeHandler}
          isLoading={attemptingVerify}
          type="button"
        >
          Verify and Create Account
        </Button>
      </div>
    </div>
  );
};
