import plaa from '../configs/plaa-config';
import { Event } from '../../EventService/types';
import { fetchPLAA } from '../utils';

// https://confluence.ets.org/display/PLALAB/Events

export const trackEventAsync = async (event: Event) => {
  let body = event;
  let input: RequestInfo = plaa.endpoints.events;
  let init: RequestInit = {
    method: 'POST',
    body: JSON.stringify({
      events: [body],
    }),
  };

  try {
    let response = await fetchPLAA(input, init);
    let data = await response.json();
    if (data.error) throw data;
    return { input, init, data };
  } catch (error) {
    return { input, init, error };
  }
};
