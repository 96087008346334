import { ReactNode, MutableRefObject, Fragment } from 'react';
import { Transition, Dialog } from '@headlessui/react';

type RecordingModalProps = {
  isOpen: boolean;
  onClose: () => void;
  initialFocus?: MutableRefObject<null>;
  children?: ReactNode;
};

export const RecordingModal = ({
  isOpen,
  onClose,
  children,
  initialFocus,
}: RecordingModalProps) => {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-50 overflow-y-hidden "
        open={isOpen}
        onClose={onClose}
        initialFocus={initialFocus}
      >
        <div className="flex h-full items-end justify-center md:items-center md:pl-56">
          <Dialog.Overlay className="fixed inset-0 bg-black/40" />
          <Transition.Child
            as={Fragment}
            enter="duration-200 transition-all ease-in"
            enterFrom="translate-y-24 opacity-0 md:translate-y-8"
            enterTo="translate-y-0 opacity-100"
            leave="duration-200 transition-all ease-in"
            leaveFrom="translate-y-0 opacity-100"
            leaveTo="translate-y-24 opacity-0 md:translate-y-8"
          >
            <div className="w-full max-w-[475px] rounded-tl-3xl rounded-tr-3xl bg-blue-gradient md:rounded-3xl">
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
