import { PersonalizedFeedback } from '../types';

export const getConfidenceFeedback = (confidenceScore: string): PersonalizedFeedback => {
  const heading = 'Confidence';
  const optimalLowerBound = 60;
  const optimalUpperBound = 100;
  const confidenceScoreNum = confidenceScore === '0' ? 20 : confidenceScore === '1' ? 50 : 80;
  const subHeading = `We use a combination of visual and vocal cues to measure the confidence of a speaker.`;

  const staticFields = {
    heading,
    subHeading,
    optimalLowerBound,
    optimalUpperBound,
    percentageScore: confidenceScoreNum,
  };

  if (confidenceScoreNum <= 33) {
    return {
      ...staticFields,
      icon: 'low',
      scoreMessage: 'low',
      tipHeader: 'Interview Advice',
      tips: [
        'Confidence when speaking is important when interviewing for a job. It helps to convey that you possess the relevant job knowledge and experience to a hiring manager.',
        'Research suggests that speakers rated as confident do the following: engaged eye gaze, steady speaking rate, increased vocal variation, composed posture with relaxed facial expression, and less animated gestures with non-excessive body movements.',
      ],
    };
  } else if (confidenceScoreNum <= 65) {
    return {
      ...staticFields,
      icon: 'almost',
      scoreMessage: 'medium',
      tipHeader: 'How To Improve',
      tips: [
        'Rewatch your videos and pay close attention to your eye gaze, gesture, body movements, posture, vocal variations, facial expressions, and/or speaking pace.',
        'How do they compare to your favorite television host or presenter? What are some qualities that you might want to emulate to improve your own communication style.',
      ],
    };
  } else {
    return {
      ...staticFields,
      scoreMessage: 'high',
      icon: 'optimal',
      percentageScore: confidenceScoreNum,
      tipHeader: 'Good Job!',
      tips: ['Your visual and vocal communication style comes across as confident and assured.'],
    };
  }
};
