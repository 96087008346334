import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useTrackEvent } from '../../hooks';
import { useAppSelector } from '../../redux';

type RecentStatsProps = {
  eventType?: string;
  eventPrefix?: string;
};

type DataSets = {
  eyeContact: DataSet;
  headMovement: DataSet;
  fillerWords: DataSet;
  pauses: DataSet;
};

type DataSet = {
  header: string;
  data: number[];
  optimalRange: string;
};

export const RecentStats = ({ eventPrefix = '', eventType = '' }: RecentStatsProps) => {
  const [activeDataSet, setActiveDataSet] = useState<keyof DataSets>('eyeContact');
  const [labels, setLabels] = useState<string[]>([]);
  const [dataSets, setDataSets] = useState<DataSets | null>(null);

  const { items } = useAppSelector((state) => state.userHistory.history);

  const { trackEvent } = useTrackEvent();

  useEffect(() => {
    if (items.length > 0) {
      const recentHistory = items.slice(0, 10).reverse();
      const eyeContactData: number[] = [];
      const headMovementData: number[] = [];
      const fillerWordData: number[] = [];
      const pausesData: number[] = [];
      const labels: string[] = [];

      recentHistory.forEach((historyItem) => {
        const { eye_gaze_percent, filler_percent, pause_percent, head_posture_percent } =
          historyItem.parsedFeedback.service_results;

        eyeContactData.push(Math.round(+eye_gaze_percent));
        headMovementData.push(Math.round(+head_posture_percent));
        fillerWordData.push(Math.round(+filler_percent));
        pausesData.push(Math.round(+pause_percent));

        const date = new Date(+historyItem.created);
        const dateString = `${date.getMonth() + 1}/${date.getDate()}`;
        labels.push(dateString);
      });

      setLabels(labels);
      setDataSets({
        eyeContact: {
          data: eyeContactData,
          header: 'Eye Contact',
          optimalRange: 'A score of 70 - 95% is considered optimal',
        },
        headMovement: {
          data: headMovementData,
          header: 'Head Movement',
          optimalRange: 'A score of 65 - 95% is considered optimal',
        },
        pauses: {
          data: pausesData,
          header: 'Pauses',
          optimalRange: 'A score of 5 - 36% is considered optimal',
        },
        fillerWords: {
          data: fillerWordData,
          header: 'Filler Words',
          optimalRange: 'A score of 0 - 6% is considered optimal',
        },
      });
    }
  }, [items]);

  const changeDataset = (key: keyof DataSets) => {
    setActiveDataSet(key);
    trackEvent(eventType, `${eventPrefix}_${key}_graph`);
  };

  return (
    <section className="w-full">
      {dataSets && (
        <div className="text-center text-navy-500">
          <h3 className="text-lg font-bold uppercase">{dataSets[activeDataSet].header}</h3>
          <p className="mt-1 text-sm">{dataSets[activeDataSet].optimalRange}</p>

          <div className="mt-2 h-[350px]">
            {/* Graph  */}
            <Line
              data={{
                labels,
                datasets: [dataSets[activeDataSet]],
              }}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                elements: {
                  line: {
                    tension: 0.3,
                  },
                },
                scales: {
                  y: {
                    suggestedMax: activeDataSet === 'fillerWords' ? 10 : 100,
                    suggestedMin: 0,
                    ticks: {
                      // color: '#193152',
                    },
                  },
                  x: {
                    title: {
                      text: labels.length > 1 ? `Last ${labels.length} Interviews` : 'Interviews',
                      display: true,
                      color: '#193152',
                    },
                    ticks: {
                      // color: '#193152',
                    },
                  },
                },
                plugins: {
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    enabled: true,
                  },
                },
              }}
            />
          </div>

          {/* Buttons */}
          <div className="mt-4 grid grid-cols-4 gap-2">
            {Object.entries(dataSets).map(([key, { header }]) => (
              <button
                key={key}
                className={`min-h-[32px] rounded-3xl py-1 text-xs leading-none transition-all duration-200 ${
                  key === activeDataSet
                    ? 'bg-lightblue-500 text-navy-500'
                    : 'bg-navy-200 text-white'
                }`}
                onClick={() => changeDataset(key as keyof DataSets)}
              >
                {header}
              </button>
            ))}
          </div>
        </div>
      )}
    </section>
  );
};
