import { useState, useRef, useMemo, useEffect, useCallback } from 'react';
import { isMobile } from 'react-device-detect';
import {
  PlayIcon,
  PauseIcon,
  SpeakerWaveIcon,
  SpeakerXMarkIcon,
  ArrowsPointingOutIcon,
  ArrowsPointingInIcon,
} from '@heroicons/react/24/solid';
import { ReactComponent as UndoSVG } from '../../../../assets/icons/undo.svg';
import { ReactComponent as RedoSVG } from '../../../../assets/icons/redo.svg';
import { useTrackEvent } from '../../../../hooks/useTrackEvent';

import styles from './styles.module.css';

export const VideoPlayer = ({ src }) => {
  const [paused, setPaused] = useState(true);
  const [volume, setVolume] = useState(1);
  const [muted, setMuted] = useState(false);
  const [timeElapsed, setTimeElapsed] = useState('0:00');
  const [duration, setDuration] = useState('0:00');
  const [fullscreen, setFullscreen] = useState(false);
  const [videoSpeed, setVideoSpeed] = useState(1);
  const [showPlayAnimation, setShowPlayAnimation] = useState(false);
  const [showPauseAnimation, setShowPauseAnimation] = useState(false);

  const [progressPosition, setProgressPosition] = useState(0);
  const [previewPosition, setPreviewPosition] = useState(0);
  const wasPaused = useRef(false);
  const isScrubbing = useRef(false);

  const [showMobileControls, setShowMobileControls] = useState(false);
  const controlsTimeoutRef = useRef(0);

  const videoRef = useRef(null);
  const videoContainerRef = useRef(null);
  const timelineContainerRef = useRef(null);

  const { trackEvent } = useTrackEvent();

  const keyboardControlsHandler = useCallback((e) => {
    const tagName = document.activeElement?.tagName.toLowerCase();

    if (tagName === 'input') return;

    switch (e.key.toLowerCase()) {
      case ' ':
        if (tagName === 'button') return;
        togglePlay();
        break;
      case 'k':
        togglePlay();
        break;
      case 'f':
        fullscreenHandler();
        break;
      case 'm':
        mutedHandler();
        break;
      case 'arrowleft':
      case 'j':
        skip(-5);
        break;
      case 'arrowright':
      case 'l':
        skip(5);
        break;
      default:
        break;
    }
  }, []);

  const fullscreenChangeHandler = () => {
    setFullscreen((prev) => !prev);
  };

  // Video Event Listeners
  useEffect(() => {
    document.addEventListener('fullscreenchange', fullscreenChangeHandler);
    document.addEventListener('keydown', keyboardControlsHandler);
    return () => {
      document.removeEventListener('fullscreenchange', fullscreenChangeHandler);
      document.removeEventListener('keydown', keyboardControlsHandler);
      window.clearTimeout(controlsTimeoutRef.current);
    };
  }, [keyboardControlsHandler]);

  // Play/Pause
  const togglePlay = (e) => {
    if (!videoRef.current) return;

    if (videoRef.current.paused) {
      setShowPauseAnimation(false);
      videoRef.current.play();
      setShowPlayAnimation(true);
      e.target.blur();
    } else {
      setShowPlayAnimation(false);
      videoRef.current.pause();
      setShowPauseAnimation(true);
    }
  };

  const playHandler = (e) => {
    if (!videoRef.current) return;

    if (videoRef.current.paused) {
      videoRef.current.play();
      e.currentTarget.blur();
    }
  };

  const onPlay = () => {
    setPaused(false);
    trackEvent('interview_practice', 'record_playback');
  };

  const pauseHandler = () => {
    if (!videoRef.current) return;

    if (!videoRef.current.paused) {
      videoRef.current.pause();
    }
  };

  const onPause = () => {
    setPaused(true);
    trackEvent('interview_practice', 'record_pause');
  };

  // Video Ended
  const onEnded = () => {
    setPaused(true);
    setShowPlayAnimation(false);
    trackEvent('interview_practice', 'record_ended');
  };

  // Volume Controls
  const mutedHandler = () => {
    if (!videoRef.current) return;

    videoRef.current.muted = !videoRef.current.muted;

    videoRef.current.muted
      ? trackEvent('interview_practice', 'record_mute')
      : trackEvent('interview_practice', 'record_unmute');
  };

  const mobileMutedHandler = (e) => {
    e.stopPropagation();

    if (!videoRef.current) return;

    showMobileControlsHandler();
    videoRef.current.muted = !videoRef.current.muted;

    videoRef.current.muted
      ? trackEvent('interview_practice', 'record_mute')
      : trackEvent('interview_practice', 'record_unmute');
  };

  const volumeHandler = (e) => {
    if (!videoRef.current) return;

    videoRef.current.volume = +e.target.value;

    if (videoRef.current.volume > 0) {
      videoRef.current.muted = false;
    }
  };

  const onVolumeChange = () => {
    if (!videoRef.current) return;

    setVolume(videoRef.current.volume);

    if (videoRef.current.muted || videoRef.current.volume === 0) {
      setVolume(0);
      setMuted(true);
    } else {
      setMuted(false);
    }
  };

  // Duration
  const onLoadedData = () => {
    if (!videoRef.current) return;
    setDuration(formatDuration(videoRef.current.duration));
  };

  const onTimeUpdate = () => {
    if (!videoRef.current) return;

    setTimeElapsed(formatDuration(videoRef.current.currentTime));

    const percent = videoRef.current.currentTime / videoRef.current.duration;
    setProgressPosition(percent);
  };

  const leadingZeroFormatter = useMemo(
    () =>
      new Intl.NumberFormat(undefined, {
        minimumIntegerDigits: 2,
      }),
    []
  );

  const formatDuration = useCallback(
    (time) => {
      if (time === Infinity) {
        return '';
      }

      const seconds = Math.floor(time % 60);
      const minutes = Math.floor(time / 60) % 60;
      const hours = Math.floor(time / 3600);
      if (hours === 0) {
        return `${minutes}:${leadingZeroFormatter.format(seconds)}`;
      } else {
        return `${hours}:${leadingZeroFormatter.format(minutes)}:${leadingZeroFormatter.format(
          seconds
        )}`;
      }
    },
    [leadingZeroFormatter]
  );

  // This deals with a browser bug where the duration of the video shows up as Infinity.
  // Setting the current time to the max length of the video forces the browser to parse
  // the video and get the correct duration.
  useEffect(() => {
    if (duration.toString() === '' && videoRef.current) {
      console.log('Browser Failed to parse duration');
      videoRef.current.currentTime = Number.MAX_SAFE_INTEGER;
      setTimeout(() => {
        if (!videoRef.current) return;
        setDuration(formatDuration(videoRef.current.duration));
        videoRef.current.currentTime = 0.1;
      }, 500);
    }
  }, [duration, formatDuration]);

  const skip = (time) => {
    if (!videoRef.current) return;

    videoRef.current.currentTime += time;
  };

  // Screen Controls
  const fullscreenHandler = () => {
    if (!videoContainerRef.current) return;

    if (document.fullscreenElement == null) {
      videoContainerRef.current.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  // Speed Controls
  const playbackSpeedHandler = () => {
    if (!videoRef.current) return;

    let newPlaybackRate = videoRef.current.playbackRate + 0.25;
    if (newPlaybackRate > 2) newPlaybackRate = 0.25;
    videoRef.current.playbackRate = newPlaybackRate;
    setVideoSpeed(newPlaybackRate);

    trackEvent('interview_practice', 'record_speed', { speed: newPlaybackRate });
  };

  // Timeline Controls
  const toggleScrubbing = useCallback((e) => {
    if (!timelineContainerRef.current || !videoContainerRef.current || !videoRef.current) return;

    const rect = timelineContainerRef.current.getBoundingClientRect();
    const percent = Math.min(Math.max(0, e.clientX - rect.x), rect.width) / rect.width;

    isScrubbing.current = (e.buttons & 1) === 1;

    if (isScrubbing.current) {
      wasPaused.current = videoRef.current.paused;
      videoRef.current.pause();
    } else {
      videoRef.current.currentTime = percent * videoRef.current.duration;
      if (!wasPaused.current) {
        videoRef.current.play();
      }
    }

    timelineUpdateHandler(e);
  }, []);

  const timelineUpdateHandler = (e) => {
    if (!timelineContainerRef.current || !videoRef.current) return;

    const rect = timelineContainerRef.current.getBoundingClientRect();
    const percent = Math.min(Math.max(0, e.clientX - rect.x), rect.width) / rect.width;

    setPreviewPosition(percent);

    if (isScrubbing.current) {
      e.preventDefault();
      setProgressPosition(percent);
    }
  };

  // Turn off scrubbing on mouseup
  const stopScrubbingHandler = useCallback(
    (e) => {
      if (isScrubbing.current) {
        toggleScrubbing(e);
        trackEvent('interview_practice', `record_change_playhead`)
      }
    },
    [toggleScrubbing, trackEvent]
  );

  // Update the timeline when scrubbing
  const scrubbingTimelineHandler = useCallback((e) => {
    if (isScrubbing.current) {
      timelineUpdateHandler(e);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mouseup', stopScrubbingHandler);
    document.addEventListener('mousemove', scrubbingTimelineHandler);

    return () => {
      document.removeEventListener('mouseup', stopScrubbingHandler);
      document.removeEventListener('mousemove', scrubbingTimelineHandler);
    };
  }, [stopScrubbingHandler, scrubbingTimelineHandler]);

  // Scrubbing for touch events
  const scrubbingTouchStartHandler = (e) => {
    if (!videoRef.current) return;

    e.stopPropagation();
    showMobileControlsHandler();

    wasPaused.current = videoRef.current.paused;
    videoRef.current.pause();
  };

  const scrubbingTouchMoveHandler = (e) => {
    if (!timelineContainerRef.current || !videoRef.current) return;
    showMobileControlsHandler();

    const rect = timelineContainerRef.current.getBoundingClientRect();
    const percent = Math.min(Math.max(0, e.touches[0].clientX - rect.x), rect.width) / rect.width;

    setPreviewPosition(percent);
    setProgressPosition(percent);
    videoRef.current.currentTime = percent * videoRef.current.duration;
  };

  const scrubbingTouchEndHandler = (e) => {
    if (!wasPaused.current && videoRef.current) {
      videoRef.current.play();
    }
  };

  // Show/hide mobile controls
  const showMobileControlsHandler = () => {
    if (controlsTimeoutRef.current) {
      window.clearTimeout(controlsTimeoutRef.current);
      controlsTimeoutRef.current = 0;
    }

    setShowMobileControls(true);
    controlsTimeoutRef.current = window.setTimeout(() => {
      setShowMobileControls(false);
    }, 2000);
  };

  const hideMobileControls = (e) => {
    e.stopPropagation();
    setShowMobileControls(false);
  };

  const mobileSkipControl = (e, time) => {
    e.stopPropagation();
    showMobileControlsHandler();
    skip(time);
  };

  const mobilePlayHandler = (e) => {
    e.stopPropagation();
    showMobileControlsHandler();
    playHandler(e);
  };

  return (
    <div
      ref={videoContainerRef}
      className={`group/video-container relative flex h-full max-h-screen w-full items-center bg-black ${
        fullscreen && 'max-w-none'
      }`}
    >
      {/* Clickable overlay to play/pause video (Desktop Only) */}
      {!isMobile && (
        <div onClick={togglePlay} className="absolute left-0 top-0 right-0 bottom-20 z-10" />
      )}

      {/* Display animated SVG when playing/pausing by clicking the overlay (Desktop Only)*/}
      {!isMobile && (
        <div className="pointer-events-none absolute top-1/2 left-1/2 z-20 -translate-x-1/2 -translate-y-1/2 ">
          {showPlayAnimation && (
            <div className="flex animate-play-pause items-center justify-center rounded-full bg-black p-4 opacity-0">
              <PlayIcon className="w-16 text-white" />
            </div>
          )}
          {showPauseAnimation && (
            <div className="flex animate-play-pause items-center justify-center rounded-full bg-black p-4 opacity-0">
              <PauseIcon className="w-16 text-white" />
            </div>
          )}
        </div>
      )}

      {/* Controls Container (Desktop) */}
      {!isMobile ? (
        <div
          className={`absolute bottom-0 left-0 right-0 z-30 h-48 text-white opacity-0 transition-opacity duration-150 ease-in-out
        group-focus-within/video-container:opacity-100 group-hover/video-container:opacity-100 ${
          paused && 'opacity-100'
        }`}
        >
          {/* Shadow behind controls */}
          <div className="pointer-events-none absolute bottom-0 left-0 right-0 -z-10 h-64 w-full bg-gradient-to-t from-black/90 to-black/0" />
          {/* Controls */}
          <div className="flex items-center gap-2 px-2 pb-2">
            {/* Play Pause buttons */}
            {paused ? (
              <button onClick={playHandler} className="w-7">
                <PlayIcon className="w-full" />
                <span className="sr-only">Play</span>
              </button>
            ) : (
              <button onClick={pauseHandler} className="w-7">
                <PauseIcon className="w-full" />
                <span className="sr-only">Pause</span>
              </button>
            )}

            {/* Volume Container */}
            <div className="group/volume flex items-center gap-1">
              <button className="w-7" onClick={mutedHandler}>
                {muted ? (
                  <SpeakerXMarkIcon className="w-full" />
                ) : (
                  <SpeakerWaveIcon className="w-full" />
                )}
              </button>
              {/* Volume slider */}
              <input
                className={`relative w-0 origin-left scale-x-0 cursor-pointer transition-[width] duration-200 ease-in-out after:absolute after:top-1/2 after:left-0 after:right-0 after:-z-10 after:h-1 after:-translate-y-1/2 after:rounded-3xl after:bg-white group-focus-within/volume:w-20 group-focus-within/volume:scale-x-100 group-hover/volume:w-20 group-hover/volume:scale-x-100 ${styles.volume}`}
                type="range"
                min="0"
                max="1"
                step="any"
                value={volume}
                onChange={volumeHandler}
              />
            </div>
            {/* Duration Container*/}
            <div className="flex items-center text-sm">
              <span>
                {timeElapsed} / {duration}
              </span>
            </div>

            {/* Right Button Container */}
            <div className="ml-auto flex items-center gap-2">
              {/* Playback Speed */}
              <button
                className="flex w-14 items-center justify-center text-lg"
                onClick={playbackSpeedHandler}
              >
                <span>{videoSpeed}x</span>
              </button>
              {/* Full Screen button (Currently only for desktop) */}
              <button className="w-7" onClick={fullscreenHandler}>
                {fullscreen ? (
                  <ArrowsPointingInIcon className="w-full" />
                ) : (
                  <ArrowsPointingOutIcon />
                )}
              </button>
            </div>
          </div>

          {/* Timeline Container */}
          <div
            style={{ touchAction: 'none' }}
            ref={timelineContainerRef}
            className="group/timeline mx-2 flex h-6 cursor-pointer items-center"
            onMouseDown={toggleScrubbing}
            onMouseMove={timelineUpdateHandler}
            onTouchStart={scrubbingTouchStartHandler}
            onTouchMove={scrubbingTouchMoveHandler}
            onTouchEnd={scrubbingTouchEndHandler}
          >
            {/* Timeline */}
            <div className="relative h-1 w-full bg-neutral-500/50">
              {/* Preview track */}
              <div
                style={{ right: `${(1 - previewPosition) * 100}%` }}
                className="absolute top-0 bottom-0 left-0 z-30 hidden bg-neutral-300/50 group-hover/timeline:block"
              />
              {/* Progress track */}
              <div
                style={{ right: `${(1 - progressPosition) * 100}%` }}
                className="absolute top-0 bottom-0 left-0 z-40 bg-primary"
              />
              {/* Thumb */}
              <div
                style={{ left: `${progressPosition * 100}%` }}
                className="absolute -top-full z-50 aspect-square h-[300%] -translate-x-1/2 scale-0 rounded-full bg-primary transition-transform duration-150 ease-in-out group-hover/timeline:scale-100"
              />
            </div>
          </div>
        </div>
      ) : (
        // Controls Container (Mobile)
        <div className="absolute inset-0" onClick={showMobileControlsHandler}>
          {/* Wrapper for fading controls in and out */}
          <div
            className={`pointer-events-none absolute inset-0 z-20 bg-black/50 opacity-0 transition-opacity duration-500 ease-in-out ${
              (paused || showMobileControls) && 'pointer-events-auto opacity-100'
            }`}
            onClick={hideMobileControls}
          >
            {/* Play, Pause, Skip */}
            <div className="absolute inset-0 z-20 grid grid-cols-3 place-items-center">
              <button className="relative" onClick={(e) => mobileSkipControl(e, -10)}>
                <UndoSVG className="h-16 w-16" />
                <span className="absolute top-1/2 left-1/2 -translate-x-1/4 -translate-y-1/2">
                  10
                </span>
              </button>

              {/* Play Pause buttons */}
              {paused ? (
                <button onClick={mobilePlayHandler} className="w-16">
                  <PlayIcon className="w-full" />
                  <span className="sr-only">Play</span>
                </button>
              ) : (
                <button onClick={pauseHandler} className="w-16">
                  <PauseIcon className="w-full" />
                  <span className="sr-only">Pause</span>
                </button>
              )}

              <button className="relative" onClick={(e) => mobileSkipControl(e, 10)}>
                <RedoSVG className="h-16 w-16" />
                <span className="absolute top-1/2 left-1/2 -translate-x-3/4 -translate-y-1/2">
                  10
                </span>
              </button>
            </div>

            {/* Timeline, Audio, Duration */}
            <div className="absolute bottom-0 z-30 mb-36 w-full px-2">
              {/* Duration/Audio Container*/}
              <div className="flex items-center justify-between px-2 pb-2 text-sm">
                <span>
                  {timeElapsed} / {duration}
                </span>

                <button className="w-7" onClick={mobileMutedHandler}>
                  {muted ? (
                    <SpeakerXMarkIcon className="w-full" />
                  ) : (
                    <SpeakerWaveIcon className="w-full" />
                  )}
                </button>
              </div>

              {/* Timeline Container */}
              <div
                style={{ touchAction: 'none' }}
                ref={timelineContainerRef}
                className="group/timeline mx-2 flex h-6 cursor-pointer items-center"
                onMouseDown={toggleScrubbing}
                onMouseMove={timelineUpdateHandler}
                onTouchStart={scrubbingTouchStartHandler}
                onTouchMove={scrubbingTouchMoveHandler}
                onTouchEnd={scrubbingTouchEndHandler}
              >
                {/* Timeline */}
                <div className="relative h-1 w-full bg-neutral-500/50">
                  {/* Preview track */}
                  <div
                    style={{ right: `${(1 - previewPosition) * 100}%` }}
                    className="absolute top-0 bottom-0 left-0 z-30 hidden bg-neutral-300/50 group-hover/timeline:block"
                  />
                  {/* Progress track */}
                  <div
                    style={{ right: `${(1 - progressPosition) * 100}%` }}
                    className="absolute top-0 bottom-0 left-0 z-40 bg-primary"
                  />
                  {/* Thumb */}
                  <div
                    style={{ left: `${progressPosition * 100}%` }}
                    className="absolute -top-full z-50 aspect-square h-[300%] -translate-x-1/2 scale-0 rounded-full bg-primary transition-transform duration-150 ease-in-out group-hover/timeline:scale-100"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <video
        ref={videoRef}
        className="pointer-events-none h-full w-full object-cover"
        src={src}
        playsInline
        autoPlay
        onPlay={onPlay}
        onPause={onPause}
        onEnded={onEnded}
        onLoadStart={() => {
          console.log('started loading video data');
        }}
        onLoadedData={onLoadedData}
        onLoadedMetadata={() => {}}
        onError={() => {
          trackEvent('interview_practice', 'record_playback_error');
        }}
        onAbort={() => {}}
        onStalled={() => {}}
        onSuspend={() => {}}
        onTimeUpdate={onTimeUpdate}
        onVolumeChange={onVolumeChange}
        onSeeking={() => {}}
        onSeeked={() => {}}
      ></video>
    </div>
  );
};
