import { useEffect, useRef } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { useAppSelector } from '../../../redux';

export const Feedback = () => {
  const navigate = useRef(useNavigate());
  const {
    websocket,
    videoUpload: { lastRecording },
  } = useAppSelector((state) => state);

  useEffect(() => {
    if (
      !websocket.messageData ||
      websocket.messageData.status === 'Error' ||
      websocket.messageData.filename !== lastRecording?.fileName.split('.')[0]
    ) {
      navigate.current('/record');
    }
  }, [websocket, lastRecording]);

  return <Outlet />;
};
