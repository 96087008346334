import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTrackEvent } from '../../../hooks';
import { SEO } from '../../../components';
import { Container, Button } from '../../../components/Elements';
import { ReactComponent as DontAskSVG } from '../assets/dont-ask.svg';
import { ReactComponent as PoorSVG } from '../assets/poor.svg';
import { ReactComponent as OkaySVG } from '../assets/okay.svg';
import { ReactComponent as GoodSVG } from '../assets/good.svg';
import { ReactComponent as GreatSVG } from '../assets/great.svg';

const performanceOptions = [
  {
    icon: <DontAskSVG />,
    value: 'very-unsatisfied',
    label: "don't ask",
  },
  {
    icon: <PoorSVG />,
    value: 'unsatisfied',
    label: 'poor',
  },
  {
    icon: <OkaySVG />,
    value: 'neutral',
    label: 'okay',
  },
  {
    icon: <GoodSVG />,
    value: 'satisfied',
    label: 'good',
  },
  {
    icon: <GreatSVG />,
    value: 'very-satisfied',
    label: 'great',
  },
];

export const Survey = () => {
  const [feeling, setFeeling] = useState('');
  const [comments, setComments] = useState('');
  const navigate = useNavigate();
  const { trackEvent } = useTrackEvent();

  useEffect(() => {
    trackEvent('interview_practice', 'quick_survey_start');
  }, [trackEvent]);

  const feelingHandler = (value: string) => {
    setFeeling(value);
  };

  const submitHandler = () => {
    trackEvent('interview_practice', 'quick_survey_submit', {
      feeling,
      comments,
    });

    navigate('/reflection/performance');
  };

  return (
    <>
      <SEO title="Survey" />

      <Container className="grid max-w-[414px] justify-items-center">
        <p className="mt-[30px] max-w-[366px] text-center text-navy-500">
          Choose the emoji that best matches how you feel about your performance overall.
        </p>

        <ul className="mt-9 flex w-full justify-between">
          {performanceOptions.map(({ icon, label, value }) => (
            <li key={value}>
              <button
                onClick={() => feelingHandler(value)}
                className={`grid justify-items-center transition-opacity duration-200 ${
                  feeling !== '' && feeling !== value ? 'opacity-50' : 'opacity-100'
                }`}
              >
                {icon}
                <span className="text-xs">{label}</span>
              </button>
            </li>
          ))}
        </ul>

        <label htmlFor="comments" className="mt-10 max-w-[390px] text-center text-navy-500">
          If you like, tell us more about why you felt this way. This will help us improve your
          practice options.
        </label>

        <textarea
          id="comments"
          value={comments}
          onChange={(e) => setComments(e.target.value)}
          className="mt-16 w-full max-w-[340px] rounded border border-primary p-4"
          rows={5}
        />

        <Button
          onClick={submitHandler}
          disabled={feeling === ''}
          className="mt-14 w-full max-w-[360px]"
        >
          Submit
        </Button>
      </Container>
    </>
  );
};
