import {
  ForgotPasswordAction,
  LoginAction,
  SignupAction,
  SubmitPasswordAction,
  VerifySignupAction,
  ForgotPasswordState,
  LoginState,
  SignupState,
  SubmitPasswordState,
  VerifySignupState,
} from '../types';
import {
  initialForgotPasswordState,
  initialSignupState,
  initialSubmitPasswordState,
  initialVerifySignupState,
} from './initial-states';

export const loginReducer = (state: LoginState, action: LoginAction): LoginState => {
  switch (action.type) {
    case 'loginAttempt':
      return { ...state, attemptingLogin: true, loginError: false, loginErrorMessage: '' };
    case 'loginSuccess':
      return { ...state, attemptingLogin: false, isLoggedIn: true };
    case 'loginFail':
      return {
        ...state,
        attemptingLogin: false,
        loginError: true,
        loginErrorMessage: action.payload,
      };
    case 'logout':
      return { ...state, isLoggedIn: false };
    case 'loginReset':
      return { ...state, attemptingLogin: false, loginError: false, loginErrorMessage: '' };
    default:
      return state;
  }
};

export const signupReducer = (state: SignupState, action: SignupAction): SignupState => {
  switch (action.type) {
    case 'signupAttempt':
      return { ...state, attemptingSignup: true, signupError: false, signupErrorMessage: '' };
    case 'signupSuccess':
      return { ...state, attemptingSignup: false };
    case 'signupFail':
      return {
        ...state,
        attemptingSignup: false,
        signupError: true,
        signupErrorMessage: action.payload,
      };
    case 'signupReset':
      return { ...initialSignupState };

    default:
      return state;
  }
};

export const verifySignupReducer = (
  state: VerifySignupState,
  action: VerifySignupAction
): VerifySignupState => {
  switch (action.type) {
    case 'verifySignupAttempt':
      return { ...state, attemptingVerify: true, verifyError: false, verifyErrorMessage: '' };
    case 'verifySignupSuccess':
      return { ...state, attemptingVerify: false };
    case 'verifySignupFail':
      return {
        ...state,
        attemptingVerify: false,
        verifyError: true,
        verifyErrorMessage: action.payload,
      };
    case 'verifySignupReset':
      return { ...initialVerifySignupState };
    default:
      return state;
  }
};

export const forgotPasswordReducer = (
  state: ForgotPasswordState,
  action: ForgotPasswordAction
): ForgotPasswordState => {
  switch (action.type) {
    case 'forgotPasswordAttempt':
      return { ...state, searchingUser: true, forgotPasswordError: false, errorMessage: '' };
    case 'forgotPasswordSuccess':
      return { ...state, searchingUser: false };
    case 'forgotPasswordFail':
      return {
        ...state,
        searchingUser: false,
        forgotPasswordError: true,
        errorMessage: action.payload,
      };
    case 'forgotPasswordReset':
      return { ...initialForgotPasswordState };
    default:
      return state;
  }
};

export const submitPasswordReducer = (
  state: SubmitPasswordState,
  action: SubmitPasswordAction
): SubmitPasswordState => {
  switch (action.type) {
    case 'submitPasswordAttempt':
      return { ...state, attemptingSubmit: true, submitError: false, submitErrorMessage: '' };
    case 'submitPasswordSuccess':
      return { ...state, attemptingSubmit: false, submitSuccess: true };
    case 'submitPasswordFail':
      return {
        ...state,
        attemptingSubmit: false,
        submitError: true,
        submitErrorMessage: action.payload,
      };
    case 'submitPasswordReset':
      return { ...initialSubmitPasswordState };
    default:
      return state;
  }
};
