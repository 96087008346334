import { ReactComponent as Wheel } from '../../assets/progress-wheel/large-wheel/wheel.svg';
import { ReactComponent as Track } from '../../assets/progress-wheel/large-wheel/track.svg';
import { ReactComponent as Bar1 } from '../../assets/progress-wheel/large-wheel/bar-1.svg';
import { ReactComponent as Bar2 } from '../../assets/progress-wheel/large-wheel/bar-2.svg';
import { ReactComponent as Bar3 } from '../../assets/progress-wheel/large-wheel/bar-3.svg';
import { ReactComponent as Bar4 } from '../../assets/progress-wheel/large-wheel/bar-4.svg';
import { ReactComponent as Bar5 } from '../../assets/progress-wheel/large-wheel/bar-5.svg';

export type ProgressWheelLargeProps = {
  step: number;
  descriptions?: string[];
  message?: string;
  className?: string;
};

export const ProgressWheelLarge = ({
  step,
  descriptions,
  className = '',
  message = ''
}: ProgressWheelLargeProps) => {
  return (
    <div className={`${className} hidden lg:block`}>
      <Wheel />
      <Track className="absolute top-1/2 -translate-y-1/2" />
      <Bar1 className="absolute top-[44px] z-50" />
      <Bar2
        className={`absolute top-[102px] left-[125px] z-40 transition-all duration-200 ${
          step >= 1 ? 'scale-100' : 'scale-0'
        }`}
      />
      <Bar3
        className={`absolute top-1/2 left-[198px] z-30 -translate-y-1/2 transition-all duration-200 ${
          step >= 2 ? 'scale-100' : 'scale-0'
        }`}
      />
      <Bar4
        className={`absolute bottom-[99px] left-[118px] z-20 transition-all duration-200 ${
          step >= 3 ? 'scale-100' : 'scale-0'
        }`}
      />
      <Bar5
        className={`absolute bottom-[46px] z-10 transition-all duration-200 ${
          step >= 4 ? 'scale-100' : 'scale-0'
        }`}
      />

      <p className="absolute top-1/2 left-5 w-[147px]  -translate-y-1/2 pl-1 text-white">
        {descriptions ? descriptions[step]: message}
      </p>
    </div>
  );
};
