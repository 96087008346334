import { PersonalizedFeedback } from '../types';
import { getEyeContactFeedback } from './getEyeContactFeedback';
import { getHeadPostureFeedback } from './getHeadPostureFeedback';
import { getPauseFeedback } from './getPauseFeedback';
import { getFillerWordFeedback } from './getFillerWordFeedback';
import { getConfidenceFeedback } from './getConfidenceFeedback';

export const generateFeedback = (
  eyeContactScore: string,
  headPostureScore: string,
  pauseScore: string,
  fillerScore: string,
  confidenceFeedback: string
): PersonalizedFeedback[] => {
  return [
    getEyeContactFeedback(eyeContactScore),
    getHeadPostureFeedback(headPostureScore),
    getPauseFeedback(pauseScore),
    getFillerWordFeedback(fillerScore),
    getConfidenceFeedback(confidenceFeedback)
  ];
};
