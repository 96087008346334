let config = {
  ApplicationName: "PLA-CCS",
  ApplicationId: "07b0e522-a1b8-4741-a2ee-90ae9c660f75",
  ApiKeyId: "aff5b10a-f18a-4ef0-a68c-f9c1b5b0b47b",
  ApiKeyValue:
    "3eZBnIMU8W06anVnbqE/KjB4hJtJms0lDyzEjLtqJ3PlBxcSU1XbcM7pbTdQJp6wSuKUe2BeGx4c7iDKnI3oUA==",
};

let baseUrl = `https://ailabs-analytics-pipeline.ets.org`;

let endpoints = {
  authorizations: `${baseUrl}/applications/${config.ApplicationId}/authorizations`,
  applications: `${baseUrl}/applications`,
  events: `${baseUrl}/applications/${config.ApplicationId}/events`,
};

export default {
  config,
  endpoints,
};
