// Packages
import Sockette from "sockette";

// Actions
import { connectedWebsocket, failedWebsocket, receivedMessage, disconnectedWebsocket } from "../actions/websocket";

// Config
import env from "../../configs/env";

const websocketMiddleware = () => {
  let socket: any = null;

  const onOpen = (event: any, store: any) => {
    store.dispatch(connectedWebsocket());
  };

  const onMessage = (event: any, store: any) => {
    const { data, type } = event;

    switch (type) {
      case "message":
        const aiFeedbackData = JSON.parse(data);
        store.dispatch(receivedMessage(aiFeedbackData));
        break;
      default:
        break;
    }
  };

  // the middleware part of this function
  return (store: any) => (next: any) => (action: any) => {
    switch (action.type) {
      case "CONNECT_WEBSOCKET":
        if (socket !== null) {
          socket.close();
        }

        const userStore = store.getState();
        const { user } = userStore;
        const { cognitoId } = user;

        // new socket connection
        try {
          socket = new Sockette(`${env.api.websocketUrl}?userId=${cognitoId}`, {
            timeout: 5e3,
            maxAttempts: 1,
            onopen: (e) => onOpen(e, store),
            onmessage: (e) => onMessage(e, store),
            onreconnect: (e) => console.log("Reconnecting...", e),
            onmaximum: (e) => console.log("Stop Attempting!", e),
            onclose: (e) => {
              console.log("Closed!", e)
              store.dispatch(disconnectedWebsocket());
            },
            onerror: (e) => console.log("Error:", e),
          });
        } catch (err: any) {
          store.dispatch(failedWebsocket(err));
        }
        break;

      default:
        return next(action);
    }
  };
};

export default websocketMiddleware();
