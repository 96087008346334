import { Navigate } from 'react-router-dom';
import { FullScreenLoader } from '../../../components/Elements';
import { useAppSelector } from '../../../redux';

export const AuthRedirect = () => {
  const {
    user: { status, settings },
  } = useAppSelector((state) => state);

  if (status === 'loggedIn') {
    if (settings && settings.isBoarded) {
      return <Navigate to="/dashboard" replace />;
    }

    return <Navigate to="/onboarding" replace />;
  }

  return <FullScreenLoader />;
};
