import { useNavigate } from 'react-router-dom';
import { MutableRefObject, Fragment } from 'react';
import { Transition, Dialog } from '@headlessui/react';
import { useTrackEvent } from '../../../hooks';

type ExitModalProps = {
  isOpen: boolean;
  onClose: () => void;
  initialFocus?: MutableRefObject<null>;
};

export const ExitModal = ({ isOpen, onClose, initialFocus }: ExitModalProps) => {
  const navigate = useNavigate();
  const { trackEvent } = useTrackEvent();

  const navigateGoalsHandler = () => {
    navigate('/goals/generate-prompts');
  };

  const navigateDashboardHandler = () => {
    navigate('/dashboard');
  };

  const cancelExitModalHandler = () => {
    trackEvent('interview_practice', 'interview_cancel_exit');
    onClose();
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-50 overflow-y-hidden "
        open={isOpen}
        onClose={cancelExitModalHandler}
        initialFocus={initialFocus}
      >
        <div className="flex h-full items-center justify-center">
          <Dialog.Overlay className="fixed inset-0 bg-black/40" />
          <Transition.Child
            as={Fragment}
            enter="duration-200 transition-all ease-in"
            enterFrom="translate-x-4 opacity-0"
            enterTo="translate-x-0 opacity-100"
            leave="duration-200 transition-all ease-in"
            leaveFrom="translate-x-0 opacity-100"
            leaveTo="translate-x-4 opacity-0"
          >
            <div className="pointer-events-auto grid items-center rounded-3xl bg-blue-gradient py-4 px-10 text-center">
              <h3 className="text-xl font-semibold tracking-wide text-white">
                Exit Mock Interview?
              </h3>
              <button
                onClick={navigateDashboardHandler}
                className="mt-6 rounded-3xl bg-primary py-2 px-4 text-lg text-navy-500"
              >
                Dashboard
              </button>
              <button
                onClick={navigateGoalsHandler}
                className="mt-6 rounded-3xl bg-primary py-2 px-4 text-lg text-navy-500"
              >
                Back to Goals
              </button>
              <button onClick={cancelExitModalHandler} className="mt-2 py-2 text-lg text-white">
                Cancel
              </button>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
