import { useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { NavLink } from 'react-router-dom';
import { Fragment } from 'react';
import { UserCircleIcon } from '@heroicons/react/20/solid';
import { useAppSelector } from '../../redux';
import { useAuth } from '../../features/auth';
import { AppSurvey } from '../AppSurvey';
import { useTrackEvent } from '../../hooks';

type AccountDropdownProps = {
  scrolled?: boolean;
};

export const AccountDropdown = ({ scrolled = false }: AccountDropdownProps) => {
  const { username } = useAppSelector((state) => state.user);
  const { signOut } = useAuth();
  const [showSurvey, setShowSurvey] = useState(false);
  const { trackEvent } = useTrackEvent();

  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        <Menu.Button
          className="flex items-center justify-center gap-1 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-primary focus-visible:ring-opacity-75"
          onClick={() => trackEvent('ccs_global', 'username_dropdown')}
        >
          <span className={`text-sm ${scrolled ? 'text-white' : 'text-navy-500'}`}>{username}</span>
          <UserCircleIcon
            className={`w-8 ${scrolled ? 'text-white' : 'text-navy-200'} hover:opacity-80"`}
            aria-hidden="true"
          />
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-1 py-1">
              <Menu.Item>
                {({ active }) => (
                  <NavLink
                    onClick={() => trackEvent('ccs_global', 'launch_settings')}
                    to="/settings"
                    className={`${
                      active ? 'bg-navy-200 text-white' : 'text-gray-900'
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  >
                    Settings
                  </NavLink>
                )}
              </Menu.Item>
            </div>

            <div className="px-1 py-1">
              <Menu.Item>
                {({ active }) => (
                  <a
                    href="mailto:authentichelp@ets.org"
                    className={`${
                      active ? 'bg-navy-200 text-white' : 'text-gray-900'
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  >
                    Contact Us
                  </a>
                )}
              </Menu.Item>
            </div>

            <div className="px-1 py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => setShowSurvey(true)}
                    className={`${
                      active ? 'bg-navy-200 text-white' : 'text-gray-900'
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  >
                    Provide Feedback
                  </button>
                )}
              </Menu.Item>
            </div>

            <div className="px-1 py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => {
                      trackEvent('ccs_global', 'user_logout');
                      signOut();
                    }}
                    className={`${
                      active ? 'bg-navy-200 text-white' : 'text-gray-900'
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  >
                    Log Out
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>

      <AppSurvey isOpen={showSurvey} onClose={() => setShowSurvey(false)} />
    </>
  );
};
