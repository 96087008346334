/**
 * Author: Mike Matos (mmatos@ets.org)
 *
 * The environmental file is used to help cut down on unnecessary duplicate code
 * that is often produced by a muli-stage environment. The combination of default
 * and staged environment variables and functions generates an output that can
 * be used by components and services.
 */

import deviceInfo from '../utils/deviceInfo';

// environmental variables object
let env: any = {};

// stage
let stage: string = process.env.REACT_APP_ENV || 'prd';
console.log('Stage:', stage);

// default settings
env.default = {
  stage,
  platform: deviceInfo.platform,
  locale: deviceInfo.locale,
  timezone: deviceInfo.timezone(),
};

// dev variables
env.dev = {
  api: {
    id: 'n777wsgzbj', // ApiGatewayId
    region: 'us-east-1',
  },
  mmai: {
    id: 'api.mmai-ccs-dev.ets-plalab-nonprod.c',
    teamId: 'ccs',
    client_id: '54sraifciuejuilng2d2s5pq48',
    client_secret: '1954nsh7gf0o4jm5a3gduglr1l8mlmdt9qlh1iusji58bor4l4hu',
  },
  websocket: {
    id: '2kpcuna7x5',
  },
};

// staging variables
env.staging = {
  api: {
    id: 's252ghm069', // ApiGatewayId
    region: 'us-east-1',
  },
  mmai: {
    id: 'api.mmai-ccs-staging.ets-plalab-nonprod.c',
    teamId: 'ccs',
    client_id: '7ddn8vlo24jfsph353gogpn11h',
    client_secret: '1ri08gokg3jivqsjhkjh1af0qr8dqb7ggpmmjcm4e0j6caln85q',
  },
  websocket: {
    id: 'iukzyroeug',
  },
};

//prod variables
env.prd = {
  api: {
    id: 'jvs4r6i3t8', // ApiGatewayId
    region: 'us-east-1',
  },
  // old
  // mmai: {
  //   id: 'api.mmai-ccs-prd.ets-plalab-nonprod.c',
  //   teamId: 'ccs',
  //   client_id: '662no5vvlgdtu7s7ofmrkh8o9l',
  //   client_secret: '3pkmuid2l9fluee8kmjbq9eefi5k76v6vmg2ptluco3k8g53l13',
  // },
  // new
  mmai: {
    id: 'api.mmai-ccs-prd.ets-plalab-authentic-prod.c',
    teamId: 'ccs',
    client_id: '3frlr6pe2q5iu4o1e20ina53fi',
    client_secret: '151o419eek5hn0ae1eu8qqlh6tego188mp1293rtp0msd3cmq3ub',
  },
  websocket: {
    id: '74rdbw62ri',
  },
};

// Sets the environment object with the desired "staged" version
env = { ...env.default, ...env[stage] };

// Appends or updates the api environment variables using previously set stage variables to create new api variables, such as endpoints
if (stage === 'prd') {
  env.api = {
    ...env.api,
    backendUrl: `https://${env.api.id}.execute-api.us-east-1.amazonaws.com/${stage}`,
    MMAI_URL: `https://${env.mmai.id}.ets.org`,
    MMAI_AUTH_URL: `https://mmai-authentication-new-ccs-${stage}.auth.us-east-1.amazoncognito.com/oauth2/token`,
    websocketUrl: `wss://${env.websocket.id}.execute-api.us-east-1.amazonaws.com/${stage}`,
  };
} else {
  env.api = {
    ...env.api,
    backendUrl: `https://${env.api.id}.execute-api.us-east-1.amazonaws.com/${stage}`,
    MMAI_URL: `https://${env.mmai.id}.ets.org`,
    MMAI_AUTH_URL: `https://mmai-authentication-ccs-${stage}.auth.us-east-1.amazoncognito.com/oauth2/token`,
    websocketUrl: `wss://${env.websocket.id}.execute-api.us-east-1.amazonaws.com/${stage}`,
  };
}

// env.api = {
//   ...env.api,
//   backendUrl: `https://${env.api.id}.execute-api.us-east-1.amazonaws.com/${stage}`,
//   MMAI_URL: `https://${env.mmai.id}.ets.org`,
//   MMAI_AUTH_URL: `https://mmai-authentication-ccs-${stage}.auth.us-east-1.amazoncognito.com/oauth2/token`,
//   websocketUrl: `wss://${env.websocket.id}.execute-api.us-east-1.amazonaws.com/${stage}`,
// };

export type ENV = {
  stage: string;
  platform: string;
  locale: string;
  timezone: string;
  api: API;
  mmai: MMAI;
  websocket: Websocket;
};

export type API = {
  id: string;
  region: string;
  backendUrl: string;
  MMAI_URL: string;
  MMAI_AUTH_URL: string;
  websocketUrl: string;
};

export type MMAI = {
  id: string;
  teamId: string;
  client_id: string;
  client_secret: string;
};

export type Websocket = {
  id: string;
};

export default env as ENV;
