import { useState, useMemo, useEffect } from 'react';
import { useAppSelector } from '../../../redux';
import { useNavigate } from 'react-router-dom';
import { SEO } from '../../../components';
import { Container, Button, Divider } from '../../../components/Elements';
import { TipCard } from '../components';
import { ReactComponent as HeartSVG } from '../../../assets/icons/check-heart.svg';
import { ReactComponent as ToolSVG } from '../../../assets/icons/tool-01.svg';
import { tipsData } from '../data';
import { ReactComponent as LeftArrow } from '../../../assets/icons/left-arrow.svg';
import { ReactComponent as RightArrow } from '../../../assets/icons/right-arrow.svg';
import { useTrackEvent } from '../../../hooks';

type Assessment = {
  name: string;
  value: number | null;
};

const initialAssessmentsState: Assessment[] = [
  {
    name: 'Presentation',
    value: null,
  },
  {
    name: 'Content',
    value: null,
  },
  {
    name: 'Organization',
    value: null,
  },
  {
    name: 'Body Language',
    value: null,
  },
  {
    name: 'Eye Contact',
    value: null,
  },
  {
    name: 'Verbal Delivery',
    value: null,
  },
  {
    name: 'Language',
    value: null,
  },
];

export const SelfAssessment = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [assessments, setAssessments] = useState(initialAssessmentsState);
  const navigate = useNavigate();
  const { trackEvent } = useTrackEvent();
  const {
    websocket,
    prompts: { selectedPrompt },
    videoUpload: { lastRecording },
  } = useAppSelector((state) => state);

  useEffect(() => {
    trackEvent('interview_practice', 'self_assess_start');
  }, [trackEvent]);

  const nextStepHandler = () => {
    if (currentStep === assessments.length - 1) {
      return;
    }

    setCurrentStep((prevStep) => prevStep + 1);
  };

  const prevStepHandler = () => {
    if (currentStep === 0) {
      return;
    }
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const setScoreHandler = (score: number) => {
    const updatedAssessments = assessments.map((assessment, index) => {
      if (index === currentStep) {
        assessment.value = score;
        return assessment;
      }

      return assessment;
    });

    setAssessments(updatedAssessments);

    const nextStepIndex = updatedAssessments.findIndex((assessment) => assessment.value === null);

    if (nextStepIndex >= 0) {
      setTimeout(() => {
        setCurrentStep(nextStepIndex);
      }, 350);
    }
  };

  const nextPageHandler = () => {
    const selfAssessmentData = assessments.reduce(
      (obj, assessment) => Object.assign(obj, { [assessment.name]: assessment.value }),
      {}
    );

    trackEvent('interview_practice', 'self_assess_submit', {
      ...selfAssessmentData,
      ...selectedPrompt,
    });

    // Reset Self Assessment Options
    const resetAssessments = assessments.map((assessment) => {
      assessment.value = null;
      return assessment;
    });

    setAssessments(resetAssessments);

    if (
      websocket &&
      websocket.messageData &&
      websocket.messageData.filename === lastRecording?.fileName.split('.')[0]
    ) {
      navigate('/feedback/ai-feedback');
    } else {
      navigate('/reflection/feedback-status');
    }
  };
  const { header, tips } = tipsData[currentStep];

  const disabled = useMemo(
    () =>
      assessments.filter((assessment) => assessment.value !== null).length !== assessments.length,
    [assessments]
  );

  return (
    <>
      <SEO title="Self Assessment" />

      <Container className="grid h-full max-w-[414px] justify-items-center">
        <div className="flex w-full flex-col items-center">
          <p className="mt-[30px] max-w-[366px] text-center text-navy-500">
            For each category below, select the option that best describes your performance.
          </p>
          <Divider className="my-6 max-w-[353px]" />

          <TipCard header={header} tips={tips} />
        </div>

        {/* I can do better/I did well buttons */}
        <div className="self-end pb-10">
          <div className="mt-8 flex gap-4">
            <Button
              onClick={() => setScoreHandler(0)}
              variant="outline"
              size="sm"
              className={`w-[169px] py-2 px-0 ${assessments[currentStep].value === 0 && 'bg-red-200'}`}
            >
              <div className="flex items-center gap-2">
                <ToolSVG  />
                <span className="block min-w-max">I can do better</span>
              </div>
            </Button>

            <Button
              onClick={() => setScoreHandler(1)}
              variant="outline"
              size="sm"
              className={`w-[169px] py-2 ${assessments[currentStep].value === 1 && 'bg-green-200'}`}
            >
              <div className="flex items-center gap-2">
                <HeartSVG />
                <span className="block min-w-max">I did well!</span>
              </div>
            </Button>
          </div>

          {/* Progress Bar */}
          <div className="mt-8 flex w-full items-center gap-3">
            <Button
              variant="progressBack"
              size="progress"
              className={`${currentStep === 0 && 'invisible'}`}
              onClick={prevStepHandler}
            >
              <LeftArrow style={{ marginRight: '3px' }} />
            </Button>
            <div className="grid flex-1 grid-cols-7 gap-3">
              {assessments.map((assessment, index) => (
                <div
                  key={assessment.name}
                  className={`h-[6px] rounded-[4px] transition-all duration-200 
                ${assessment.value === null && currentStep !== index && 'bg-neutral-300'} 
                ${assessment.value !== null && currentStep !== index && 'bg-navy-500'} 
                ${currentStep === index && 'bg-primary'}`}
                />
              ))}
            </div>
            <Button
              variant="progressForward"
              size="progress"
              className={`${currentStep === assessments.length - 1 && 'invisible'}`}
              onClick={nextStepHandler}
            >
              <RightArrow style={{ marginLeft: '3px' }} />
            </Button>
          </div>

          <Button
            onClick={nextPageHandler}
            disabled={disabled}
            className="mt-8 w-full max-w-[360px]"
          >
            Next
          </Button>
        </div>
      </Container>
    </>
  );
};
