/* 
Note: The structure of this file is going to constantly change as 
personalization and MMAI features are added. Most of this file is a
work in progress.
*/
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTrackEvent } from '../../../hooks';
import { SEO } from '../../../components';
import { useAppSelector, useAppDispatch, getHistoryItems, getRecordingCount } from '../../../redux';
import { ProgressWheelLarge, SectionHeader, VideoPlayer } from '../../../components';
import { Button } from '../../../components/Elements';

import { generateFeedback } from '../utils';

import { ReactComponent as LeftArrow } from '../../../assets/icons/left-arrow.svg';
import { ReactComponent as RightArrow } from '../../../assets/icons/right-arrow.svg';
import { ReactComponent as RedWrench } from '../../../assets/icons/red-wrench.svg';
import { ReactComponent as BlueWrench } from '../../../assets/icons/blue-wrench.svg';
import { ReactComponent as YellowWrench } from '../../../assets/icons/yellow-wrench.svg';
import { ReactComponent as HeartIcon } from '../../../assets/icons/feedback-heart.svg';
import { ReactComponent as Marker } from '../assets/marker.svg';

import { getVideoSrc } from '../../../utils/mmai';
import fixWebmDuration from 'webm-duration-fix';
import { PersonalizedFeedback } from '../types';

export const AiFeedback = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [videoUrl, setVideoUrl] = useState('');
  const [feedback, setFeedback] = useState<PersonalizedFeedback[]>([]);
  const [topFiller, setTopFiller] = useState('');

  const { trackEvent } = useTrackEvent();
  const dispatch = useAppDispatch();
  const {
    websocket,
    videoUpload: { lastRecording },
  } = useAppSelector((state) => state);
  const navigate = useNavigate();

  const steps = ['eye contact', 'head movement', 'pauses', 'filler', 'confidence', 'video'];

  // Event Tracking
  useEffect(() => {
    trackEvent('interview_practice', 'ai_feedback_start');

    // If the user successfully gets feedback from mmai, their history data will be stale in redux
    // This updates the history state to stale to let the application know to reload history data when
    // navigating to the history screen
    // dispatch(setStaleHistory());

    dispatch(getHistoryItems());
    dispatch(getRecordingCount());
  }, [trackEvent, dispatch]);

  // Fetch the src url to display to the user
  useEffect(() => {
    const getVideoUrl = async () => {
      if (!lastRecording) return;
      const url = await getVideoSrc(lastRecording.fileKey);

      if (!url) return;

      // .webm duration fix TODO: move to util since it is used in two other places in the application
      if (url.includes('.webm')) {
        const data = await fetch(url);
        const blob = await data.blob();
        const fixedBlob = await fixWebmDuration(blob);
        const newUrl = URL.createObjectURL(fixedBlob);
        setVideoUrl(newUrl);
      } else {
        setVideoUrl(url);
      }
    };

    getVideoUrl();
  }, [lastRecording]);

  // Helper for sending score events
  const sendScoreEvents = useCallback(
    (data: PersonalizedFeedback[]) => {
      data.forEach((category) => {
        let eventCategory = '';
        switch (category.heading) {
          case 'Eye Contact':
            eventCategory = 'eye_contact';
            break;
          case 'Head Movement':
            eventCategory = 'head_movement';
            break;
          case 'Pauses':
            eventCategory = 'pauses';
            break;
          case 'Filler Words':
            eventCategory = 'fillers';
            break;
          case 'Confidence':
            eventCategory = 'confidence';
            break;
          default:
            break;
        }

        trackEvent('interview_practice', `ai_feedback_${eventCategory}`, {
          score: `${category.percentageScore}%`,
          description: category.scoreMessage,
        });
      });
    },
    [trackEvent]
  );

  // Format the feedback scores and tips
  useEffect(() => {
    if (websocket.messageData?.feedback) {
      const {
        eye_gaze_percent,
        head_posture_percent,
        filler_percent,
        pause_percent,
        fillers_top,
        ConfidenceFeedback,
      } = websocket.messageData.feedback;

      const data = generateFeedback(
        eye_gaze_percent,
        head_posture_percent,
        pause_percent,
        filler_percent,
        ConfidenceFeedback
      );

      setTopFiller(fillers_top);
      setFeedback(data);

      // Send score event for each category
      sendScoreEvents(data);
    }
  }, [websocket, trackEvent, sendScoreEvents]);

  const nextStepHandler = () => {
    if (currentStep === steps.length - 1) {
      return;
    }

    // send event for navigating to video playback
    if (currentStep === steps.length - 2) {
      trackEvent('interview_practice', 'ai_feedback_video');
    }

    setCurrentStep((prevStep) => prevStep + 1);
  };

  const prevStepHandler = () => {
    if (currentStep === 0) {
      return;
    }
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const navigateToSurveyHandler = () => {
    trackEvent('interview_practice', 'ai_feedback_submit');
    navigate('/feedback/survey');
  };

  const colorScheme = {
    marker: {
      low: 'fill-feedback-low',
      almost: 'fill-feedback-almost',
      optimal: 'fill-feedback-optimal',
      high: 'fill-feedback-high',
    },
    fill: {
      low: 'bg-feedback-low-gradient',
      almost: 'bg-feedback-almost-gradient',
      optimal: '',
      high: 'bg-feedback-high-gradient',
    },
    text: {
      low: 'text-feedback-low',
      almost: 'text-feedback-almost',
      optimal: 'text-feedback-optimal',
      high: 'text-feedback-high',
    },
  };

  const currentFeedback = (feedback.length > 0 && feedback[currentStep]) || null;

  return (
    <>
      <SEO title="Feedback" />

      <div className="container mx-auto grid max-w-[470px] justify-items-center pb-10">
        <SectionHeader heading="Personalized Feedback" step={4} />

        <section className="relative mt-11 min-h-[565px] w-full overflow-x-hidden bg-feedback-background px-4 pt-4 pb-12 text-white">
          {currentFeedback && currentStep !== 5 && (
            <>
              {/* Heading */}
              <h3 className="flex min-h-[40px] items-center text-2xl">
                <span className="mr-2 uppercase">{currentFeedback.heading}</span>
                <span>{currentFeedback.icon === 'low' && <BlueWrench />}</span>
                <span>{currentFeedback.icon === 'almost' && <YellowWrench />}</span>
                <span>{currentFeedback.icon === 'high' && <RedWrench />}</span>
                <span>{currentFeedback.icon === 'optimal' && <HeartIcon />}</span>
              </h3>

              {/* Subheading */}
              <p className="mt-4">
                <span>{currentFeedback.subHeading}</span>

                {/* Show for all except Confidence */}
                {currentFeedback.heading !== 'Confidence' && (
                  <span>
                    <span className="font-bold">{currentFeedback.percentageScore}%</span> of the
                    time.
                  </span>
                )}

                {/* Only for Filler Words */}
                {currentFeedback.heading === 'Filler Words' && topFiller && (
                  <span>
                    {' '}
                    Your most common filler: <span className="font-bold">{topFiller}</span>
                  </span>
                )}
              </p>

              {/* Scoring display */}
              <div className="relative my-10">
                {/* Marker */}
                <div
                  // key={currentStep}
                  style={{
                    left: `${Math.min(
                      currentFeedback.percentageScore *
                        (currentFeedback.heading.includes('Filler') ? 10 : 1),
                      100
                    )}%`,
                  }}
                  className="absolute transition-all duration-500"
                >
                  <Marker
                    className={`absolute -translate-y-full -translate-x-1/2 ${
                      colorScheme.marker[currentFeedback.icon]
                    }`}
                  />
                </div>

                {/* Score Fill */}
                {/* Fill logic differs for Confidence scoring */}
                {currentFeedback.heading === 'Confidence' ? (
                  <div
                    style={{
                      left: `${
                        currentFeedback.scoreMessage === 'low'
                          ? 0
                          : currentFeedback.scoreMessage === 'medium'
                          ? 30
                          : 60
                      }%`,
                      right: `${
                        currentFeedback.scoreMessage === 'low'
                          ? 60
                          : currentFeedback.scoreMessage === 'medium'
                          ? 30
                          : 0
                      }%`,
                    }}
                    className={`absolute h-full transition-all duration-500 ${
                      colorScheme.fill[currentFeedback.icon]
                    }`}
                  />
                ) : (
                  <div
                    style={{
                      right: `${Math.max(
                        100 -
                          currentFeedback.percentageScore *
                            (currentFeedback.heading.includes('Filler') ? 10 : 1),
                        0
                      )}%`,
                    }}
                    className={`absolute left-0 h-full transition-all duration-500 ${
                      colorScheme.fill[currentFeedback.icon]
                    }`}
                  />
                )}

                {/* Score Message */}
                <div
                  style={{
                    left: `${Math.min(
                      currentFeedback.percentageScore *
                        (currentFeedback.heading.includes('Filler') ? 10 : 1),
                      100
                    )}%`,
                  }}
                  className="absolute transition-all duration-500"
                >
                  <p
                    className={`max-w-[16ch] translate-y-5 text-sm leading-none ${
                      colorScheme.text[currentFeedback.icon]
                    }
                  ${
                    currentFeedback.percentageScore >
                      (currentFeedback.heading.includes('Filler') ? 1 : 5) &&
                    currentFeedback.percentageScore <
                      (currentFeedback.heading.includes('Filler') ? 10 : 90) &&
                    'w-[16ch] -translate-x-1/2 text-center'
                  }
                  ${
                    currentFeedback.percentageScore >=
                      (currentFeedback.heading.includes('Filler') ? 10 : 90) &&
                    'min-w-max -translate-x-full'
                  }
                  `}
                  >
                    {currentFeedback.scoreMessage}
                  </p>
                </div>

                {/* Optimal Range */}
                {/*  */}
                {currentFeedback.heading === 'Confidence' ? (
                  <div
                    style={{
                      left: `${currentFeedback.optimalLowerBound}%`,
                      right: `${100 - currentFeedback.optimalUpperBound}%`,
                    }}
                    className={`absolute h-full bg-feedback-optimal-gradient ${
                      currentFeedback.icon === 'optimal' ? 'block' : 'hidden'
                    }`}
                  />
                ) : (
                  <div
                    style={{
                      left: `${
                        currentFeedback.optimalLowerBound *
                        (currentFeedback.heading.includes('Filler') ? 10 : 1)
                      }%`,
                      right: `${
                        100 -
                        currentFeedback.optimalUpperBound *
                          (currentFeedback.heading.includes('Filler') ? 10 : 1)
                      }%`,
                    }}
                    className={`absolute h-full bg-feedback-optimal-gradient ${
                      currentFeedback.icon !== 'optimal' && 'opacity-75'
                    }`}
                  />
                )}

                {/* Grid lines */}
                <div className="z-30 grid h-4 grid-cols-10 border border-white">
                  <div />
                  <div className="border-x" />
                  <div />
                  <div className="border-x" />
                  <div />
                  <div className="border-x" />
                  <div />
                  <div className="border-x" />
                  <div />
                  <div className="border-l" />
                </div>
              </div>

              {/* Optimal Range */}

              {/* Filler Words Category */}
              {currentFeedback.heading.includes('Filler') ? (
                <p>
                  No more than{' '}
                  <span className="font-bold">{currentFeedback.optimalUpperBound}%</span> of your
                  words should be fillers.
                </p>
              ) : currentFeedback.heading === 'Confidence' ? (
                <p>
                  Your perceived confidence level is{' '}
                  <span className="font-bold uppercase">
                    {currentFeedback.percentageScore === 20
                      ? 'low'
                      : currentFeedback.percentageScore === 50
                      ? 'medium'
                      : 'high'}
                  </span>
                </p>
              ) : (
                <p>
                  A score between{' '}
                  <span className="font-bold">
                    {currentFeedback.optimalLowerBound} - {currentFeedback.optimalUpperBound}%
                  </span>{' '}
                  is considered optimal.
                </p>
              )}

              {/* Tips */}
              <h3 className="mt-8 font-bold uppercase tracking-wide">
                {currentFeedback.tipHeader}
              </h3>

              <ul className="mt-4">
                {currentFeedback.tips.map((tip) => (
                  <li className="mt-2" key={currentFeedback.heading + tip}>
                    {tip}
                  </li>
                ))}
              </ul>
            </>
          )}

          <div className="absolute inset-0">
            {videoUrl && currentStep === steps.length - 1 && (
              <VideoPlayer
                src={videoUrl}
                eventType="interview_practice"
                eventPrefix="video"
                controlsOffsetY="mb-4"
              />
            )}
          </div>
        </section>

        {/* Progress Bar */}
        <div className="mt-6 flex w-full items-center gap-3 px-3">
          <Button
            variant="progressBack"
            size="progress"
            className={`${currentStep === 0 && 'invisible'}`}
            onClick={prevStepHandler}
          >
            <LeftArrow style={{ marginRight: '3px' }} />
          </Button>
          <div className="grid flex-1 grid-cols-6 gap-3">
            {steps.map((item, index) => (
              <div
                key={item}
                className={`h-[6px] rounded-[4px] transition-all duration-200 
                ${currentStep === index ? 'bg-navy-500' : 'bg-neutral-300'}`}
              />
            ))}
          </div>
          <Button
            variant="progressForward"
            size="progress"
            className={`${currentStep === steps.length - 1 && 'invisible'}`}
            onClick={nextStepHandler}
          >
            <RightArrow style={{ marginLeft: '3px' }} />
          </Button>
        </div>

        {/* Apply Feedback (navigate to next screen) */}
        <Button
          onClick={navigateToSurveyHandler}
          disabled={currentStep !== steps.length - 1}
          className="mt-6 w-full max-w-[320px]"
        >
          Apply Your Feedback
        </Button>
      </div>

      {/* <ProgressWheelLarge
        className="fixed left-0 top-1/2 -translate-y-1/2"
        step={3}
        message="Personalized Feedback"
      /> */}
    </>
  );
};
