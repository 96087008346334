export const tipsData = [
  {
    header: 'Presentation',
    color: "purple",
    image: "mock-interview/presentation.png",
    tips: ['Appears polished and confident', 'Background is free of distracting objects or noises'],
  },
  {
    header: 'Content',
    color: "orange",
    image: "mock-interview/content.png",
    tips: [
      'Provides example of a specific situation',
      'Explains how situation relates to a clear task or goal',
      'Describes actions taken toward the goal',
      'Provides a positive and measurable outcome of actions',
    ],
  },
  {
    header: 'Organization',
    color: "yellow",
    image: "mock-interview/organization.png",
    tips: [
      'Response has a clear beginning, middle, and end',
      'Presents content in a cohesive manner',
    ],
  },
  {
    header: 'Body Language',
    color: "green",
    image: "mock-interview/body-language.png",
    tips: [
      'Maintains good posture',
      'Effectively uses gestures and facial expressions',
      'Avoids distracting mannerisms (swiveling in chair, tapping pen, etc.)',
    ],
  },
  {
    header: 'Eye Contact',
    color: "lightBlue",
    image: "mock-interview/eye-contact.png",
    tips: [
      'Maintains eye contact with the camera',
      'Avoids looking away for a significant amount of time',
    ],
  },
  {
    header: 'Verbal Delivery',
    color: "red",
    image: "mock-interview/verbal-delivery.png",
    tips: [
      'Speaks clearly and effectively',
      'Varies volume and pace of speaking',
      'Avoids verbal fillers (e.g., “um” and “ah”)',
      'Makes effective use of pauses',
    ],
  },
  {
    header: 'Language',
    color: "navy",
    image: "mock-interview/language.png",
    tips: [
      'Language choices are enhance effectiveness of my response',
      'Language and tone are appropriate for my interview',
    ],
  },
];
