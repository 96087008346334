import { useState, useEffect } from 'react';
import { Disclosure, Transition } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/24/solid';
import { FilmIcon } from '@heroicons/react/24/outline';

import { MobileVideoPlayer } from './MobileVideoPlayer';
import { Divider } from '../../../components/Elements';
import { ParsedFeedback } from '../../../apis/HistoryAPI';
import { useTrackEvent } from '../../../hooks';

import { ReactComponent as PlayCircleIcon } from '../assets/play_circle_outline.svg';

import { formatDate, formatDuration } from '../utils';
import { getThumbnailSrc } from '../../../utils/mmai';
import { UserPrompt } from '../../../apis/UserPromptsAPI';

type HistoryItemsProps = {
  parsedFeedback: ParsedFeedback;
  created: string;
  promptRecord: UserPrompt;
  duration: number;
  eventType?: string;
  eventPrefix?: string;
};

export const HistoryItem = ({
  created,
  parsedFeedback,
  promptRecord,
  duration,
  eventPrefix = '',
  eventType = ''
}: HistoryItemsProps) => {
  const [mobilePlayerOpen, setMobilePlayerOpen] = useState(false);
  const [thumbnailSrc, setThumbnailSrc] = useState('');
  const { trackEvent } = useTrackEvent();

  const {
    service_results: serviceResults,
    MMAI_requestID,
    file_extension,
    picture_key,
    filename,
  } = parsedFeedback;

  // const {
  //   articulation_rate, // the number of content words spoken per second
  //   net_speaking_time, // total duration of content words
  //   response_length, // number of content words (as opposed to silence, noise, and hesitation markers) in the hypothesis
  //   num_fillers, // number of fillers, defined as filled pauses or repetitions
  //   num_pauses, // number of pauses, defined as silences >145ms and filled pauses
  // } = audioFeatures;

  const {
    filler_percent: fillerPercent,
    // fillers_top: fillerTop,
    head_posture_percent: headPosturePercent,
    pause_percent: pausePercent,
    eye_gaze_percent: eyeGazePercent,
  } = serviceResults;

  useEffect(() => {
    if (picture_key) {
      (async () => {
        const src = await getThumbnailSrc(picture_key);

        if (!src) return;

        setThumbnailSrc(src);
      })();
    }
  }, [picture_key]);

  const openMobilePlayer = () => {
    setMobilePlayerOpen(true);
    trackEvent(eventType, `${eventPrefix}_open_video`, { filename });
  };

  const closeMobilePlayer = () => {
    setMobilePlayerOpen(false);
    trackEvent(eventType, `${eventPrefix}_close_video`, { filename });
  };

  const floor = (num: string) => {
    return Math.floor(+num);
  };

  const fileKey = MMAI_requestID.replace('ccs', 'upload_files') + file_extension;

  return (
    <li className="relative grid grid-cols-[78px_1fr] gap-2 text-navy-500">
      <button className="absolute top-0 right-1" onClick={openMobilePlayer}>
        <PlayCircleIcon className="w-11 fill-navy-200" />
      </button>

      <div
        className={`grid aspect-square place-items-center rounded-lg ${
          !thumbnailSrc && 'border border-neutral-500'
        }`}
      >
        {thumbnailSrc ? (
          <img src={thumbnailSrc} alt="video thumbnail" className="rounded-lg" />
        ) : (
          <FilmIcon className="w-3/4" />
        )}
      </div>

      <div className="flex flex-col gap-2">
        {/* Date */}
        <p className="">{formatDate(created)}</p>
        {/* Duration */}
        <p>
          <span className="font-bold">Duration: </span>
          <span>{formatDuration(duration)}</span>
        </p>
      </div>

      <div className="col-span-full mb-2 grid gap-2">
        {/* Prompt */}
        <p className="font-light">
          <span className="font-bold">Prompt:</span> {promptRecord.prompt}
        </p>

        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button
                className="flex w-full justify-between rounded-lg border border-neutral-300 px-4 py-2 text-left text-sm font-medium focus:outline-none focus-visible:ring focus-visible:ring-primary focus-visible:ring-opacity-75"
                onClick={() =>
                  trackEvent(eventType, `${eventPrefix}_view_stats`, {
                    filename,
                    duration,
                    stat_visibility: !open,
                  })
                }
              >
                <span>View Stats</span>
                <ChevronUpIcon
                  className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 duration-200
                  `}
                />
              </Disclosure.Button>
              <Transition
                enter="transition duration-100 ease-out origin-top"
                enterFrom="transform scale-y-0 opacity-0"
                enterTo="transform scale-y-100 opacity-100"
              >
                <Disclosure.Panel className="p-2 text-sm text-gray-500">
                  <ul className="grid grid-cols-2 gap-1">
                    <li>
                      Eye Contact: <span className="text-navy-500">{eyeGazePercent}%</span>
                    </li>
                    <li>
                      Head Posture: <span className="text-navy-500">{headPosturePercent}%</span>
                    </li>
                    <li>
                      Pauses Percent: <span className="text-navy-500">{floor(pausePercent)}%</span>
                    </li>
                    <li>
                      Filler Percent: <span className="text-navy-500">{floor(fillerPercent)}%</span>
                    </li>
                  </ul>
                </Disclosure.Panel>
              </Transition>
            </>
          )}
        </Disclosure>
      </div>

      <Divider className="col-span-full" />

      <MobileVideoPlayer
        isOpen={mobilePlayerOpen}
        onClose={closeMobilePlayer}
        fileKey={fileKey}
        fileType={file_extension}
        prompt={promptRecord.prompt}
        eventType={eventType}
        eventPrefix={eventPrefix}
      />
    </li>
  );
};
