import { useState, useEffect } from 'react';
import { SEO } from '../../../components';
import { SectionHeader } from '../../../components/SectionHeader';
import { Container } from '../../../components/Elements';
// import { ProgressWheelLarge } from '../../../components/ProgressWheel';
import { TipCard } from '../components';
import { tipsData } from '../data';
import { useTrackEvent } from '../../../hooks';

export const Tips = () => {
  const [openKey, setOpenKey] = useState<number | null>(0);
  const { trackEvent } = useTrackEvent();

  useEffect(() => {
    trackEvent('interview_practice', 'rubric_read_start', { rubric_version: '1.0.0' });
  }, [trackEvent]);

  const handleToggle = (key: number) => {
    setOpenKey(openKey === key ? null : key);
  };

  return (
    <div className="relative">
      <SEO title="Interview Tips" />

      {/* <ProgressWheelLarge className="absolute left-0 top-32" step={1} message="Mock Interview" /> */}

      <Container className="grid max-w-[360px] justify-items-center pb-8">
        <SectionHeader step={2} heading="Mock Interview" />
        <p className="mt-5 mb-4 text-center font-light text-navy-500">
          Here are some dimensions we believe that successful interviewers excel at.
        </p>

        {tipsData.map((data, index) => (
          <TipCard
            key={data.header}
            header={data.header}
            color={data.color}
            image={data.image}
            tips={data.tips}
            index={index}
            toggle={handleToggle}
            open={openKey === index}
            length={tipsData.length}
          />
        ))}
      </Container>
    </div>
  );
};
