/**
 * Author: Mike Matos (mmatos@ets.org)
 *
 * Values can be found by looking at your AWS console. (https://console.aws.com)
 *
 * Auth.region: (us-east-1)
 * The region in which you launched your application in
 *
 * Auth.userPoolId
 * value of "Pool Id" in Services > Cognito > General settings
 *
 * Auth.userPoolWebClient
 * value of "ID" in Service > Cognito > App intergation > App client settings
 *
 * Analytics.disabled (false)
 * Suppresses unnecessary warnings cause but not using analytics
 */

type AWSConfig = {
  Auth: {
    region: string;
    userPoolId: string;
    userPoolWebClientId: string;
  };
  Analytics: {
    disabled: boolean;
  };
};

export const generateAWSConfig = (): AWSConfig => {
  const stage = process.env.REACT_APP_ENV || 'dev';

  if (stage === 'prd') {
    return {
      Auth: {
        region: 'us-east-1',
        userPoolId: 'us-east-1_fsittqhlE',
        userPoolWebClientId: '243ruuec7b13anfm86re7dl52',
      },
      Analytics: {
        disabled: true,
      },
    };
  }

  return {
    Auth: {
      region: 'us-east-1',
      userPoolId: 'us-east-1_MTpvxyvpC',
      userPoolWebClientId: '7hbu6rb53mt8e7bcnhcbgompip',
    },
    Analytics: {
      disabled: true,
    },
  };
};
