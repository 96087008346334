import axios from 'axios';
import env from '../../configs/env';
import {
  GetAuthTokenResponse,
  GetPresignedUrlResponse,
  GetFeedbackResponse,
  Fields,
  RawFeedback,
  ParsedFeedback,
} from './types';
import FeedbackService from '../../services/FeedbackService';
import fetchAuth from '../fetchAuth';
import { UserPrompt } from '../../apis/UserPromptsAPI';

export const getAuthToken = async () => {
  try {
    const urlencoded = new URLSearchParams();
    urlencoded.append('grant_type', 'client_credentials');

    const response = await axios.post(env.api.MMAI_AUTH_URL, urlencoded, {
      headers: {
        Authorization: `Basic ${btoa(env.mmai.client_id + ':' + env.mmai.client_secret)}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    return response.data as GetAuthTokenResponse;
  } catch (error) {
    console.log(error);
  }
};

export const getPreSignedUrl = async (token: string, tokenType: string, fileName?: string) => {
  const requestUrl = `${env.api.MMAI_URL}/preSigned?file=${fileName}&notification_type=callback_url`;

  try {
    const response = await axios.get(requestUrl, {
      headers: {
        Authorization: `${tokenType} ${token}`,
      },
    });

    return response.data as GetPresignedUrlResponse;
  } catch (error) {
    console.log(error);
  }
};

export const uploadVideo = async (file: File, promptRecord: UserPrompt, duration: number) => {
  const token = await getAuthToken();
  if (!token) return;

  const { access_token, token_type } = token;

  const preSignedUrl = await getPreSignedUrl(access_token, token_type, file.name);
  if (!preSignedUrl) return;

  const { fields, url } = preSignedUrl;

  const formData = setFormData(fields, file, promptRecord.prompt);

  try {
    await FeedbackService.updateRecord({
      filename: file.name,
      // userId, // TODO: Pass in User Id from Redux
      status: 'upload-initial',
      created: Date.now().toString(),
      promptRecord,
      duration,
    });

    await axios.post(url, formData);

    await FeedbackService.updateRecord({
      filename: file.name,
      status: 'upload-mmai',
    });

    return { fileKey: formData.get('key') };
  } catch (error) {
    console.log(error);
  }
};

const setFormData = (fields: Fields, file: File, prompt?: string): FormData => {
  const callbackUrl = `https://${env.api.id}.execute-api.${env.api.region}.amazonaws.com/${env.stage}/feedback/raw/${file.name}`;

  const formData = new FormData();

  Object.entries(fields).forEach(([key, value]: [string, string]) => {
    switch (key) {
      case 'x-amz-meta-callback_url':
        formData.append(key, callbackUrl);
        break;

      case 'x-amz-meta-team_id':
        formData.append(key, env.mmai.teamId);
        break;

      case 'x-amz-meta-services':
        formData.append(key, "['all']");
        break;

      case 'x-amz-meta-prompt':
        formData.append(key, prompt || '');
        break;

      default:
        formData.append(key, value);
        break;
    }
  });

  formData.append('file', file);

  return formData;
};

export const getAiFeedback = async (fileKey: string) => {
  try {
    const token = await getAuthToken();
    if (!token) throw new Error('Unable to fetch token');

    const { access_token, token_type } = token;
    const requestUrl = `${env.api.MMAI_URL}/fetch_feedback?file_key=${fileKey}&team_id=${env.mmai.teamId}`;

    const response = await axios.get(requestUrl, {
      headers: {
        Authorization: `${token_type} ${access_token}`,
      },
    });

    return response.data as GetFeedbackResponse;
  } catch (error) {
    console.log(error);
  }
};

export const getVideoSrc = async (fileKey: string) => {
  try {
    const token = await getAuthToken();
    if (!token) throw new Error('Unable to fetch token');

    const { access_token, token_type } = token;
    const requestUrl = `${env.api.MMAI_URL}/preSigned_get?file_key=${fileKey}`;

    const response = await axios.get(requestUrl, {
      headers: {
        Authorization: `${token_type} ${access_token}`,
      },
    });

    return response.data as string;
  } catch (error) {
    console.log(error);
  }
};

export const getThumbnailSrc = async (fileKey: string) => {
  try {
    const token = await getAuthToken();
    if (!token) throw new Error('Unable to fetch token');

    const { access_token, token_type } = token;
    const requestUrl = `${env.api.MMAI_URL}/preSigned_get_picture?file_key=${fileKey}`;

    const response = await axios.get(requestUrl, {
      headers: {
        Authorization: `${token_type} ${access_token}`,
      },
    });

    return response.data as string;
  } catch (error) {
    console.log(error);
  }
};

export const getRawFeedback = async (filename: string) => {
  const response = await fetchAuth(`/feedback?filename=${filename}`);
  const data = (await response.json()) as RawFeedback;
  const {
    feedback: {
      Item: { raw },
    },
  } = data;

  if (Object.keys(raw).length === 0) {
    throw new Error('Feedback not found');
  }

  const parsedFeedback = JSON.parse(raw) as ParsedFeedback;
  return parsedFeedback;
};
