import { Helmet } from 'react-helmet-async';

type SEOProps = {
  title?: string;
  description?: string;
};

export const SEO = ({ title, description }: SEOProps) => {
  return (
    <Helmet>
      {title && <title>Authentic | {title}</title>}
      {description && <metadata name="description">{description}</metadata>}
    </Helmet>
  );
};
