import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import localForage from 'localforage';
import { SectionHeader, SEO } from '../../../components';
import VideoRecorder from '../components/VideoRecorder';
import {
  resetMessageData,
  resetFeedbackTimerState,
  resetVideoUploadState,
  useAppDispatch,
  useAppSelector,
  uploadToMMAI,
} from '../../../redux';
import { VideoActionsProps } from '../types';
import { VideoActions } from '../components';
import { useTrackEvent } from '../../../hooks';

export const Record = () => {
  const [file, setFile] = useState<File>();
  const [timeLimit, setTimeLimit] = useState(90000);
  const [countdownTime, setCountdownTime] = useState(3000);
  const {
    user,
    prompts: { selectedPrompt },
  } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { trackEvent } = useTrackEvent();

  useEffect(() => {
    trackEvent('interview_practice', 'interview_practice_start');
  }, [trackEvent]);

  useEffect(() => {
    dispatch(resetMessageData());
    dispatch(resetFeedbackTimerState());
    dispatch(resetVideoUploadState());
  }, [dispatch]);

  const updateCountdownHandler = (newCountdown: number) => {
    setCountdownTime(newCountdown);
    trackEvent('interview_practice', 'update_countdown', { countdown: newCountdown });
  };

  const updateTimeLimitHandler = (newTimeLimit: number) => {
    setTimeLimit(newTimeLimit);
    trackEvent('interview_practice', 'update_time_limit', { timeLimit: newTimeLimit });
  };

  const submitVideoHandler = async (duration: number) => {
    if (!file) return;

    // Save submission data to indexDB in case the user needs to retry upload
    await localForage.setItem('uploadData', { file, promptRecord: selectedPrompt!, duration });

    if (selectedPrompt) {
      dispatch(uploadToMMAI({ file, selectedPrompt, duration }));
    }

    trackEvent('interview_practice', 'record_submit', {
      filename: file.name,
      duration,
      prompt: selectedPrompt,
    });

    navigate('/reflection/survey');
  };

  return (
    <>
      <SEO title="Mock Interview" />

      <div className="flex h-full flex-col items-center lg:px-4 lg:pt-12 lg:pb-20">
        <div className="hidden lg:block">
          <SectionHeader step={2} heading="Mock Interview" />
        </div>

        <div className="flex w-full flex-1 flex-col items-center justify-center lg:pt-14">
          <div className="aspect-video h-full w-full lg:mx-auto lg:max-h-[600px] lg:max-w-[1000px]">
            <VideoRecorder
              eventData={{ user, selectedPrompt }}
              isOnInitially
              chunkSize={250}
              countdownTime={countdownTime}
              timeLimit={timeLimit}
              constraints={{
                audio: true,
                video: {
                  facingMode: 'user',
                  frameRate: 30,
                },
              }}
              showReplayControls
              replayVideoAutoplayAndLoopOff={true}
              renderActions={(props: VideoActionsProps) => (
                <VideoActions
                  onSubmitVideo={submitVideoHandler}
                  onUpdateTimeLimit={updateTimeLimitHandler}
                  onUpdateCountdown={updateCountdownHandler}
                  {...props}
                />
              )}
              onRecordingComplete={(videoBlob: Blob) => {
                const [mimeType, codecs] = videoBlob.type.split(';');
                const [type, ext] = mimeType.split('/');
                let filename = `${uuidv4()}.${ext}`;

                let file = new File([videoBlob], `${filename}`, {
                  type: videoBlob.type,
                });

                setFile(file);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
