import { PersonalizedFeedback } from '../types';
import { isMobile } from 'react-device-detect';

export const getHeadPostureFeedback = (headPostureScore: string): PersonalizedFeedback => {
  const heading = 'Head Movement';
  const optimalLowerBound = 65;
  const optimalUpperBound = 95;
  const headPostureScoreNum = Math.round(+headPostureScore);
  const subHeading = `Your head position was stable approximately `;

  const staticFields = {
    heading,
    subHeading,
    optimalLowerBound,
    optimalUpperBound,
    percentageScore: headPostureScoreNum,
  };

  if (headPostureScoreNum <= 45) {
    return {
      ...staticFields,
      icon: 'high',
      scoreMessage: 'too much movement',
      tipHeader: 'Interview Advice',
      tips: isMobile
        ? [
            'Hold your device in a comfortable position so your face remains centered in the screen.',
            'Try to relax your face and keep your body posture upright.',
          ]
        : [
            'Place your camera slightly above eye level and center your face and upper torso in the screen.',
            'Position yourself in front of your camera with a relaxed face and upright body posture',
          ],
    };
  } else if (headPostureScoreNum <= 65) {
    return {
      ...staticFields,
      icon: 'almost',
      scoreMessage: 'almost there!',
      tipHeader: 'How To Improve',
      tips: [
        'Keep your audience engaged by using intentional gestures or movements during transitions.',
        'Avoid moving when attempting to make a key point.',
      ],
    };
  } else if (headPostureScoreNum <= 95) {
    return {
      ...staticFields,
      icon: 'optimal',
      scoreMessage: 'just right',
      tipHeader: 'Good Job!',
      tips: [
        'Your head movement is natural and relaxed.',
        'Did you know that head movements can convey your emotional state to others even in the absence of sound and facial expressions?',
      ],
    };
  } else {
    return {
      ...staticFields,
      icon: 'low',
      scoreMessage: 'too rigid',
      tipHeader: 'How To Improve',
      tips: [
        'Surprisingly, too little movement can negatively influence how others perceive you.',
        'You can better engage your audience with intentional head movements and gestures when transitioning between topics.',
      ],
    };
  }
};
