import env from "../configs/env";

export const fetchSignInFailure = async (
  input: string | RequestInfo,
  init: RequestInit = { method: "GET" }
) => {
  // Set default headers
  let defaultHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  // Merge default headers with headers passed in by user
  init.headers = { ...defaultHeaders, ...init?.headers };

  // Ensure base url does not have a trailing slash
  let lastSlashPattern = /(\/$)/g;
  let baseUrl = env.api.backendUrl.replace(lastSlashPattern, "");

  // Absolute/Relative Path Check
  let absoluteUrlPattern = new RegExp(/:\/\/|\/\//g);
  if (!absoluteUrlPattern.test(input.toString())) {
    let firstSlashPattern = /(^\/+\w{0})/g;
    let realativeUrl = input.toString().replace(firstSlashPattern, "");
    input = [baseUrl, realativeUrl].join("/");
  }

  // Return preformated fetch for authenticated api call
  return fetch(input, init);
};
