import clsx from 'clsx'
import { ReactNode } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useAppSelector } from '../../../redux';
import { useAuth } from '../context';
import { SideNavigation, TopBar } from '../../../components';
import { useLocation } from 'react-router-dom';

import { FullScreenLoader } from '../../../components/Elements';

type ProtectedRoutesProps = {
  children?: ReactNode;
};

export const ProtectedRoutes = ({ children }: ProtectedRoutesProps) => {
  const {
    checkingCredentials,
    loginState: { isLoggedIn },
  } = useAuth();
  const { status } = useAppSelector((state) => state.user);
  const {pathname} = useLocation();

  if (!checkingCredentials && !isLoggedIn) {
    return <Navigate to="/login" replace />;
  }

  return (
    <>
      {(checkingCredentials || status === 'loading' || status === 'loggedOut') && (
        <FullScreenLoader />
      )}

      <div className="flex">
        <div className="fixed top-0 left-0 bottom-0 hidden w-56 md:block">
          <SideNavigation />
        </div>

        <div className={clsx("flex min-h-100svh flex-1 flex-col md:ml-56", pathname !== '/record' && 'pt-12')}>
          <TopBar />

          <main className="flex-1" id="main-content">
            {!checkingCredentials && children ? children : <Outlet />}
          </main>
        </div>
      </div>
    </>
  );
};
