import {
  Websocket,
  WebsocketActionTypes,
  CONNECTED_WEBSOCKET,
  MESSAGE_RECEIVED,
  FAILED_WEBSOCKET,
  DISCONNECTED_WEBSOCKET,
  RESET_MESSAGE_DATA
} from '../types';

const initialWebsocketState: Websocket = { connected: false };

export function websocketReducer(
  state: Websocket = initialWebsocketState,
  action: WebsocketActionTypes
): Websocket {
  switch (action.type) {
    case CONNECTED_WEBSOCKET:
      return {
        ...state,
        connected: true,
      };

    case MESSAGE_RECEIVED:
      return {
        ...state,
        connected: true,
        messageData: action.payload,
      };

    case FAILED_WEBSOCKET:
      return {
        ...state,
        connected: false,
        error: action.payload,
      };

    case DISCONNECTED_WEBSOCKET:
      return {
        ...state,
        connected: false,
      };

    case RESET_MESSAGE_DATA: 
      return {
        connected: state.connected
      }

    default:
      return state;
  }
}
