import { Outlet } from 'react-router-dom';
import { SectionHeader } from '../../../components/SectionHeader';
import { Container } from '../../../components/Elements';

export const Goals = () => {
  return (
    <div className='relative'>
      <Container className="grid justify-items-center">
        <SectionHeader step={1} heading="Goal Selection" />
      </Container>

      <Outlet />
    </div>
  );
};
