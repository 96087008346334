import { useCallback } from 'react';
import { useAppSelector } from '../redux';
import { useLocation } from 'react-router-dom';
import { trackEventAsync } from '../services/EventService';

export const useTrackEvent = () => {
  const { cognitoId, username } = useAppSelector((state) => state.user);
  const { pathname } = useLocation();

  const trackEvent = useCallback(
    async (eventType: string, eventName: string, data?: any) => {
      if (!cognitoId) return;

      try {
        await trackEventAsync({
          eventType,
          eventName,
          username,
          userId: cognitoId,
          eventData: {
            page: pathname.slice(1), // remove leading slash
            ...data,
          },
        });
      } catch (error) {}
    },
    [cognitoId, username, pathname]
  );

  return { trackEvent };
};
