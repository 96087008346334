import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

type TipsOverlayProps = {
  showTips: boolean;
};

export const TipsOverlay = ({ showTips }: TipsOverlayProps) => {
  return (
    <div
      className={`absolute bottom-24 z-30 mx-4 max-w-[400px] rounded-lg bg-black/60 px-8 py-2 font-raleway font-semibold transition-opacity duration-200 lg:bottom-4  ${
        showTips ? 'opacity-100' : 'opacity-0'
      }`}
    >
      <ul className="list-disc text-sm md:text-base">
        <li className="relative list-none">
          <ExclamationTriangleIcon className="absolute w-5 -translate-x-6 translate-y-[3px]" />
          <span>
            Please speak for at least {process.env.REACT_APP_MIN_VIDEO_DURATION || 30} seconds to
            receive feedback
          </span>
        </li>
        <li>Face inside the oval area</li>
        <li>Avoid wearing glasses with a glare</li>
        <li>Check for adequate lighting</li>
      </ul>
    </div>
  );
};
