import { axios } from '../configs';
import { UserGetResponse, UserUpdateRecord, UserUpdateResponse } from './types';
import deviceInfo from "../../utils/deviceInfo";

export const UserAPI = {
  getUser: async function () {
    try {
      const response = await axios.get('/user');
      const data = response.data as UserGetResponse;

      return data.user.Item;
    } catch (error) {
      throw error;
    }
  },
  updateUser: async function (userUpdateRecord: UserUpdateRecord) {
    try {
      const locale = deviceInfo.locale;
      const timezone = deviceInfo.timezone();
      const updateObj = {...userUpdateRecord, timezone, locale }

      const response = await axios.post('/user', updateObj);
      const data = response.data as UserUpdateResponse;

      return data.user.Attributes;
    } catch (error) {
      throw error;
    }
  },
};
