import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { SEO } from '../../../components';
import { updateUser, useAppDispatch, useAppSelector } from '../../../redux';
import { Step } from '../components';
import { Button, Container } from '../../../components/Elements';
import { ProgressBar } from '../../../components/ProgressBar';
import { ProgressWheelSmall, ProgressWheelLarge } from '../../../components/ProgressWheel';
import { useTrackEvent } from '../../../hooks';

const content =
  '5 research-backed steps combine AI-powered feedback and performance reflection tools to help you build the communication skills employers value most.';
const steps = [
  'Goal Selection',
  'Mock Interview',
  'Rate Your Performance',
  'Personalized Feedback',
  'Reflection',
];
const events = [
  'goals',
  'interview',
  'self_assessment',
  'feedback',
  'reflect'
]
const length = steps.length;
const descriptions = [
  // 'Select an interview practice goal to personalize your experience',
  'We will generate custom interview prompts based on the job title or industry.',
  'Respond to real- world interview prompts ',
  'Reflect on what you did well and what to keep working on',
  'Get system feedback on what you did well and what to keep working on',
  'Reflect on how to improve in your next practice video',
];

export const Onboarding = () => {
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { trackEvent } = useTrackEvent();

  const { settings } = useAppSelector((state) => state.user);

  useEffect(() => {
    trackEvent('onboarding', 'onboarding_start');
  }, [trackEvent]);

  useEffect(() => {
    trackEvent('onboarding', `onboard_${events[activeStep]}`)
  }, [activeStep, trackEvent])

  const nextStep = () => {
    if (activeStep === length - 1) return;
    setActiveStep((currentStep) => currentStep + 1);
  };

  const previousStep = () => {
    if (activeStep === 0) return;
    setActiveStep((currentStep) => currentStep - 1);
  };

  const navigateToGoalsHandler = () => {
    // update the isBoarded state
    dispatch(updateUser({ settings: { ...settings, isBoarded: true } }));
    trackEvent('onboarding', 'onboarding_complete');

    navigate('/goals/generate-prompts');
  };

  return (
    <div className="overflow-x-hidden">
      <SEO title="Onboarding" />

      <Container className="grid place-items-center text-navy-500">
        <h1 className="mt-6 text-center text-3xl uppercase">How It Works</h1>

        <p className="mt-8 max-w-[627px] text-center">{content}</p>
      </Container>

      <div className="relative mt-12">
        <Container className="grid place-items-center">
          <ul className="relative grid gap-10">
            {steps.map((step, index) => (
              <Step step={index + 1} text={step} key={index} active={activeStep === index} />
            ))}
          </ul>
        </Container>
        <ProgressWheelSmall
          step={activeStep}
          className="left-0 top-9"
          descriptions={descriptions}
        />
        <ProgressWheelLarge
          step={activeStep}
          className="absolute left-0 top-1/2 -translate-y-1/2"
          descriptions={descriptions}
        />
      </div>

      <Container className="grid place-items-center pb-8">
        <Button
          onClick={navigateToGoalsHandler}
          className={`mt-7 w-full max-w-[320px] ${activeStep !== length - 1 ? 'invisible' : ''}`}
        >
          Get Started
        </Button>

        <ProgressBar
          className="mt-10 max-w-[400px] pb-6"
          step={activeStep}
          numberOfSteps={steps.length}
          previousStep={previousStep}
          nextStep={nextStep}
        />
      </Container>
    </div>
  );
};
