import { PersonalizedFeedback } from '../types';

export const getPauseFeedback = (pauseScore: string): PersonalizedFeedback => {
  const heading = 'Pauses';
  const optimalLowerBound = 5;
  const optimalUpperBound = 36;
  const pauseScoreNum = Math.round(+pauseScore);
  const subHeading = `You paused while speaking approximately `;

  const staticFields = {
    heading,
    subHeading,
    optimalLowerBound,
    optimalUpperBound,
    percentageScore: pauseScoreNum,
  };

  if (pauseScoreNum <= 5) {
    return {
      ...staticFields,
      icon: 'low',
      scoreMessage: 'too little',
      tipHeader: 'How To Improve',
      tips: [
        'Short pauses allow your audience to more effectively comprehend what you are saying.',
      ],
    };
  } else if (pauseScoreNum <= 36) {
    return {
      ...staticFields,
      icon: 'optimal',
      scoreMessage: 'just right',
      tipHeader: 'Good Job',
      tips: [
        'Your speech contained just the right amount of pauses. This can help the person you are speaking to more accurately comprehend what you are saying.',
        'Research suggests that strong public speakers will deliberately pause for two to three seconds or even longer for dramatic effect.',
      ],
    };
  } else if (pauseScoreNum <= 52) {
    return {
      ...staticFields,
      icon: 'almost',
      scoreMessage: 'almost there!',
      tipHeader: 'How To Improve',
      tips: ['Try to pause intentionally to emphasize key points in your next response.'],
    };
  } else {
    return {
      ...staticFields,
      icon: 'high',
      scoreMessage: 'too high',
      tipHeader: 'Interview Advice',
      tips: [
        'Overly long pauses can make speech difficult to understand, but short pauses can be beneficial.',
        'Reduce excessive pauses by identifying whether you pause more at the beginning, middle, or end of your response.',
      ],
    };
  }
};
