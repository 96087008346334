import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { HistoryAPI, FeedbackRecord, PaginationKey } from '../../apis/HistoryAPI';
import { RootState } from '../store';

type UserHistoryState = {
  history: {
    items: FeedbackRecord[];
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    stale: boolean;
    moreItems: boolean;
    lastEvaluatedKey?: PaginationKey;
    firstItemSet: FeedbackRecord[];
    firstLastEvaluatedKey?: PaginationKey;
    firstMoreItems: boolean;
  };
  recordingCount: number;
};

const initialState: UserHistoryState = {
  history: {
    items: [],
    status: 'idle',
    stale: false,
    moreItems: true,
    firstItemSet: [],
    firstMoreItems: true,
  },
  recordingCount: 0,
};

export const getHistoryItems = createAsyncThunk('userHistory/getHistoryItems', async () => {
  const response = await HistoryAPI.getRecordingHistory();

  return response;
});

export const getMoreHistoryItems = createAsyncThunk(
  'userHistory/getMoreHistoryItems',
  async (_: void, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const {
      userHistory: {
        history: { moreItems, lastEvaluatedKey },
      },
    } = state;

    if (moreItems && lastEvaluatedKey) {
      const response = await HistoryAPI.getRecordingHistory(
        lastEvaluatedKey.filename,
        lastEvaluatedKey.created
      );
      return response;
    }
  }
);

export const getRecordingCount = createAsyncThunk('userHistory/getRecordingCount', async () => {
  const response = await HistoryAPI.getRecordingCount();
  return response;
});

export const userHistorySlice = createSlice({
  name: 'userHistory',
  initialState,
  reducers: {
    trimHistoryData(state) {
      if (state.history.firstItemSet.length > 0) {
        state.history.items = state.history.firstItemSet;
        state.history.moreItems = state.history.firstMoreItems;
        state.history.lastEvaluatedKey = state.history.firstLastEvaluatedKey;
      }
    },
    setStaleHistory(state) {
      state.history.stale = true;
      state.history.items = [];
    },
    resetUserHistoryState: (state) => ({
      ...initialState,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHistoryItems.pending, (state) => {
        state.history.status = 'loading';
      })
      .addCase(getHistoryItems.fulfilled, (state, action) => {
        state.history.items = action.payload.Items;
        state.history.firstItemSet = action.payload.Items;
        state.history.status = 'succeeded';
        state.history.stale = false;

        if (action.payload.LastEvaluatedKey) {
          state.history.lastEvaluatedKey = action.payload.LastEvaluatedKey;
          state.history.firstLastEvaluatedKey = action.payload.LastEvaluatedKey;
        } else {
          state.history.moreItems = false;
          state.history.firstMoreItems = false;
        }
      })
      .addCase(getHistoryItems.rejected, (state) => {
        state.history.status = 'failed';
      })
      .addCase(getMoreHistoryItems.pending, (state) => {
        state.history.status = 'loading';
      })
      .addCase(getMoreHistoryItems.fulfilled, (state, action) => {
        state.history.status = 'succeeded'

        if (action.payload) {
          state.history.items = [...state.history.items, ...action.payload.Items];
        }

        if (action.payload?.LastEvaluatedKey) {
          state.history.lastEvaluatedKey = action.payload.LastEvaluatedKey;
        } else {
          state.history.moreItems = false;
        }
      })
      .addCase(getMoreHistoryItems.rejected, (state) => {
        state.history.status = 'failed';
      })
      .addCase(getRecordingCount.pending, (state) => {
        // TODO: Add loading state for getting the recording count
      })
      .addCase(getRecordingCount.fulfilled, (state, action) => {
        state.recordingCount = action.payload.count;
      })
      .addCase(getRecordingCount.rejected, (state) => {
        // TODO: Add failed state for getting the recording count
      });
  },
});

export const { trimHistoryData, setStaleHistory, resetUserHistoryState } = userHistorySlice.actions;
