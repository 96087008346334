import Axios, { AxiosRequestConfig } from 'axios';
import { Auth } from 'aws-amplify';

import env from '../../configs/env';

const authRequestInterceptor = async (config: AxiosRequestConfig) => {
  const token = (await Auth.currentSession()).getIdToken().getJwtToken();

  if (token && config && config.headers) {
    config.headers.Authorization = 'Bearer ' + token;
  }

  return config;
};

export const axios = Axios.create({
  baseURL: env.api.backendUrl,
  headers: {
    Authorization: null,
  },
});

axios.interceptors.request.use(authRequestInterceptor);
