import { useState, useEffect, useCallback } from 'react';

export const useCounter = (
  durationMs: number
): [() => void, () => void, string, boolean, number] => {
  const [id, setId] = useState<number | null>(null);
  const [isOn, setIsOn] = useState(false);
  const [timeElapsedSec, setTimeElapsedSec] = useState(0);
  const [counter, setCounter] = useState({
    mins: parseMins(0),
    secs: parseSecs(0),
  });

  useEffect(() => {
    return () => {
      if (id) {
        window.clearInterval(id);
      }
    };
  }, [id]);

  const resetCounter = useCallback(() => {
    setIsOn(false);
    setId(null);
    setCounter({
      mins: parseMins(0),
      secs: parseSecs(0),
    });
    setTimeElapsedSec(0);
  }, []);

  const clear = useCallback(
    (intervalId?: number) => {
      if (intervalId) {
        window.clearInterval(intervalId);
        resetCounter();
        return;
      }

      if (!isOn || !id) {
        return;
      }

      window.clearInterval(id);
      resetCounter();
    },
    [id, isOn, resetCounter]
  );

  const start = useCallback(() => {
    if (isOn || id !== null) {
      return;
    }

    if (!durationMs) {
      return;
    }

    try {
      let timer = 0;
      const currentId = window.setInterval(() => {
        timer += 1000;
        const mins = parseMins(timer);
        const secs = parseSecs(timer);
        setCounter({ mins, secs });
        setTimeElapsedSec(timer / 1000);
      }, 1000);

      setId(currentId);
      setIsOn(true);
    } catch (error) {}
  }, [durationMs, id, isOn]);

  function parseMins(timeInMS: number): string {
    return parseInt((timeInMS / 1000 / 60).toString(), 10).toString();
  }

  function parseSecs(timeInMS: number): string {
    return parseInt(((timeInMS / 1000) % 60).toString(), 10).toString();
  }

  const displayTime = `${counter.mins}:${counter.secs.padStart(2, '0')}`;

  return [start, clear, displayTime, isOn, timeElapsedSec];
};
