import { useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import PasswordChecklist from 'react-password-checklist';
import { useAuth } from '../context';
import { Modal, Button, InputField, FieldError } from '../../../components/Elements';
import { AuthHeading, AuthError, BackToLogin } from '../components';
import { PASSWORD_REGEX } from '../../../constants';

type FormValues = {
  code: string;
  password: string;
};

export const ConfirmPassword = () => {
  const navigate = useRef(useNavigate());
  const { state } = useLocation();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormValues>();
  const watchPassword = watch('password');

  const {
    submitNewPassword,
    resetSubmitPassword,
    submitPasswordState: { attemptingSubmit, submitError, submitErrorMessage, submitSuccess },
  } = useAuth();

  useEffect(() => {
    if (!state || !state.username) {
      navigate.current('/reset-password');
    }

    return () => {
      resetSubmitPassword();
    };
  }, [state, resetSubmitPassword]);

  useEffect(() => {
    if (!submitSuccess) return;

    const timerId = setTimeout(() => {
      navigate.current('/login');
    }, 3500);
    return () => {
      clearTimeout(timerId);
    };
  }, [submitSuccess]);

  const submitResetPasswordHandler: SubmitHandler<FormValues> = ({ code, password }) => {
    submitNewPassword(state.username, code, password);
  };

  return (
    <div className="relative min-h-screen bg-white">
      <BackToLogin />

      <div className="container mx-auto flex max-w-[334px] flex-col items-center px-3">
        <AuthHeading>Reset Password</AuthHeading>

        <form
          className="mt-11 w-full max-w-[334px]"
          onSubmit={handleSubmit(submitResetPasswordHandler)}
        >
          {submitError && <AuthError className="mb-4 ml-0" message={submitErrorMessage} />}

          <InputField
            label="Verification Code*"
            registration={register('code', {
              required: true,
            })}
          />
          {errors.code?.type === 'required' && (
            <FieldError message="Verification code is required" />
          )}

          <InputField
            className="mt-4"
            label="New Password*"
            registration={register('password', {
              required: true,
              pattern: PASSWORD_REGEX,
            })}
            type="password"
          />
          {errors.password?.type === 'required' && <FieldError message="Password is required" />}
          {errors.password?.type === 'pattern' && (
            <div role="alert" className="mt-2 ml-2 flex font-medium">
              <PasswordChecklist
                className="[&>li]:items-center"
                value={watchPassword}
                rules={['capital', 'lowercase', 'minLength', 'specialChar', 'number']}
                minLength={8}
                iconSize={14}
              />
            </div>
          )}

          <Button type="submit" className="mt-11 w-full" isLoading={attemptingSubmit}>
            Update Password
          </Button>
        </form>
      </div>

      <Modal isOpen={submitSuccess} onClose={() => {}}>
        <div className="px-10 text-center ">
          <h3 className="text-lg">Password Reset Successful! </h3>
          <p className="mt-4">Redirecting back to Login page...</p>
        </div>
      </Modal>
    </div>
  );
};
