import { axios } from '../configs';
import { GetUserPromptsResponse, UserPromptDto, PostUserPromptResponse } from './types';

export const UserPromptsAPI = {
  endpoint: '/user/prompts',
  getUserPrompts: async function () {
    try {
      const response = await axios.get(this.endpoint);
      const { userPrompts } = response.data as GetUserPromptsResponse;
      return userPrompts;
    } catch (error) {
      throw error;
    }
  },
  saveUserPrompt: async function (userPromptDto: UserPromptDto) {
    try {
      const response = await axios.post(this.endpoint, userPromptDto);
      const { userPrompt } = response.data as PostUserPromptResponse;
      return userPrompt;
    } catch (error) {
      throw error;
    }
  },
};
