import InfiniteScroll from 'react-infinite-scroll-component';
import { Spinner, Button } from '../../../components/Elements';
import { HistoryItem } from './HistoryItem';
import {
  useAppDispatch,
  useAppSelector,
  getMoreHistoryItems,
  getHistoryItems,
  trimHistoryData,
} from '../.././../redux';
import { useEffect } from 'react';
import { useTrackEvent } from '../../../hooks';

export const HistoryList = () => {
  const { trackEvent } = useTrackEvent();
  const dispatch = useAppDispatch();
  const { history } = useAppSelector((state) => state.userHistory);
  const { items, moreItems, stale } = history;

  // remove the paginated results except the first page from redux to prevent long page loads when leaving and
  // navigating back to this page
  useEffect(() => {
    return () => {
      dispatch(trimHistoryData());
    };
  }, [dispatch]);

  useEffect(() => {
    if (stale) {
      dispatch(getHistoryItems());
    }
  }, [dispatch, stale]);

  const backToTop = () => {
    setTimeout(() => {
      window.scrollTo({
        behavior: 'smooth',
        top: 0,
      });
    }, 100);
    trackEvent('interview_archive', 'archive_top');
  };

  const getMoreItems = () => {
    dispatch(getMoreHistoryItems());
  };

  return (
    <div className="my-12 w-full sm:max-w-[600px]">
      <InfiniteScroll
        dataLength={items.length}
        next={getMoreItems}
        hasMore={moreItems}
        loader={<Spinner size="lg" className="mx-auto mt-8" />}
        endMessage={
          <Button onClick={backToTop} variant="borderless" className="mx-auto my-6">
            Back To Top
          </Button>
        }
        // scrollableTarget="main-content"
      >
        <ul className="flex flex-col gap-6">
          {items.map((item) => (
            <HistoryItem key={item.filename} {...item} eventType="interview_archive" eventPrefix="archive" />
          ))}
        </ul>
      </InfiniteScroll>
    </div>
  );
};
