import { ReactComponent as Wheel } from '../../assets/progress-wheel/small-wheel/wheel.svg';
import { ReactComponent as Track } from '../../assets/progress-wheel/small-wheel/track.svg';
import { ReactComponent as Bar1 } from '../../assets/progress-wheel/small-wheel/bar-1.svg';
import { ReactComponent as Bar2 } from '../../assets/progress-wheel/small-wheel/bar-2.svg';
import { ReactComponent as Bar3 } from '../../assets/progress-wheel/small-wheel/bar-3.svg';
import { ReactComponent as Bar4 } from '../../assets/progress-wheel/small-wheel/bar-4.svg';
import { ReactComponent as Bar5 } from '../../assets/progress-wheel/small-wheel/bar-5.svg';

export type ProgressWheelSmallProps = {
  step: number;
  descriptions: string[];
  className?: string;
};

export const ProgressWheelSmall = ({
  step,
  descriptions,
  className = '',
}: ProgressWheelSmallProps) => {
  return (
    <div className={`absolute ${className} lg:hidden`}>
      <Wheel />
      <Track className="absolute top-[24px] left-[4px]" />
      <Bar1 className="absolute top-[24px] left-[8px] z-50" />
      <Bar2
        className={`absolute top-[56px] left-[81px] z-40 transition-all duration-200 ${
          step >= 1 ? 'scale-100' : 'scale-0'
        }`}
      />
      <Bar3
        className={`absolute top-[120px] left-[124px] z-30 transition-all duration-200 ${
          step >= 2 ? 'scale-100' : 'scale-0'
        }`}
      />
      <Bar4
        className={`absolute top-[207px] left-[79px] z-20 transition-all duration-200 ${
          step >= 3 ? 'scale-100' : 'scale-0'
        }`}
      />
      <Bar5
        className={`absolute top-[272px] left-[4px] z-10 transition-all duration-200 ${
          step >= 4 ? 'scale-100' : 'scale-0'
        }`}
      />

      <p className="absolute top-[150px] w-[127px] pl-1 text-sm text-white">{descriptions[step]}</p>
    </div>
  );
};
