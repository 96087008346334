import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

const variants = {
  red: 'text-red-600',
  yellow: 'text-yellow-300',
};

type FieldErrorProps = {
  message: string;
  variant?: keyof typeof variants;
};

export const FieldError = ({ message, variant = 'red' }: FieldErrorProps) => {
  return (
    <p role="alert" className={`mt-2 ml-2 flex items-center font-medium ${variants[variant]}`}>
      <ExclamationTriangleIcon className="mr-1 h-6 w-6" />
      <span>{message}</span>
    </p>
  );
};
