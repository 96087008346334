import { ChangeEvent, useState, useEffect } from 'react';
import { RecordingModal } from '../RecordingModal';
import { Button } from '../../../../components/Elements';

import styles from './styles.module.css';

type DurationModalProps = {
  timeLimit: number;
  showDuration: boolean;
  closeModalHandler: () => void;
  onUpdateDuration: (duration: number) => void;
};

export const DurationModal = ({
  showDuration,
  closeModalHandler,
  onUpdateDuration,
  timeLimit,
}: DurationModalProps) => {
  const [videoDuration, setVideoDuration] = useState(timeLimit);

  useEffect(() => {
    if (!showDuration) {
      setVideoDuration(timeLimit);
    }
  }, [showDuration, timeLimit]);

  const updateDurationHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setVideoDuration(+e.target.value);
  };

  const setDurationHandler = () => {
    onUpdateDuration(videoDuration);
    closeModalHandler();
  };

  const overlayPosition = ((videoDuration - 60000) / (120000 - 60000)) * 100;

  const parseMins = (timeInMS: number): string => {
    return parseInt((timeInMS / 1000 / 60).toString(), 10).toString();
  };

  const parseSecs = (timeInMS: number): string => {
    return parseInt(((timeInMS / 1000) % 60).toString(), 10).toString();
  };

  const displayTime = `${parseMins(videoDuration)}:${parseSecs(videoDuration).padStart(2, '0')}`;

  return (
    <RecordingModal isOpen={showDuration} onClose={closeModalHandler}>
      <div className="grid justify-items-center py-4 px-8">
        <h3 className="text-xl font-semibold tracking-wide text-white">Video Duration</h3>
        <p className="mt-4 text-neutral-200/80">Set the length of your recording</p>

        <div className="relative mt-16 w-full">
          {/* Overlay fill */}
          <div className="pointer-events-none absolute inset-0 z-20 overflow-hidden rounded-lg">
            <div
              className="absolute -left-full h-full w-full rounded-tl-lg rounded-bl-lg border-3 border-r-0 border-primary bg-primary/50"
              style={{ transform: `translateX(${overlayPosition}%)` }}
            />
          </div>

          {/* Video Duration */}
          <div
            className="absolute -top-7 z-40 grid min-w-max -translate-x-1/2 place-content-center text-white"
            style={{ left: `${overlayPosition}%` }}
          >
            <span>{displayTime}</span>
          </div>

          {/* Min Max display and Slider Border */}
          <div className="align-items-center relative grid h-12 w-full rounded-lg border-3">
            <span
              className={`absolute -left-2 -top-7 text-sm text-neutral-200/80 transition-opacity duration-200 ${
                overlayPosition < 15 ? 'opacity-0' : 'opacity-100'
              }`}
            >
              1min
            </span>
            <span
              className={`absolute -right-2 -top-7 text-sm text-neutral-200/80 transition-opacity duration-200 ${
                overlayPosition > 85 ? 'opacity-0' : 'opacity-100'
              }`}
            >
              2min
            </span>
          </div>

          {/* Slider Background */}
          <div className="absolute inset-0 flex items-center justify-evenly px-3">
            <div className="aspect-square w-2 rotate-45 bg-white" />
            <div className="aspect-square w-3 rotate-45 bg-white" />
            <div className="aspect-square w-2 rotate-45 bg-white" />
            <div className="aspect-square w-3 rotate-45 bg-white" />
            <div className="aspect-square w-2 rotate-45 bg-white" />
            <div className="aspect-square w-3 rotate-45 bg-white" />
            <div className="aspect-square w-2 rotate-45 bg-white" />
          </div>

          {/* Range Input */}
          <input
            className={`absolute inset-0 ${styles.slider}`}
            type="range"
            min={60000}
            max={120000}
            step={1000}
            value={videoDuration}
            onChange={updateDurationHandler}
          />
        </div>

        <Button size="md" className="mt-8" onClick={setDurationHandler}>
          Set Duration
        </Button>
        <Button variant="borderless" className="mt-2 text-white" onClick={closeModalHandler}>
          Cancel
        </Button>
      </div>
    </RecordingModal>
  );
};
