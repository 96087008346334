import { ReactNode } from 'react';

type AuthHeadingProps = {
  children: ReactNode;
};
export const AuthHeading = ({ children }: AuthHeadingProps) => {
  return (
    <h1 className="mt-12 text-center font-open-sans text-2xl font-bold text-navy-400">
      {children}
    </h1>
  );
};
