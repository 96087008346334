import { useRef, useState, useEffect, useReducer } from 'react';
import Countdown from '../components/VideoRecorder/defaults/countdown';
import { VideoActionsProps } from '../types';
import { useAppDispatch, useAppSelector, saveUserPrompt } from '../../../redux';
import {
  RecordingModal,
  PromptSelect,
  SideActionsBar,
  HiddenOverlay,
  TipsOverlay,
  FaceOverlay,
  BottomActionsBar,
  DurationModal,
  CountdownModal,
  ExitModal,
} from '../components';
import { useCounter } from '../hooks';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { useTrackEvent } from '../../../hooks';

const initialModalState = {
  showPrompts: false,
  showDuration: false,
  showTimer: false,
  showExit: false,
};

type ModalState = typeof initialModalState;

type ModalActions =
  | { type: 'ShowPrompts' }
  | { type: 'ShowDuration' }
  | { type: 'ShowTimer' }
  | { type: 'HideModal' }
  | { type: 'ShowExit' };

const modalReducer = (state: ModalState = initialModalState, action: ModalActions): ModalState => {
  switch (action.type) {
    case 'ShowPrompts':
      return { showDuration: false, showPrompts: true, showTimer: false, showExit: false };
    case 'ShowDuration':
      return { showDuration: true, showPrompts: false, showTimer: false, showExit: false };
    case 'ShowTimer':
      return { showDuration: false, showPrompts: false, showTimer: true, showExit: false };
    case 'HideModal': {
      return { showDuration: false, showPrompts: false, showTimer: false, showExit: false };
    }
    case 'ShowExit':
      return { showDuration: false, showPrompts: false, showTimer: false, showExit: true };
    default:
      return state;
  }
};

type ActionsProps = VideoActionsProps & {
  onSubmitVideo: (duration: number) => Promise<void>;
  onUpdateTimeLimit: (duration: number) => void;
  onUpdateCountdown: (timer: number) => void;
};

export const VideoActions = ({
  isRecording,
  countdownTime,
  timeLimit,
  onStartRecording,
  onStopRecording,
  onStopReplaying,
  streamIsReady,
  isCameraOn,
  // isConnecting,
  isReplayingVideo,
  isRunningCountdown,
  onSubmitVideo,
  onUpdateCountdown,
  onUpdateTimeLimit,
  thereWasAnError,
  onSwitchCamera,
  availableDeviceIds,
}: ActionsProps) => {
  const selectedPrompt = useAppSelector((state) => state.prompts.selectedPrompt);
  const recentPrompts = useAppSelector((state) => state.prompts.userPrompts.recentPrompts);

  const dispatch = useAppDispatch();
  const [modalState, modalDispatch] = useReducer(modalReducer, initialModalState);

  const [promptHeight, setPromptHeight] = useState(0);
  const [showTips, setShowTips] = useState(true);
  const [showFaceOverlay, setShowFaceOverlay] = useState(true);
  const [hideDisplay, setHideDisplay] = useState(false);
  const [videoDuration, setVideoDuration] = useState(0);

  const promptRef = useRef<HTMLDivElement>(null);
  const [start, clear, counter, isOn, timeElapsedSec] = useCounter(timeLimit);
  const { trackEvent } = useTrackEvent();

  const { showDuration, showPrompts, showTimer, showExit } = modalState;

  useEffect(() => {
    setPromptHeight(promptRef.current?.clientHeight || 0);
  }, [selectedPrompt]);

  useEffect(() => {
    if (isRecording && !isRunningCountdown && !isOn) {
      setShowFaceOverlay(false);
      setShowTips(false);
      start();
    }

    if (!isRecording && isOn) {
      setVideoDuration(timeElapsedSec);
      clear();
    }
  }, [isRecording, isRunningCountdown, timeLimit, start, clear, isOn, timeElapsedSec]);

  // Video Action Handlers
  const startHandler = () => {
    if (!selectedPrompt) {
      modalDispatch({ type: 'ShowPrompts' });
      return;
    }

    const noPrompt = Object.keys(selectedPrompt).length === 0;

    if (noPrompt) {
      modalDispatch({ type: 'ShowPrompts' });
      return;
    }

    // If the selected prompt was generated by gpt, check if the user has practiced it in the past
    if (!selectedPrompt.id) {
      const practicedPrompt = recentPrompts.find(
        (recentPrompt) => recentPrompt.prompt === selectedPrompt.prompt
      );

      if (practicedPrompt) {
        dispatch(saveUserPrompt(practicedPrompt));
      } else {
        dispatch(saveUserPrompt(selectedPrompt));
      }
    } else {
      dispatch(saveUserPrompt(selectedPrompt));
    }

    onStartRecording();
    trackEvent('interview_practice', 'record_start', selectedPrompt);
  };

  const stopHandler = () => {
    onStopRecording();
    trackEvent('interview_practice', 'record_stop', {
      ...selectedPrompt,
      duration: timeElapsedSec,
    });
  };

  const resetHandler = () => {
    if (isRecording) {
      stopHandler();
    }

    onStopReplaying();
    trackEvent('interview_practice', 'record_again', selectedPrompt);
  };

  // Toggle Modals
  const showPromptsHandler = () => {
    modalDispatch({ type: 'ShowPrompts' });
    trackEvent('interview_practice', 'more_prompts');
  };
  const showDurationHandler = () => {
    modalDispatch({ type: 'ShowDuration' });
    trackEvent('interview_practice', 'show_duration');
  };

  const showTimerHandler = () => {
    modalDispatch({ type: 'ShowTimer' });
    trackEvent('interview_practice', 'show_timer');
  };

  const closeModalHandler = () => {
    modalDispatch({ type: 'HideModal' });
  };

  const showExitHandler = () => {
    modalDispatch({ type: 'ShowExit' });
    trackEvent('interview_practice', 'interview_exit');
  };

  // Toggle Overlays
  const toggleTips = () => {
    showTips
      ? trackEvent('interview_practice', 'hide_record_tips')
      : trackEvent('interview_practice', 'show_record_tips');

    setShowTips((prev) => !prev);
  };

  const toggleFaceOverlay = () => {
    showFaceOverlay
      ? trackEvent('interview_practice', 'hide_head_framing')
      : trackEvent('interview_practice', 'show_head_framing');

    setShowFaceOverlay((prev) => !prev);
  };

  const toggleHideDisplay = () => {
    hideDisplay
      ? trackEvent('interview_practice', 'toggle_hide_user', { self_view: true })
      : trackEvent('interview_practice', 'toggle_hide_user', { self_view: false });

    setHideDisplay((prev) => !prev);
  };

  // Modal Action Handlers
  const timeLimitHandler = (videoDuration: number) => {
    onUpdateTimeLimit(videoDuration);
  };

  const updateCountdownHandler = (newCountdown: number) => {
    onUpdateCountdown(newCountdown);
  };

  return (
    <>
      {/* Prompt display */}
      <div
        ref={promptRef}
        className="pointer-events-none absolute left-0 right-0 top-0 z-30 grid place-items-center bg-blue-gradient/90 p-3 text-center leading-snug text-white lg:-translate-y-full lg:rounded-tl-3xl lg:rounded-tr-3xl"
      >
        {selectedPrompt?.prompt ? (
          <p className="">{selectedPrompt?.prompt}</p>
        ) : (
          <button
            onClick={showPromptsHandler}
            className="pointer-events-auto rounded-md border border-white py-2 px-4 text-sm uppercase tracking-wide"
          >
            Select a Prompt
          </button>
        )}
      </div>

      {streamIsReady && !isRunningCountdown && !isRecording && !isReplayingVideo && (
        <SideActionsBar
          promptHeight={promptHeight}
          onShowPrompts={showPromptsHandler}
          onShowDuration={showDurationHandler}
          onShowTimer={showTimerHandler}
          countdownTime={countdownTime}
          availableDeviceIds={availableDeviceIds}
          onSwitchCamera={onSwitchCamera}
          onShowExit={showExitHandler}
        />
      )}

      <RecordingModal isOpen={showPrompts} onClose={closeModalHandler}>
        <PromptSelect onClose={closeModalHandler} />
      </RecordingModal>

      <DurationModal
        showDuration={showDuration}
        closeModalHandler={closeModalHandler}
        onUpdateDuration={timeLimitHandler}
        timeLimit={timeLimit}
      />

      <CountdownModal
        isOpen={showTimer}
        onClose={closeModalHandler}
        onUpdateCountdown={updateCountdownHandler}
        countdownTime={countdownTime}
      />

      <ExitModal isOpen={showExit} onClose={closeModalHandler} />

      {/* Countdown Timer */}
      <div className="absolute top-1/2 z-30">
        {isRunningCountdown && <Countdown countdownTime={countdownTime} />}
      </div>

      {!isReplayingVideo && <HiddenOverlay hideDisplay={hideDisplay} />}

      {!isReplayingVideo && <TipsOverlay showTips={showTips} />}

      {!isReplayingVideo && <FaceOverlay showFaceOverlay={showFaceOverlay} />}

      {!isReplayingVideo && (
        <BottomActionsBar
          countdown={counter}
          isCameraOn={isCameraOn}
          isRecording={isRecording}
          isRunningCountdown={isRunningCountdown}
          hideDisplay={hideDisplay}
          onReset={resetHandler}
          onStart={startHandler}
          onStop={stopHandler}
          showFaceOverlay={showFaceOverlay}
          showTips={showTips}
          streamIsReady={streamIsReady}
          timeLimit={timeLimit}
          timeElapsedSec={timeElapsedSec}
          toggleFaceOverlay={toggleFaceOverlay}
          toggleHideDisplay={toggleHideDisplay}
          toggleTips={toggleTips}
        />
      )}

      {isReplayingVideo && (
        <div className="pointer-events-none absolute bottom-0 z-50 mb-6 grid w-full place-items-center gap-4 py-3">
          {videoDuration < (process.env.REACT_APP_MIN_VIDEO_DURATION || 30) && (
            <div className="flex">
              <ExclamationTriangleIcon className="mr-2 w-8 text-yellow-500" />
              <p className="max-w-[250px] leading-tight">
                We can only give feedback if you speak for at least{' '}
                {process.env.REACT_APP_MIN_VIDEO_DURATION || 30} seconds.
              </p>
            </div>
          )}
          <div className="flex items-center gap-4">
            <button
              className="pointer-events-auto w-[169px] rounded-3xl bg-white py-3 text-navy-500"
              onClick={resetHandler}
            >
              Try Again
            </button>
            <button
              onClick={() => onSubmitVideo(videoDuration)}
              className="pointer-events-auto w-[169px] rounded-3xl bg-primary py-3 text-navy-500 disabled:bg-slate-200 disabled:opacity-70"
              disabled={videoDuration < (process.env.REACT_APP_MIN_VIDEO_DURATION || 30)}
            >
              Submit
            </button>
          </div>
        </div>
      )}
    </>
  );
};
