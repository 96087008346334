import { Auth } from "aws-amplify";
import env from "../configs/env";

export const getBearerToken = async () =>
  (await Auth.currentSession()).getIdToken().getJwtToken();

export const getAuthorizationHeaders = async () => {
  // Get authorization json web token (jwt)
  let bearerToken = await getBearerToken();
  return {
    Authorization: bearerToken, // Needed for cognito authorizer
  };
};

const fetchAuth = async (
  input: string | RequestInfo,
  init: RequestInit = { method: "GET" }
) => {
  let { Authorization } = await getAuthorizationHeaders();

  // Set default headers
  let defaultHeaders = {
    Authorization,
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  // Merge default headers with headers passed in by user
  init.headers = { ...defaultHeaders, ...init?.headers };

  // Ensure base url does not have a trailing slash
  let lastSlashPattern = /(\/$)/g;
  let baseUrl = env.api.backendUrl.replace(lastSlashPattern, "");

  // Absolute/Relative Path Check
  let absoluteUrlPattern = new RegExp(/:\/\/|\/\//g);
  if (!absoluteUrlPattern.test(input.toString())) {
    let firstSlashPattern = /(^\/+\w{0})/g;
    let realativeUrl = input.toString().replace(firstSlashPattern, "");
    input = [baseUrl, realativeUrl].join("/");
  }

  // Return preformated fetch for authenticated api call
  return fetch(input, init);
};

export default fetchAuth;
