import { Button } from '../Elements';
import { ReactComponent as LeftArrow } from '../../assets/icons/left-arrow.svg';
import { ReactComponent as RightArrow } from '../../assets/icons/right-arrow.svg';

export type ProgressBarProps = {
  step: number;
  numberOfSteps: number;
  previousStep: () => void;
  nextStep: () => void;
  className?: string;
};

export const ProgressBar = ({
  step,
  numberOfSteps,
  nextStep,
  previousStep,
  className = '',
}: ProgressBarProps) => {
  const bars = [];

  for (let i = 0; i < numberOfSteps; i++) {
    bars.push(
      <div
        key={`bar-${i}`}
        className={`h-[6px] rounded-[4px] transition-all duration-200  ${
          step === i ? 'bg-navy-500' : 'bg-neutral-300'
        }`}
      />
    );
  }

  return (
    <div className={`flex w-full items-center gap-3 ${className}`}>
      <Button
        className={`${step === 0 && 'invisible'}`}
        variant="progressBack"
        size="progress"
        radius="round"
        onClick={previousStep}
      >
        <LeftArrow style={{ marginRight: '3px' }} />
      </Button>

      <div
        className={`grid flex-1 gap-3`}
        style={{ gridTemplateColumns: `repeat(${numberOfSteps}, minmax(0,1fr))` }}
      >
        {bars}
      </div>

      <Button
        className={`${step === numberOfSteps - 1 && 'invisible'}`}
        variant="progressForward"
        size="progress"
        radius="round"
        onClick={nextStep}
      >
        <RightArrow style={{ marginLeft: '3px' }} />
      </Button>
    </div>
  );
};
