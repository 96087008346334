import { useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import localForage from 'localforage';
import {
  startFeedbackTimer,
  stopFeedbackTimer,
  useAppDispatch,
  useAppSelector,
  uploadToMMAI,
  connectWebsocket,
} from '../../../redux';
import { Outlet } from 'react-router-dom';
import { Button, Container } from '../../../components/Elements';
import { SectionHeader } from '../../../components/SectionHeader';
// import { ProgressWheelLarge } from '../../../components/ProgressWheel';
import { Modal } from '../../../components/Elements';
import { useTrackEvent } from '../../../hooks';
import { UserPrompt } from '../../../apis/UserPromptsAPI';

export const Reflection = () => {
  const [feedbackError, setFeedbackError] = useState(false);
  const [noUploadData, setNoUploadData] = useState(false);
  const [retryAttempts, setRetryAttempts] = useState(0);

  const navigate = useRef(useNavigate());
  const {
    websocket,
    feedbackTimer: { status: timerStatus },
    videoUpload: { status: uploadStatus, lastRecording },
  } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const { trackEvent } = useTrackEvent();

  // Establish a websocket connect and reopen if it disconnects
  useEffect(() => {
    if (!websocket.connected) {
      dispatch(connectWebsocket());
    }
  }, [websocket, dispatch]);

  // Route back to Video Recorder if no video has been recorded
  useEffect(() => {
    if (uploadStatus === 'idle') {
      navigate.current('/record');
    }
  }, [uploadStatus]);

  // Start the video processing timer when video successfully uploads to MMAI
  useEffect(() => {
    if (uploadStatus === 'succeeded' && lastRecording && timerStatus === 'idle') {
      dispatch(startFeedbackTimer(lastRecording.processingTime));
    }
  }, [uploadStatus, lastRecording, dispatch, timerStatus]);

  // Turn off the timer if the user navigates away from the page during processing
  useEffect(() => {
    return () => {
      dispatch(stopFeedbackTimer());
    };
  }, [dispatch]);

  // If the feedback from MMAI contains an error show a Failed to Process Modal
  useEffect(() => {
    if (
      websocket.messageData &&
      (websocket.messageData.status === 'Error' || websocket.messageData.status === 'Retry')
    ) {
      dispatch(stopFeedbackTimer());
      setFeedbackError(true);
      trackEvent('interview_practice', 'video_processing_error');
    }

    // Stop the timer if the feedback is successful
    if (
      websocket.messageData &&
      (websocket.messageData.status === 'Accepted' || websocket.messageData?.status === 'Done')
    ) {
      dispatch(stopFeedbackTimer());
    }
  }, [websocket, dispatch, trackEvent]);

  const backToRecorderHandler = () => {
    navigate.current('/record');
    trackEvent('interview_practice', 'back_to_interview');
  };

  const retrySubmissionHandler = async () => {
    try {
      const uploadData = await localForage.getItem<{
        file: File;
        selectedPrompt: UserPrompt;
        duration: number;
      }>('uploadData');

      if (!uploadData) throw new Error('No upload data found');

      dispatch(uploadToMMAI(uploadData));
      setRetryAttempts((prev) => prev + 1);
    } catch (error) {
      setNoUploadData(true);
    }
  };

  return (
    <div className="flex h-full flex-col overflow-x-hidden">
      {/* Show a Modal if there was an error during video upload */}
      <Modal isOpen={uploadStatus === 'failed'} onClose={() => {}}>
        <div className="grid justify-items-center text-center">
          <h3 className="text-lg font-bold">Sorry your video failed to upload.</h3>

          {retryAttempts >= (process.env.REACT_APP_MAX_RETRY_SUBMISSION_ATTEMPTS || 5) && (
            <p className="my-4">Maximum retry attempts exceeded.</p>
          )}

          {noUploadData && <p className="my-4">No video data found</p>}

          {retryAttempts < (process.env.REACT_APP_MAX_RETRY_SUBMISSION_ATTEMPTS || 5) &&
            !noUploadData && (
              <Button onClick={retrySubmissionHandler} className="mt-8 w-full max-w-[320px]">
                Retry Upload
              </Button>
            )}

          <Button
            variant="outline"
            onClick={backToRecorderHandler}
            className="mt-2 w-full max-w-[320px]"
          >
            Back To Mock Interview
          </Button>
        </div>
      </Modal>

      {/* Show a Modal if there was an error during video processing */}
      <Modal isOpen={feedbackError} onClose={() => {}}>
        <div className="grid justify-items-center text-center">
          <h3 className="text-lg font-bold">Sorry your video could not be processed.</h3>
          <p className="mt-4">Please try recording your interview again.</p>
          <Button onClick={backToRecorderHandler} className="mt-8 w-full max-w-[320px]">
            Back To Mock Interview
          </Button>
        </div>
      </Modal>

      <Container className="grid justify-items-center">
        <SectionHeader step={3} heading="Rate Your Performance" />
      </Container>

      {/* <ProgressWheelLarge
        className="fixed left-0 top-1/2 -translate-y-1/2"
        step={2}
        message="Rate Your Performance"
      /> */}

      <div className="flex-1">
        <Outlet />
      </div>
    </div>
  );
};
