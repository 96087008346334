import {
  createContext,
  useContext,
  ReactNode,
  useEffect,
  useState,
  useRef,
  useReducer,
  useCallback,
} from 'react';

import { toast } from 'react-toastify';

import {
  useAppDispatch,
  resetUserState,
  resetVideoUploadState,
  resetUserHistoryState,
  disconnectedWebsocket,
  getUser,
  getHistoryItems,
  getRecordingCount,
  getUserPrompts
} from '../../../redux';
import { trackEventAsync, signInFailureEventAsync } from '../../../services/EventService';

import { useNavigate } from 'react-router-dom';
import { Auth, Hub } from 'aws-amplify';

import {
  initialForgotPasswordState,
  initialLoginState,
  initialSignupState,
  initialSubmitPasswordState,
  initialVerifySignupState,
} from './initial-states';

import {
  forgotPasswordReducer,
  loginReducer,
  signupReducer,
  submitPasswordReducer,
  verifySignupReducer,
} from './reducers';

import { AuthState } from '../types';
import { initPendo } from '../../../configs/pendo';

const AuthContext = createContext({} as AuthState);

type AuthProviderProps = {
  children?: ReactNode;
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [checkingCredentials, setCheckingCredentials] = useState(true);
  const navigate = useRef(useNavigate());
  const dispatch = useAppDispatch();

  const [loginState, loginDispatch] = useReducer(loginReducer, initialLoginState);
  const [signupState, signupDispatch] = useReducer(signupReducer, initialSignupState);
  const [verifySignupState, verifySignupDispatch] = useReducer(
    verifySignupReducer,
    initialVerifySignupState
  );
  const [forgotPasswordState, forgotPasswordDispatch] = useReducer(
    forgotPasswordReducer,
    initialForgotPasswordState
  );
  const [submitPasswordState, submitPasswordDispatch] = useReducer(
    submitPasswordReducer,
    initialSubmitPasswordState
  );

  useEffect(() => {
    const checkForUserCredentials = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();

        if (user) {
          loginDispatch({ type: 'loginSuccess' });
          dispatch(getUser());
          dispatch(getUserPrompts());
          dispatch(getHistoryItems());
          dispatch(getRecordingCount());
          initPendo(user.attributes.sub, user.attributes.email);
        }
      } catch (error) {
        console.error(error);
      }

      setCheckingCredentials(false);
    };

    Hub.listen('auth', ({ payload }) => {
      const { event, data, message } = payload;
      // console.log(payload);

      if (event === 'signIn') {
        loginDispatch({ type: 'loginSuccess' });

        dispatch(getUser());
        // dispatch(getPrompts());
        dispatch(getHistoryItems());
        dispatch(getRecordingCount());
        initPendo(data.attributes.sub, data.attributes.email);

        trackEventAsync({
          eventType: 'ccs_global',
          eventName: 'user_login',
          userId: data.attributes.sub,
          username: data.username,
          eventData: {
            page: 'login',
          },
        });

        navigate.current('/auth-redirect');
      }

      if (event === 'signOut') {
        loginDispatch({ type: 'logout' });
        dispatch(resetUserState());
        dispatch(resetUserHistoryState());
        dispatch(resetVideoUploadState());
        dispatch(disconnectedWebsocket());

        trackEventAsync({
          eventType: 'ccs_global',
          eventName: 'user_logout',
          userId: data.attributes.sub,
          username: data.username,
        });

        navigate.current('/login');
      }

      if (event === 'signUp') {
        signupDispatch({ type: 'signupSuccess' });

        navigate.current('/signup/verify', {
          state: {
            email: data.codeDeliveryDetails.Destination,
            username: data.user.getUsername(),
          },
        });
      }

      if (event === 'confirmSignUp') {
        navigate.current('/login');
        toast.success(message);
      }

      if (event === 'signIn_failure') {
        loginDispatch({ type: 'loginFail', payload: data?.message });

        console.log(payload);
        console.log()

        signInFailureEventAsync({
          eventType: 'ccs_global',
          eventName: 'user_login_failed',
          userId: '000000000000',
          username: '000000000000', // TODO: Pull username from login
          eventData: {
            page: 'login',
          },
        });
      }

      if (event === 'signUp_failure') {
        signupDispatch({ type: 'signupFail', payload: data?.message });
      }

      if (event === 'autoSignIn') {
        loginDispatch({ type: 'loginReset' });
        console.log('AUTO SIGNIN');
        // placeholder for event tracking
      }

      if (event === 'autoSignIn_failure') {
        navigate.current('/login');
      }

      if (event === 'forgotPassword') {
        forgotPasswordDispatch({ type: 'forgotPasswordSuccess' });
        navigate.current('/reset-password/confirm', {
          state: {
            username: data.username,
          },
        });
      }

      if (event === 'forgotPassword_failure') {
        forgotPasswordDispatch({ type: 'forgotPasswordFail', payload: data?.message });
      }

      if (event === 'forgotPasswordSubmit') {
        submitPasswordDispatch({ type: 'submitPasswordSuccess' });
      }

      if (event === 'forgotPasswordSubmit_failure') {
        submitPasswordDispatch({ type: 'submitPasswordFail', payload: data?.message });
      }
    });

    checkForUserCredentials();
  }, [dispatch]);

  const signIn = async (username: string, password: string) => {
    loginDispatch({ type: 'loginAttempt' });
    try {
      await Auth.signIn(username, password);
    } catch (error) {
      console.error(error);
    }
  };

  const signOut = async () => {
    try {
      await Auth.signOut();
    } catch (error) {
      console.error(error);
    }
  };

  const signUp = async (username: string, email: string, password: string) => {
    signupDispatch({ type: 'signupAttempt' });
    try {
      await Auth.signUp({
        username: username,
        password: password,
        attributes: {
          email,
        },
        autoSignIn: {
          enabled: false,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const confirmSignUp = async (username: string, code: string) => {
    verifySignupDispatch({ type: 'verifySignupAttempt' });

    try {
      await Auth.confirmSignUp(username, code);
      verifySignupDispatch({ type: 'verifySignupSuccess' });
    } catch (error) {
      verifySignupDispatch({
        type: 'verifySignupFail',
        payload: 'Invalid verification code provided, please try again.',
      });
    }
  };

  const sendForgotPasswordCode = async (username: string) => {
    forgotPasswordDispatch({ type: 'forgotPasswordAttempt' });

    try {
      Auth.forgotPassword(username);
    } catch (error) {}
  };

  const submitNewPassword = async (username: string, code: string, password: string) => {
    submitPasswordDispatch({ type: 'submitPasswordAttempt' });

    try {
      Auth.forgotPasswordSubmit(username, code, password);
    } catch (error) {
      console.error(error);
    }
  };

  const resetVerifySignUp = useCallback(() => {
    verifySignupDispatch({ type: 'verifySignupReset' });
  }, []);

  const resetSignIn = useCallback(() => {
    loginDispatch({ type: 'loginReset' });
  }, []);

  const resetSignUp = useCallback(() => {
    signupDispatch({ type: 'signupReset' });
  }, []);

  const resetForgotPassword = useCallback(() => {
    forgotPasswordDispatch({ type: 'forgotPasswordReset' });
  }, []);

  const resetSubmitPassword = useCallback(() => {
    submitPasswordDispatch({ type: 'submitPasswordReset' });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        checkingCredentials,
        signIn,
        signOut,
        resetSignIn,
        signUp,
        resetSignUp,
        loginState,
        signupState,
        verifySignupState,
        confirmSignUp,
        resetVerifySignUp,
        forgotPasswordState,
        resetForgotPassword,
        sendForgotPasswordCode,
        submitPasswordState,
        resetSubmitPassword,
        submitNewPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
