export const initialLoginState = {
  isLoggedIn: false,
  attemptingLogin: false,
  loginErrorMessage: '',
  loginError: false,
};

export const initialSignupState = {
  attemptingSignup: false,
  signupErrorMessage: '',
  signupError: false,
};

export const initialVerifySignupState = {
  attemptingVerify: false,
  verifyError: false,
  verifyErrorMessage: '',
};

export const initialForgotPasswordState = {
  searchingUser: false,
  forgotPasswordError: false,
  errorMessage: '',
};

export const initialSubmitPasswordState = {
  attemptingSubmit: false,
  submitError: false,
  submitErrorMessage: '',
  submitSuccess: false,
};
