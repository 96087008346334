import { UserPrompt } from './../../apis/UserPromptsAPI/types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { uploadVideo } from '../../utils/mmai';
import { determineProcessingTime } from '../../utils/misc';

type VideoUploadState = {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  lastRecording: {
    fileKey: string;
    fileName: string;
    duration: number;
    processingTime: number;
  } | null;
};

type UploadData = {
  file: File;
  selectedPrompt: UserPrompt;
  duration: number;
};

const initialState: VideoUploadState = {
  status: 'idle',
  lastRecording: null,
};

export const uploadToMMAI = createAsyncThunk(
  'videoUpload/uploadToMMAI',
  async ({ file, duration, selectedPrompt }: UploadData) => {
    const response = await uploadVideo(file, selectedPrompt, duration);

    if (!response?.fileKey) throw new Error('Failed to upload video for processing');

    return {
      fileName: file.name,
      fileKey: response.fileKey,
      duration,
      processingTime: determineProcessingTime(duration),
    };
  }
);

export const videoUploadSlice = createSlice({
  name: 'videoUpload',
  initialState,
  reducers: {
    resetVideoUploadState(state) {
      state.status = 'idle';
      state.lastRecording = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadToMMAI.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(uploadToMMAI.fulfilled, (state, action) => {
        const { duration, fileKey, fileName, processingTime } = action.payload;
        state.status = 'succeeded';
        state.lastRecording = {
          fileKey: fileKey as string,
          duration,
          fileName,
          processingTime,
        };
      })
      .addCase(uploadToMMAI.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { resetVideoUploadState } = videoUploadSlice.actions;
