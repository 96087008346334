import { EyeSlashIcon } from '@heroicons/react/24/outline';

type HiddenOverlayProps = {
  hideDisplay: boolean;
};

export const HiddenOverlay = ({ hideDisplay }: HiddenOverlayProps) => {
  return (
    <div
      className={`pointer-events-none absolute inset-0 z-20 grid place-content-center bg-black px-12 text-center transition-opacity duration-200 ${
        hideDisplay ? 'opacity-100' : 'opacity-0'
      }`}
    >
      <EyeSlashIcon className="mx-auto w-11" />
      <p className="mt-4">
        Your self view is hidden, but your camera is still capturing video to enable review and
        feedback.
      </p>
    </div>
  );
};
