import fetchAuth from '../../utils/fetchAuth';
import { fetchSignInFailure } from '../../utils/fetchSignInFailure';
import * as PLAAService from '../PLAAService';
import { UserEvent, Event } from './types';
import pkg from '../../../package.json';
import { v4 as uuidv4 } from 'uuid';

/**
 * use fetchAuth
 * track a single event
 * send a json object to and endpoint
 * track object in database
 */
export const trackEventAsync = async (userEvent: UserEvent) => {
  const { eventType, eventName, eventData, userId, username } = userEvent;
  let eventId = uuidv4();

  const event: Event = {
    event_id: eventId,
    event_type: eventType,
    event_name: eventName,
    event_version: pkg.event_version,
    event_timestamp: Date.now(),
    app_version: pkg.version,
    event_data: {
      source: `CCS - ${pkg.name}`,
      ...eventData,
    },
    session: eventId,
    user_id: userId || '',
    user_name: username || '',
    env: process.env.REACT_APP_ENV || pkg.env,
    user_type: pkg.user_type,
    schema_version: pkg.schema_version,
  };

  try {
    await fetchAuth('/event', {
      method: 'POST',
      body: JSON.stringify(event),
    });

    await PLAAService.trackEventAsync(event);
  } catch (err) {
    console.warn('Unable to track event.');
    console.log(err);
  }
};

export const signInFailureEventAsync = async (userEvent: UserEvent) => {
  const {
    eventType,
    eventName,
    eventData,
    // userId,
    // username
  } = userEvent;

  let eventId = uuidv4();

  const event: Event = {
    event_id: eventId,
    event_type: eventType,
    event_name: eventName,
    event_version: pkg.event_version,
    event_timestamp: Date.now(),
    app_version: pkg.version,
    event_data: {
      source: `CCS - ${pkg.name}`,
      ...eventData,
    },
    session: eventId,
    user_id: eventId,
    user_name: eventId,
    env: pkg.env,
    user_type: pkg.user_type,
    schema_version: pkg.schema_version,
  };

  try {
    await fetchSignInFailure('/unauth_event', {
      method: 'POST',
      body: JSON.stringify(event),
    });

    await PLAAService.trackEventAsync(event);
  } catch (err) {
    console.warn('Unable to track event.');
    console.log(err);
  }
};
