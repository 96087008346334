import { Link } from 'react-router-dom';

export const BackToLogin = () => {
  return (
    <Link
      className="absolute left-4 top-4 flex items-center text-sm text-neutral-700 hover:underline"
      to="/login"
    >
      <span>Back to Login</span>
    </Link>
  );
};
