import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { SEO } from '../../../components';

import { useAppSelector } from '../../../redux';
import { useTrackEvent } from '../../../hooks';

// import { ProgressWheelLarge } from '../../../components/ProgressWheel';
import { SectionHeader } from '../../../components/SectionHeader';
import { Button, Container, Divider } from '../../../components/Elements';
import fetchAuth from '../../../utils/fetchAuth';

const improvementAreas = [
  'Vocal delivery',
  'Filler words',
  'Pauses',
  'Eye contact',
  'Content',
  'Presentation',
  'Body language',
  'Head posture',
  'Organization',
  'Language',
];

export const FeedbackSurvey = () => {
  const [selectedArea, setSelectedArea] = useState('');
  const [reasonText, setReasonText] = useState('');
  const [actionText, setActionText] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { trackEvent } = useTrackEvent();
  const {
    user,
    prompts: { selectedPrompt },
    videoUpload: { lastRecording },
  } = useAppSelector((state) => state);
  const navigate = useNavigate();

  useEffect(() => {
    trackEvent('interview_practice', 'reflection_start');
  }, [trackEvent]);

  const submitHandler = async () => {
    try {
      setIsSubmitting(true);
      const reflectionData = {
        filename: lastRecording?.fileName,
        userId: user.cognitoId,
        timestamp: Date.now(),
        aspect: selectedArea,
        explanation: reasonText,
        improveAction: actionText,
      };

      await fetchAuth('/reflection', {
        method: 'POST',
        body: JSON.stringify({ reflectionData }),
      });

      trackEvent('interview_practice', 'reflection_submit', {
        explanation: reasonText,
        action: actionText,
        prompt: selectedPrompt,
        filename: lastRecording?.fileName,
      });

      setIsSubmitting(false);
      navigate('/feedback/end');
    } catch (error) {
      setIsSubmitting(false);
      // TODO: Error handling if reflection data fails to save
    }
  };

  const selectAreaHandler = (area: string) => {
    setSelectedArea(area);
    trackEvent('interview_practice', 'reflection_aspect_select', {
      aspect: area,
    });
  };

  return (
    <>
      <SEO title="Reflection Survey" />

      <Container className="grid max-w-[414px] justify-items-center pb-12">
        <SectionHeader heading="Reflection" step={5} />
        <p className="mt-11 text-center text-navy-500">
          Answering these questions helps you{' '}
          <span className="font-bold">reflect and take action</span> to make progress toward your
          goals.
        </p>
        <Divider className="my-4" />

        <p className="text-center text-navy-500">
          Select ONE aspect of your performance you’d like to improve? (Required)
        </p>

        <ul className="mt-5 flex flex-wrap gap-x-2 gap-y-3">
          {improvementAreas.map((area) => (
            <li key={area}>
              <button
                className={`rounded-[60px] bg-navy-500 py-1 px-4 transition-all duration-200 ${
                  selectedArea === area
                    ? 'bg-lightblue-500 text-navy-500'
                    : ' bg-navy-500 text-white'
                }`}
                onClick={() => selectAreaHandler(area)}
              >
                {area}
              </button>
            </li>
          ))}
        </ul>

        <div className="relative mt-4 w-full">
          <label htmlFor="reason" className="text-navy-500">
            Please explain why.
          </label>
          <textarea
            id="reason"
            value={reasonText}
            onChange={(e) => setReasonText(e.target.value)}
            className="mt-2 w-full rounded border border-primary p-4"
            rows={5}
            maxLength={700}
          />
        </div>

        <div className="relative mt-8 w-full">
          <label htmlFor="action" className="text-navy-500">
            What would be ONE action in your next practice session to improve?
          </label>
          <textarea
            id="action"
            value={actionText}
            onChange={(e) => setActionText(e.target.value)}
            className="mt-2 w-full rounded border border-primary p-4"
            rows={5}
            maxLength={700}
          />
        </div>

        <Button
          onClick={submitHandler}
          disabled={selectedArea === ''}
          className="mt-6 w-full max-w-[320px]"
          isLoading={isSubmitting}
        >
          Submit
        </Button>
      </Container>

      {/* <ProgressWheelLarge
        className="fixed left-0 top-1/2 -translate-y-1/2"
        step={4}
        message="Reflection"
      /> */}
    </>
  );
};
