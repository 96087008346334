export const formatDate = (dateString: string) => {
  const date = new Date(+dateString);
  const formattedDate = new Intl.DateTimeFormat('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  }).format(date);

  return formattedDate;
};
