import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PromptAPI, PromptDTO } from '../../apis/PromptAPI';
import { UserPromptsAPI, UserPrompt, UserPromptDto } from '../../apis/UserPromptsAPI';

type PromptState = {
  selectedPrompt: UserPrompt | null;
  generatedPrompts: {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    gptPrompts: string[];
  };
  userPrompts: {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    recentPrompts: UserPrompt[];
  };
};

const initialState: PromptState = {
  selectedPrompt: null,
  generatedPrompts: {
    status: 'idle',
    gptPrompts: [],
  },
  userPrompts: {
    status: 'idle',
    recentPrompts: [],
  },
};

export const getGPTPrompts = createAsyncThunk(
  'prompts/getGPTPrompts',
  async (promptDto: PromptDTO) => {
    const prompts = await PromptAPI.getGPTPrompts(promptDto);
    return prompts;
  }
);

export const getUserPrompts = createAsyncThunk('prompts/userPrompts', async () => {
  const userPrompts = await UserPromptsAPI.getUserPrompts();
  return userPrompts;
});

export const saveUserPrompt = createAsyncThunk(
  'prompts/saveUserPrompt',
  async (userPromptDto: UserPromptDto, thunkAPI) => {
    const userPrompt = await UserPromptsAPI.saveUserPrompt(userPromptDto);
    thunkAPI.dispatch(getUserPrompts());
    return userPrompt;
  }
);

export const promptSlice = createSlice({
  name: 'prompts',
  initialState,
  reducers: {
    updateSelectedPrompt(state, action: PayloadAction<UserPrompt>) {
      state.selectedPrompt = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGPTPrompts.pending, (state) => {
        state.generatedPrompts.status = 'loading';
      })
      .addCase(getGPTPrompts.fulfilled, (state, action) => {
        state.generatedPrompts.status = 'succeeded';
        state.generatedPrompts.gptPrompts = action.payload;
      })
      .addCase(getGPTPrompts.rejected, (state) => {
        state.generatedPrompts.status = 'failed';
      })
      .addCase(getUserPrompts.pending, (state) => {
        state.userPrompts.status = 'loading';
      })
      .addCase(getUserPrompts.fulfilled, (state, action) => {
        state.userPrompts.status = 'succeeded';
        state.userPrompts.recentPrompts = action.payload;
      })
      .addCase(getUserPrompts.rejected, (state) => {
        state.userPrompts.status = 'failed';
      })
      .addCase(saveUserPrompt.fulfilled, (state, action) => {
        state.selectedPrompt = action.payload;
      })
  },
});

export const { updateSelectedPrompt } = promptSlice.actions;
