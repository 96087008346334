import Lottie from 'lottie-react';
import robotAnimation from '../../../assets/lottie/39701-robot-bot-3d.json';
import { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { SEO } from '../../../components';
import { updateSelectedPrompt, useAppDispatch, useAppSelector } from '../../../redux';
import { Button, Container, Spinner } from '../../../components/Elements';
import { useTrackEvent } from '../../../hooks';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

export const PromptSelection = () => {
  // const [goalSelected, setGoalSelected] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { trackEvent } = useTrackEvent();
  const { gptPrompts, status } = useAppSelector((state) => state.prompts.generatedPrompts);

  useEffect(() => {
    if (status === 'idle') {
      navigate('/goals/generate-prompts');
    }
  }, [status, navigate]);

  useEffect(() => {
    trackEvent('generate_prompts', 'prompt_select_start');
  }, [trackEvent]);

  useEffect(() => {
    if (gptPrompts.length > 0) {
      trackEvent('generate_prompts', 'prompts_generated', { gptPrompts });
    }
  }, [trackEvent, gptPrompts]);

  // const selectGoalHandler = (goal: string) => {
  //   trackEvent('interview_practice', 'goal_select', { goal });
  //   setGoalSelected(true);
  // };

  const selectPromptHandler = (prompt: string) => {
    dispatch(updateSelectedPrompt({ prompt }));
    trackEvent('interview_practice', 'prompt_select', { prompt });
    navigate('/recording-tips');
  };

  return (
    <>
      <SEO title="Prompt Selection" />

      <NavLink
        onClick={() => trackEvent('generate_prompts', 'back_to_generate_prompt')}
        to="/goals/generate-prompts"
        className="absolute top-4 left-4 z-20 text-gray-500 hover:underline"
      >
        Back
      </NavLink>

      <Container className="mt-12 grid max-w-[450px] justify-items-center pb-6">
        {/* Render three goals for user to pick while they wait for gpt to load */}
        {/* {!goalSelected && (
          <div className="grid w-full max-w-[350px] text-center">
            <p className="rounded-xl bg-navy-100 p-6 text-white">
              Setting goals is a scientifically proven way to improve and master a skill!
            </p>

            <h2 className="mt-6 text-xl font-bold text-navy-500">
              What is most important for you to work on right now?
            </h2>

            <p className="mt-10 text-lg font-light text-navy-500">
              My ability to effectively demonstrate:
            </p>

            <Button
              variant="secondary"
              size="lg"
              radius="rounded"
              className="mt-10 w-full"
              onClick={() => selectGoalHandler('interpersonal')}
            >
              Strong interpersonal skills
            </Button>
            <Button
              variant="secondary"
              size="lg"
              radius="rounded"
              className="mt-10 w-full"
              onClick={() => selectGoalHandler('motivation')}
            >
              Motivation to learn and grow
            </Button>

            <Button
              variant="secondary"
              size="lg"
              radius="rounded"
              className="mt-10 w-full"
              onClick={() => selectGoalHandler('resilience')}
            >
              <span className="block max-w-[240px] leading-tight">
                Resilience in the face of challenges
              </span>
            </Button>
          </div>
        )} */}

        {status === 'loading' && (
          <div className="mt-12 grid place-items-center">
            <p className="text-xl uppercase text-navy-500">Generating Prompts</p>
            <Lottie animationData={robotAnimation} loop={true} className="w-full" />
          </div>
        )}

        {status === 'succeeded' && (
          <div className="grid justify-items-center">
            <p className="text-lg text-navy-500">
              We used Generative AI to produce the following interview prompts based on the job
              title, industry, and seniority level you specified.
            </p>

            <h2 className="mt-10 max-w-[24ch] text-center text-xl font-bold">
              Select a prompt you would like to practice with.
            </h2>

            <ul className="mt-10 grid gap-4">
              {gptPrompts.map((prompt, index) => (
                <li className="" key={index}>
                  <button
                    className="flex w-full rounded-xl bg-navy-100 px-4 py-6 text-start text-white transition-all duration-100 hover:bg-navy-200"
                    onClick={() => selectPromptHandler(prompt.slice(3))}
                  >
                    {prompt.slice(3)}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}

        {(status === 'failed' || (status === 'succeeded' && gptPrompts.length === 0)) && (
          <div className="grid place-items-center">
            <ExclamationTriangleIcon className="w-12 text-red-500" />
            <p className="mt-2">An error occurred while generating prompts.</p>
            <p>Please navigate back and try again.</p>
            <NavLink
              className="mt-4 rounded-3xl bg-primary px-10 py-3 shadow shadow-navy-500"
              to="/goals/generate-prompts"
            >
              Go Back
            </NavLink>
          </div>
        )}
      </Container>
    </>
  );
};
