import { NavLink, useLocation } from 'react-router-dom';
import { useState } from 'react';
import { useAppSelector } from '../../redux';
import { Modal } from '../Elements';
import {
  StopCircleIcon,
  HomeIcon,
  ArchiveBoxIcon,
  Bars3BottomLeftIcon,
} from '@heroicons/react/24/solid';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { useTrackEvent } from '../../hooks';

type MenuProps = {
  className?: string;
  scrolled?: boolean;
};

export const Menu = ({ className = '', scrolled = false }: MenuProps) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const location = useLocation();
  const { trackEvent } = useTrackEvent();

  const {
    user: { settings },
    userHistory: { recordingCount },
  } = useAppSelector((state) => state);

  const openMenuHandler = () => {
    setMenuIsOpen(true);
    trackEvent('ccs_global', 'navigation');
  };

  const closeMenuHandler = () => {
    setMenuIsOpen(false);
  };

  return (
    <div className={`${className}`}>
      <button
        onClick={openMenuHandler}
        className={`text-navy-600 ${location.pathname === '/record' && 'invisible'} ${
          location.pathname === '/dashboard' && ''
        }`}
      >
        <Bars3BottomLeftIcon className={`w-8 ${scrolled && 'text-white'}`} />
      </button>
      <Modal isOpen={menuIsOpen} onClose={closeMenuHandler}>
        <div className="grid gap-6 p-3 text-xl text-navy-500">
          {/* Render recording count if greater than 0 */}
          {recordingCount > 0 && (
            <p className="text-center text-sm">
              You have recorded <span className="font-bold">{recordingCount}</span> interviews.
            </p>
          )}

          {/* Only Render Dashboard button if user is boarded */}
          {settings && settings.isBoarded && (
            <NavLink
              className="flex items-center"
              to="/dashboard"
              onClick={() => {
                trackEvent('ccs_global', 'navigate_dashboard');
                closeMenuHandler();
              }}
            >
              <HomeIcon className="mr-3 w-8" />
              <span>Dashboard</span>
            </NavLink>
          )}

          <NavLink
            className="flex items-center"
            to="/goals/generate-prompts"
            onClick={() => {
              trackEvent('ccs_global', 'navigate_goals');
              closeMenuHandler();
            }}
          >
            <CheckCircleIcon className="mr-3 w-8" />
            <span>Set Goals</span>
          </NavLink>

          <NavLink
            className="flex items-center"
            to="/record"
            onClick={() => {
              trackEvent('ccs_global', 'navigate_record');
              closeMenuHandler();
            }}
          >
            <StopCircleIcon className="mr-3 w-8" />
            <span>Record Interview</span>
          </NavLink>

          {recordingCount > 0 && (
            <NavLink
              className="flex items-center"
              to="/history"
              onClick={() => {
                trackEvent('ccs_global', 'navigate_archive');
                closeMenuHandler();
              }}
            >
              <ArchiveBoxIcon className="mr-3 w-8 fill-navy-500" />
              <span>Interview Archive</span>
            </NavLink>
          )}
        </div>
      </Modal>
    </div>
  );
};
