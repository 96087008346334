import fixWebmDuration from 'webm-duration-fix';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState, MutableRefObject, useEffect } from 'react';
import { XMarkIcon } from '@heroicons/react/24/solid';

import { VideoPlayer } from '../../../components';
import { Spinner } from '../../../components/Elements';
import { getVideoSrc } from '../../../utils/mmai';

type MobileVideoPlayerProps = {
  isOpen: boolean;
  onClose: () => void;
  initialFocus?: MutableRefObject<null>;
  fileKey: string;
  fileType: string;
  prompt: string;
  eventType?: string;
  eventPrefix?: string;
};

export const MobileVideoPlayer = ({
  isOpen,
  onClose,
  fileKey,
  fileType,
  initialFocus,
  prompt,
  eventType = '',
  eventPrefix = ''
}: MobileVideoPlayerProps) => {
  const [videoUrl, setVideoUrl] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getVideoUrl = async () => {
      if (!fileKey || !isOpen || videoUrl) return;
      setLoading(true);

      try {
        const url = await getVideoSrc(fileKey);
        if (!url) return;

        if (fileType === '.webm') {
          const data = await fetch(url);
          const blob = await data.blob();
          const fixedBlob = await fixWebmDuration(blob);
          const newUrl = URL.createObjectURL(fixedBlob);
          setVideoUrl(newUrl);
        } else {
          setVideoUrl(url);
        }
      } catch (error) {
        // TODO: Add Error Handling if video fails to load
        console.log(error);
      }

      setLoading(false);
    };

    getVideoUrl();
  }, [fileKey, isOpen, videoUrl, fileType]);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-[100] lg:flex lg:items-center lg:justify-center"
        open={isOpen}
        onClose={onClose}
        initialFocus={initialFocus}
      >
        <div className="relative h-full w-full max-w-[1024px] bg-black lg:aspect-video lg:h-auto lg:overflow-hidden lg:rounded-2xl">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-300"
            enterFrom="opacity-0 scale-75"
            enterTo="opacity-100 scale-100"
            leave="ease-in-out duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="h-full">
              {/* Exit Button */}
              <button
                onClick={onClose}
                className="absolute right-4 bottom-4 z-50 grid aspect-square w-16 place-items-center rounded-full bg-primary"
              >
                <XMarkIcon className="w-8" />
              </button>

              {/* Loading Spinner */}
              {loading && (
                <div className="absolute inset-0 z-30 grid place-items-center">
                  <Spinner size="xl" variant="light" />
                </div>
              )}

              {/* Prompt */}
              <div className="absolute top-0 left-0 right-0 z-40 grid place-items-center bg-blue-gradient/90 p-3 text-center leading-snug text-white">
                {prompt}
              </div>

              {/* Video Player */}
              {videoUrl && (
                <VideoPlayer
                  src={videoUrl}
                  controlsOffsetY="mb-28"
                  autoPlay={true}
                  desktopControlsOffsetY="pb-24"
                  eventType={eventType}
                  eventPrefix={eventPrefix}
                />
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
