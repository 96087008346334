import { useEffect } from 'react';
import clsx from 'clsx';
import { useForm, SubmitHandler } from 'react-hook-form';
import {
  useAppDispatch,
  useAppSelector,
  getGPTPrompts,
  updateSelectedPrompt,
} from '../../../redux';
import { Container } from '../../../components/Elements';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { EXCLUDE_EMOJIS_REGEX } from '../../../constants';
import { SEO } from '../../../components';
import { useTrackEvent } from '../../../hooks';

type FormValues = {
  jobTitle: string;
  industry: string;
  seniorityLevel: string;
};

export const PromptGeneration = () => {
  const { recentPrompts } = useAppSelector((state) => state.prompts.userPrompts);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { trackEvent } = useTrackEvent();

  // On page load
  useEffect(() => {
    trackEvent('generate_prompts', 'generative_prompts_start');
  }, [trackEvent]);

  // Recent prompts load
  useEffect(() => {
    if (recentPrompts.length > 0) {
      trackEvent('generate_prompts', 'recent_prompts_load', { recentPrompts });
    }
  }, [trackEvent, recentPrompts]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  const getPromptsHandler: SubmitHandler<FormValues> = async (formValues) => {
    dispatch(getGPTPrompts(formValues));

    const { industry, jobTitle, seniorityLevel } = formValues;
    trackEvent('generate_prompts', 'generate_prompts', {
      job_field: jobTitle,
      industry,
      seniority: seniorityLevel,
    });

    navigate('/goals/select-prompt');
  };

  const selectRecentPromptHandler = (
    prompt: string,
    id?: string,
    practiceCount?: number,
    practiceDate?: number,
    userId?: string
  ) => {
    dispatch(updateSelectedPrompt({ prompt, id, practiceCount, practiceDate }));

    trackEvent('generate_prompts', 'prompt_reselect', {
      id,
      prompt,
      practiceCount,
      practiceDate,
      userId,
    });

    navigate('/recording-tips');
  };

  return (
    <>
      <SEO title="Prompt Generation" />

      <Container
        className={clsx([
          recentPrompts.length > 0 ? 'max-w-[550px] lg:max-w-[1100px]' : 'max-w-[500px]',
        ])}
      >
        {/* Generate Practice Questions */}
        <div
          className={clsx([
            'mt-12 grid min-h-[400px] gap-6',
            recentPrompts.length > 0 ? 'grid-cols-1 lg:grid-cols-[1fr_30px_1fr]' : 'grid-cols-1',
          ])}
        >
          <div className="px-2">
            <h2 className="text-lg font-bold text-navy-600">Generate Practice Prompts</h2>
            <p className="mt-4 text-gray-600">
              Please tell us what job/position you are interested in interviewing for?
            </p>

            <form className="mt-4" onSubmit={handleSubmit(getPromptsHandler)}>
              <label className="relative flex flex-col">
                <span className="pb-1 text-gray-600">
                  Job Title<span className="text-red-600">*</span>
                </span>
                <input
                  {...register('jobTitle', {
                    required: true,
                    pattern: EXCLUDE_EMOJIS_REGEX,
                  })}
                  className={clsx([
                    'w-full rounded-lg border border-navy-200/50 p-2 text-gray-600 shadow-inner shadow-navy-200/50',
                  ])}
                  onFocus={() => trackEvent('generate_prompts', 'job_title_textbox')}
                />
                {errors.jobTitle?.type === 'required' && (
                  <div className="absolute right-2 bottom-2 z-10 flex items-center gap-1 text-sm text-red-600">
                    <ExclamationTriangleIcon className="w-5" />
                    <span>Required</span>
                  </div>
                )}
                {errors.jobTitle?.type === 'pattern' && (
                  <div className="absolute right-2 bottom-2 z-10 flex items-center gap-1 text-sm text-red-600">
                    <ExclamationTriangleIcon className="w-5" />
                    <span>Invalid Input</span>
                  </div>
                )}
              </label>

              <label className="relative mt-4 flex flex-col">
                <span className="pb-1 text-gray-600">Field/Industry &#40;optional&#41;</span>
                <input
                  {...register('industry', {
                    pattern: EXCLUDE_EMOJIS_REGEX,
                  })}
                  className="w-full rounded-lg border border-navy-200/50 p-2 text-gray-600 shadow-inner shadow-navy-200/50"
                  onFocus={() => trackEvent('generate_prompts', 'industry_textbox')}
                />
                {errors.industry?.type === 'pattern' && (
                  <div className="absolute right-2 bottom-2 z-10 flex items-center gap-1 text-sm text-red-600">
                    <ExclamationTriangleIcon className="w-5" />
                    <span>Invalid Input</span>
                  </div>
                )}
              </label>

              <label className="relative mt-4 flex flex-col">
                <span className="pb-1 text-gray-600">Seniority Level &#40;optional&#41;</span>
                <input
                  {...register('seniorityLevel', {
                    pattern: EXCLUDE_EMOJIS_REGEX,
                  })}
                  className="w-full rounded-lg border border-navy-200/50 p-2 text-gray-600 shadow-inner shadow-navy-200/50"
                  onFocus={() => trackEvent('generate_prompts', 'seniority_textbox')}
                />
                {errors.seniorityLevel?.type === 'pattern' && (
                  <div className="absolute right-2 bottom-2 z-10 flex items-center gap-1 text-sm text-red-600">
                    <ExclamationTriangleIcon className="w-5" />
                    <span>Invalid Input</span>
                  </div>
                )}
              </label>

              <button
                className="mt-4 w-full rounded-xl bg-primary py-3 px-8 font-semibold text-navy-500 shadow shadow-navy-400 hover:opacity-80 lg:w-auto"
                type="submit"
              >
                Generate Prompts
              </button>
            </form>
          </div>

          {/* Divider */}
          {recentPrompts.length > 0 && (
            <div className="flex items-center justify-center lg:flex-col">
              <div className="h-[2px] flex-1 bg-lightblue-400 lg:h-auto lg:w-[2px]" />
              <span className="px-4 text-gray-600 lg:px-0 lg:py-4">or</span>
              <div className="h-[2px] flex-1 bg-lightblue-400 lg:h-auto lg:w-[2px]" />
            </div>
          )}

          {/* Recently Practiced Questions */}
          {recentPrompts.length > 0 && (
            <div>
              <h2 className="px-2 text-lg font-bold text-navy-600">
                Choose a recently practiced prompt
              </h2>

              <ul className="mt-4 grid gap-4 overflow-auto px-2 pb-4 lg:max-h-[480px]">
                {recentPrompts.map(({ prompt, id, practiceDate, practiceCount, userId }) => (
                  <li key={id}>
                    <button
                      onClick={() =>
                        selectRecentPromptHandler(prompt, id, practiceCount, practiceDate, userId)
                      }
                      className="relative flex w-full rounded-lg bg-sky-100 px-3 pt-8 pb-3 text-start text-navy-600 transition-all duration-100 hover:shadow-md "
                    >
                      <span>{prompt}</span>
                      {practiceDate && (
                        <span className="absolute top-3 right-3 text-xs text-gray-500">
                          Last practiced on {/* TODO: Bring in Date formatter Util */}
                          {new Intl.DateTimeFormat('en-US', {
                            month: 'long',
                            day: 'numeric',
                            year: 'numeric',
                          }).format(practiceDate)}
                        </span>
                      )}
                      {practiceCount && (
                        <span className="absolute top-3 left-3 text-xs text-gray-500">
                          Practiced <span className="text-gray-500">{practiceCount}</span> times
                        </span>
                      )}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </Container>
    </>
  );
};
