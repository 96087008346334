import { useState } from 'react';
import { useEffect } from 'react';
import { useAppSelector, useAppDispatch, updateUser } from '../../redux';
import { Modal } from '../Elements';
import clsx from 'clsx';
import { useTrackEvent } from '../../hooks';
import { ReactComponent as SurveyCompleteSVG } from '../../assets/icons/survey_complete.svg';
import { XMarkIcon } from '@heroicons/react/24/solid';

type AppSurveyProps = {
  isOpen: boolean;
  onClose: () => void;
};

const surveyQuestions: SurveyQuestion[] = [
  {
    question: 'It was easy to use this app.',
    score: null,
  },
  {
    question: 'This app motivates me to prepare for interviews.',
    score: null,
  },
  {
    question: 'My communication skills have grown due to using this app.',
    score: null,
  },
  {
    question: 'The interview feedback categories are meaningful to my improvement.',
    score: null,
  },
  {
    question: 'I feel more confident about my interviewing skills after using this app.',
    score: null,
  },
  {
    question: 'I plan to continue using this app.',
    score: null,
  },
];

type SurveyQuestion = {
  question: string;
  score: number | null;
};

const ratings = [1, 2, 3, 4, 5];

export const AppSurvey = ({ isOpen, onClose }: AppSurveyProps) => {
  const [questions, setQuestions] = useState(surveyQuestions);
  const [surveyCompleted, setSurveyCompleted] = useState(false);

  const { trackEvent } = useTrackEvent();
  const { settings } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  // Reset the Survey State when closing the modal. There's a set timeout to allow the exit animation to finish before reset
  useEffect(() => {
    if (isOpen) {
      trackEvent('ccs_global', 'app_survey_start');
    }

    if (!isOpen) {
      setTimeout(() => {
        setSurveyCompleted(false);
        setQuestions(surveyQuestions);
      }, 300);
    }
  }, [isOpen, trackEvent]);

  // Send event when user completes survey
  useEffect(() => {
    if (surveyCompleted) {
      trackEvent('ccs_global', 'app_survey_thanks');
    }
  }, [surveyCompleted, trackEvent])

  const updateAnswerHandler = (currentQuestion: string, rating: number) => {
    const updatedQuestions = questions.map((surveyQuestion) => {
      if (surveyQuestion.question !== currentQuestion) return surveyQuestion;

      return { ...surveyQuestion, score: rating };
    });

    setQuestions(updatedQuestions);
  };

  const submitSurveyHandler = () => {
    trackEvent('ccs_global', 'app_survey', questions);
    dispatch(updateUser({ settings: { ...settings, surveyCompleted: true } }));
    setSurveyCompleted(true);
  };

  const submitDisabled = questions.find((question) => question.score === null) ? true : false;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="relative">
        <button className="absolute -top-2 -right-2 text-navy-600" onClick={onClose}>
          <span className="sr-only">Close Modal</span>
          <XMarkIcon className="h-5 w-5" />
        </button>
        {!surveyCompleted ? (
          <div className="flex max-w-[380px] flex-col">
            <h1 className="text-center text-xl font-bold text-navy-600">
              We would love your feedback!
            </h1>

            {/* <p className="mt-4 text-center text-sm text-neutral-600">
              Our goal is to make a platform that simplifies your interview preparation.
            </p>

            <p className="mt-2 text-center text-sm text-neutral-600">
              You've been using Authentic Interview Prep for some time now, and we'd love to know
              what you think about it.
            </p>

            <p className="mt-2 text-center text-sm text-neutral-600">
              We really appreciate your feedback, {username}!
            </p> */}

            <p className="mt-2 text-sm text-neutral-600">
              We are actively working on Authentic, and would love to learn how we can improve.
              Please take a minute to complete this survey. Your feedback is appreciated!
            </p>

            <ol className="mt-4 grid gap-6">
              {questions.map(({ question, score }, index) => (
                <div key={question} className="relative">
                  <li className="text-navy-600" key={question}>
                    {question}
                  </li>
                  <div className="mt-2 flex justify-between px-4">
                    {ratings.map((rating) => (
                      <button
                        key={rating}
                        className={clsx(
                          'aspect-square w-9 rounded-full border border-navy-500',
                          score === rating ? 'bg-navy-500 text-white' : 'text-black'
                        )}
                        onClick={() => updateAnswerHandler(question, rating)}
                      >
                        {rating}
                      </button>
                    ))}
                  </div>
                  <div className="mt-2 flex justify-between px-2 text-sm text-navy-500">
                    <span>Disagree</span>
                    <span className="mr-2">Agree</span>
                  </div>
                </div>
              ))}
            </ol>

            <div className="mt-6 grid grid-cols-2 gap-4">
              <button
                onClick={() => {
                  trackEvent('ccs_global', 'app_survey_skip');
                  onClose();
                }}
                className="rounded-md border border-neutral-600 py-2 text-neutral-600"
              >
                No, Thanks
              </button>
              <button
                disabled={submitDisabled}
                onClick={submitSurveyHandler}
                className="rounded-md border bg-navy-500 py-2 text-white disabled:bg-neutral-500"
              >
                Submit
              </button>
            </div>
          </div>
        ) : (
          <div className="grid max-w-[380px] place-items-center gap-4 px-4">
            <h1 className="text-xl font-bold text-navy-300">Thanks for your feedback!</h1>
            <p className="text-center text-neutral-600">
              We look forward to continuing to aid in your interview preparation and improve our
              platform with new features. Thank you for using Authentic!
            </p>
            <SurveyCompleteSVG className="h-64 w-64" />
            <button className="w-full rounded-md bg-navy-300 py-2 text-white" onClick={onClose}>
              Close
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
};
