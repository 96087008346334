import { forwardRef } from 'react';
import { Spinner } from '../Spinner';

const variants = {
  primary: 'bg-primary text-navy-600',
  secondary: 'bg-navy-500 text-white',
  active: 'bg-blue-gradient text-white',
  outline: 'bg-white text-navy-500 border border-navy-500',
  progressForward: 'bg-navy-500 text-lightblue-500',
  progressBack: 'bg-lightblue-500 text-navy-500',
  borderless: 'border-0',
  danger: 'bg-red-600 text-white',
  none: "",
};

const sizes = {
  sm: 'text-sm px-4',
  md: 'text-[20px] px-6 h-[60px]',
  lg: 'text-lg h-[70px]',
  xl: 'text-lg min-h-[100px] p-4',
  xxl: 'text-lg min-h-[144px] p-4',
  progress: 'w-[40px] h-[40px]',
  menu: 'w-[40px] h-[40px]',
};

const radii = {
  small: 'rounded-md',
  normal: 'rounded-[30px]',
  rounded: 'rounded-[36px]',
  round: 'rounded-full',
};

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: keyof typeof variants;
  size?: keyof typeof sizes;
  radius?: keyof typeof radii;
  isLoading?: boolean;
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      type = 'button',
      className = '',
      variant = 'primary',
      size = 'md',
      radius = 'normal',
      isLoading = false,
      ...props
    },
    ref
  ) => {
    return (
      <button
        ref={ref}
        type={type}
        className={`relative flex items-center justify-center font-open-sans font-normal disabled:cursor-not-allowed disabled:bg-slate-200 disabled:opacity-70 ${
          isLoading ? 'pointer-events-none' : ''
        } ${variants[variant]} ${sizes[size]} ${radii[radius]} ${className}`}
        {...props}
        style={{
          boxShadow: `${
              variant === 'primary' 
              ? '0px 2px 1px #193152'
              : variant === 'secondary' ||
                variant === 'outline' ||
                variant === 'active' ||
                size === 'progress'
              ? '0px 2px 1px rgba(0, 0, 0, 0.25)'
              : ''
          }`,
        }}
      >
        {isLoading && <Spinner size="md" className="absolute text-current" />}
        <span className={`mx-2 ${isLoading ? 'opacity-0' : ''}`}>{props.children}</span>
      </button>
    );
  }
);

Button.displayName = 'Button';
