type TipCardProps = {
  header: string;
  tips: string[];
};

export const TipCard = ({ header, tips }: TipCardProps) => {
  return (
    <article className="w-full">
      <h3 className="text-xl font-bold uppercase text-navy-200">{header}</h3>
      <ul className="mt-2 list-disc border border-lightblue-500 bg-white py-2 pr-2 pl-7">
        {tips.map((item) => (
          <li className="text-xs text-navy-500" key={item}>
            <span className="text-base">{item}</span>
          </li>
        ))}
      </ul>
    </article>
  );
};
