export type StepProps = {
  text: string;
  step: number;
  active?: boolean;
  className?: string;
};

export const Step = ({ text, step, active = false, className = '' }: StepProps) => {
  const translateX =
    step === 2 || step === 4 ? 'translate-x-24' : step === 3 ? 'translate-x-32' : '';

  return (
    <li
      className={`flex items-center ${
        !active && 'opacity-25'
      } ${className} ${translateX} transition-all duration-200 sm:translate-x-0`}
    >
      <div className="grid h-[51px] w-[51px] place-content-center rounded-full border-3 border-navy-500 bg-white">
        <span className="font-open-sans text-4xl font-bold">{step}</span>
      </div>

      <p className="ml-[10px] max-w-[186px] text-xl leading-6 tracking-wide text-navy-500 sm:max-w-none">
        {text}
      </p>
    </li>
  );
};
