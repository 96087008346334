import { MutableRefObject, Fragment } from 'react';
import { Transition, Dialog } from '@headlessui/react';

type CountdownModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onUpdateCountdown: (countdown: number) => void;
  initialFocus?: MutableRefObject<null>;
  countdownTime: number;
};

export const CountdownModal = ({
  onUpdateCountdown,
  isOpen,
  onClose,
  initialFocus,
  countdownTime,
}: CountdownModalProps) => {
  const setCountdownHandler = (countdown: number) => {
    onUpdateCountdown(countdown);
    onClose();
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-50 overflow-y-hidden "
        open={isOpen}
        onClose={onClose}
        initialFocus={initialFocus}
      >
        <div className="flex h-full items-center justify-center lg:pl-56">
          <Dialog.Overlay className="fixed inset-0 bg-black/40" />
          <Transition.Child
            as={Fragment}
            enter="duration-200 transition-all ease-in"
            enterFrom="translate-x-4 opacity-0"
            enterTo="translate-x-0 opacity-100"
            leave="duration-200 transition-all ease-in"
            leaveFrom="translate-x-0 opacity-100"
            leaveTo="translate-x-4 opacity-0"
          >
            <div className="pointer-events-none grid justify-items-center">
              <div className="pointer-events-auto rounded-3xl bg-blue-gradient py-4 px-6 text-center">
                <h3 className="text-xl font-semibold tracking-wide text-white">Set Timer</h3>
                <div className="mt-4 flex justify-between gap-4">
                  <button
                    className={`aspect-square w-12 rounded-full ${
                      countdownTime === 3000
                        ? 'bg-primary text-black'
                        : 'bg-neutral-600/80 text-white'
                    } `}
                    onClick={() => setCountdownHandler(3000)}
                  >
                    3s
                  </button>
                  <button
                    className={`aspect-square w-12 rounded-full ${
                      countdownTime === 5000
                        ? 'bg-primary text-black'
                        : 'bg-neutral-600/80 text-white'
                    } `}
                    onClick={() => setCountdownHandler(5000)}
                  >
                    5s
                  </button>
                  <button
                    className={`aspect-square w-12 rounded-full ${
                      countdownTime === 10000
                        ? 'bg-primary text-black'
                        : 'bg-neutral-600/80 text-white'
                    } `}
                    onClick={() => setCountdownHandler(10000)}
                  >
                    10s
                  </button>
                </div>
              </div>
              <p className="mt-2 max-w-[15ch] text-center text-sm text-neutral-300">
                Tap anywhere on Screen to dismiss
              </p>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
