// Types
import {
  CONNECT_WEBSOCKET,
  CONNECTED_WEBSOCKET,
  MESSAGE_RECEIVED,
  FAILED_WEBSOCKET,
  DISCONNECTED_WEBSOCKET,
  RESET_MESSAGE_DATA,
} from '../types';

export const connectWebsocket = () => ({ type: CONNECT_WEBSOCKET });
export const connectedWebsocket = () => ({ type: CONNECTED_WEBSOCKET });
export const failedWebsocket = (error: string) => ({
  type: FAILED_WEBSOCKET,
  payload: error,
});

export const receivedMessage = (messageData: any) => ({
  type: MESSAGE_RECEIVED,
  payload: messageData,
});

export const disconnectedWebsocket = () => ({
  type: DISCONNECTED_WEBSOCKET,
});

export const resetMessageData = () => ({
  type: RESET_MESSAGE_DATA,
});
