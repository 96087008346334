import { PersonalizedFeedback } from '../types';

export const getEyeContactFeedback = (eyeContactScore: string): PersonalizedFeedback => {
  const heading = 'Eye Contact';
  const optimalLowerBound = 70;
  const optimalUpperBound = 95;
  const eyeContactScoreNum = Math.round(+eyeContactScore);
  const subHeading = `Your eyes appear in contact with the center of the screen `;

  const staticFields = {
    heading,
    subHeading,
    optimalLowerBound,
    optimalUpperBound,
    percentageScore: eyeContactScoreNum,
  };

  if (eyeContactScoreNum <= 54) {
    return {
      ...staticFields,
      icon: 'low',
      scoreMessage: 'too low',
      tipHeader: 'Interview Advice',
      tips: [
        'Avoid looking down or around frequently; this could signal distress or discomfort to an interviewer.',
        `If you are uncomfortable looking directly into someone's eyes, try looking at the camera.`,
      ],
    };
  } else if (eyeContactScoreNum <= 69) {
    return {
      ...staticFields,
      icon: 'almost',
      scoreMessage: 'almost there!',
      tipHeader: 'How To Improve',
      tips: ['Try looking more at the camera to mimic eye contact with your interviewer.'],
    };
  } else if (eyeContactScoreNum <= 95) {
    return {
      ...staticFields,
      scoreMessage: 'just right',
      icon: 'optimal',
      percentageScore: eyeContactScoreNum,
      tipHeader: 'Good Job!',
      tips: [
        'Your level of eye contact is just right! Good eye contact helps build rapport with the person you are speaking with.',
        'Interestingly, both too little and too much eye contact can negatively affect how people perceive you!',
      ],
    };
  } else {
    return {
      ...staticFields,
      scoreMessage: 'too much!',
      icon: 'high',
      tipHeader: 'How To Improve',
      tips: [
        'Did you know that prolonged or excessive eye contact may signify that you are attempt to asserting dominance or influence your listeners?',
        'Try looking away from your screen occasionally. Staring too intently can make other people feel uncomfortable.',
      ],
    };
  }
};
