import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Auth } from 'aws-amplify';

import { UserRecord, UserAPI, UserUpdateRecord } from '../../apis/UserAPI';

export type UserState = UserRecord & {
  status: 'loggedOut' | 'loading' | 'loggedIn' | 'failed' | 'updating';
  username: string;
  email: string;
};

type AuthenticatedUser = {
  attributes: {
    email: string;
  };
  username: string;
};

const initialState: UserState = {
  status: 'loggedOut',
  cognitoId: '',
  date: '',
  locale: '',
  timezone: '',
  username: '',
  email: '',
  settings: {
    isBoarded: false,
    surveyCompleted: false,
  },
  goals: {
    subtheme: '',
    theme: '',
  },
};

export const getUser = createAsyncThunk('user/getUser', async () => {
  const response = await UserAPI.getUser();

  const {
    username,
    attributes: { email },
  } = (await Auth.currentAuthenticatedUser()) as AuthenticatedUser;

  return {
    ...response,
    username,
    email,
  };
});

export const updateUser = createAsyncThunk('user/updateUser', async (user: UserUpdateRecord) => {
  const response = await UserAPI.updateUser(user);

  const {
    username,
    attributes: { email },
  } = (await Auth.currentAuthenticatedUser()) as AuthenticatedUser;

  return {
    ...response,
    username,
    email,
  };
});

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetUserState: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getUser.fulfilled, (state, action) => {
        return { ...action.payload, status: 'loggedIn' };
      })
      .addCase(getUser.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(updateUser.pending, (state) => {
        state.status = 'updating';
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        return { ...action.payload, status: 'loggedIn' };
      })
      .addCase(updateUser.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { resetUserState } = userSlice.actions;
