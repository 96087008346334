import { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { SVGProps } from 'react';
import { useAppSelector } from '../../redux';
import {
  StopCircleIcon,
  ArrowRightOnRectangleIcon,
  HomeIcon,
  ArchiveBoxIcon,
} from '@heroicons/react/24/solid';
import {
  CheckCircleIcon,
  ClipboardDocumentListIcon,
  ChatBubbleOvalLeftEllipsisIcon,
} from '@heroicons/react/24/outline';
import { useAuth } from '../../features/auth';
import { AppSurvey } from '../AppSurvey';

import { ReactComponent as AppLogo } from '../../assets/icons/app_logo.svg';
import { useTrackEvent } from '../../hooks';

type SideNavigationItem = {
  name: string;
  to: string;
  Icon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  activeUrls: string[];
};

export const SideNavigation = () => {
  const { signOut } = useAuth();
  const {
    user: { settings },
    userHistory: { recordingCount },
  } = useAppSelector((state) => state);
  const { pathname } = useLocation();
  const [showSurvey, setShowSurvey] = useState(false);
  const { trackEvent } = useTrackEvent();

  const navigation = [
    settings &&
      settings.isBoarded && {
        name: 'Dashboard',
        to: '/dashboard',
        Icon: HomeIcon,
        activeUrls: ['/dashboard'],
      },
    {
      name: 'Set Goals',
      to: '/goals/generate-prompts',
      Icon: CheckCircleIcon,
      activeUrls: ['/goals/generate-prompts', '/goals/select-prompt'],
    },
    {
      name: 'Record Interview',
      to: '/record',
      Icon: StopCircleIcon,
      activeUrls: ['/recording-tips', '/permissions', '/record'],
    },
    recordingCount > 0 && {
      name: 'Interview Archive',
      to: '/history',
      Icon: ArchiveBoxIcon,
      activeUrls: ['/history'],
    },
  ].filter(Boolean) as SideNavigationItem[];

  return (
    <nav className="grid-row-3 grid h-full auto-rows-fr justify-items-center overflow-hidden bg-blue-gradient text-white">
      <AppLogo className="h-44 w-44" title="Authentic Interview Prep" />

      <ul className="flex w-full flex-col justify-center gap-3">
        {navigation.map(({ Icon, name, to, activeUrls }) => (
          <li key={name}>
            <NavLink
              className={`relative flex items-center py-3 pl-8 transition-all duration-100 hover:opacity-100 ${
                activeUrls.includes(pathname) ? 'opacity-100' : 'opacity-60'
              }`}
              to={to}
            >
              <Icon
                className={`mr-3 w-6 transition-all duration-100 ${
                  activeUrls.includes(pathname) && 'text-primary'
                }`}
              />
              <span>{name}</span>

              {activeUrls.includes(pathname) && (
                <div className="position absolute right-0 h-3/4 w-[10px] translate-x-1/2 rounded-2xl bg-primary" />
              )}
            </NavLink>
          </li>
        ))}
      </ul>

      <div className="flex w-full items-end pb-6">
        <div className="grid gap-5">
          <a
            href="mailto:authentichelp@ets.org"
            className="flex w-full items-center pl-8 opacity-60 hover:opacity-100"
            title="authentichelp@ets.org"
            onClick={() => trackEvent('ccs_global', 'contact_us')}
          >
            <ChatBubbleOvalLeftEllipsisIcon className="mr-3 w-6" />
            <span>Contact Us</span>
          </a>
          <button
            className="flex w-full items-center pl-8 opacity-60 hover:opacity-100"
            onClick={() => {
              setShowSurvey(true);
              trackEvent('ccs_global', 'launch_app_survey');
            }}
          >
            <ClipboardDocumentListIcon className="mr-3 w-6" />
            <span>Provide Feedback</span>
          </button>
          <AppSurvey isOpen={showSurvey} onClose={() => setShowSurvey(false)} />

          <button
            className="flex w-full items-center pl-8 opacity-60 hover:opacity-100"
            onClick={() => {
              trackEvent('ccs_global', 'user_logout');
              signOut();
            }}
          >
            <ArrowRightOnRectangleIcon className="mr-3 w-6" />
            <span>Log Out</span>
          </button>
        </div>
      </div>
    </nav>
  );
};
