import { toast } from 'react-toastify';
import { Auth } from 'aws-amplify';
import { useState, useEffect } from 'react';
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Container, Modal, Divider, Spinner } from '../../../components/Elements';
import { useAppSelector } from '../../../redux';
import { useTrackEvent } from '../../../hooks';
import { ChangePassword } from '../components/ChangePassword';

export const AccountSettings = () => {
  const [deleteUserIsOpen, setDeleteUserIsOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { email, username } = useAppSelector((state) => state.user);
  const { trackEvent } = useTrackEvent();

  useEffect(() => {
    trackEvent('settings', 'start_settings');
  }, [trackEvent]);

  const deleteUserHandler = async () => {
    setIsDeleting(true);
    try {
      await Auth.deleteUser();
      toast.success('Account Deleted');
    } catch (error) {
      toast.error('Failed to delete account. Please check your network connection and try again.');
      setIsDeleting(false);
    }
  };

  const openDeleteAccountModalHandler = () => {
    setDeleteUserIsOpen(true);
    trackEvent('settings', 'start_delete_account');
  };

  const closeDeleteAccountModalHandler = () => {
    setDeleteUserIsOpen(false);
    trackEvent('settings', 'cancel_delete_account');
  };

  return (
    <main>
      <Container className="max-w-[500px] px-4 text-navy-500">
        <h1 className="mt-16 text-center text-2xl uppercase">Account Settings</h1>

        <h2 className="mt-6 text-lg font-medium">My Information</h2>
        <Divider className="my-2 mb-4" />

        <div className="mt-4 flex justify-between">
          <label>Email address:</label>
          <p className="text-neutral-600">{email}</p>
        </div>

        <div className="mt-2 flex justify-between">
          <label>Username:</label>
          <p className="text-neutral-600">{username}</p>
        </div>

        {/* <h2 className="mt-10 text-lg font-medium">My settings</h2> */}
        <h2 className="mt-10 text-lg font-medium">Account Management</h2>
        <Divider className="my-2 mb-4" />

        <ChangePassword />

        {/* <Divider className="my-4" />

        <h3>Appearance</h3>
        <p className="mt-1 text-sm text-neutral-600">
          Customize how Authentic looks on your device.
        </p>
        <select className="mt-2 rounded border border-navy-500 px-3 py-2 text-sm">
          <option>Use system setting</option>
          <option>light</option>
          <option>dark</option>
        </select> */}

        <Divider className="my-4" />

        <h3 className="">Delete Account</h3>
        <button
          onClick={openDeleteAccountModalHandler}
          className="mt-2 rounded border border-red-600 py-2 px-3 text-sm text-red-600"
        >
          Delete My Account
        </button>

        <Modal isOpen={deleteUserIsOpen} onClose={closeDeleteAccountModalHandler}>
          <button onClick={closeDeleteAccountModalHandler} className="absolute right-2 top-2">
            <XMarkIcon className="w-5" />
          </button>
          <div className="grid max-w-[300px] place-items-center text-center">
            <ExclamationTriangleIcon className="w-12 text-red-600" />
            <h3 className="text-lg font-bold">Delete Account</h3>
            <p className="mt-2">
              Are you sure you want to delete your account? This action is irreversible. Once your
              account is deleted, we won't be able to recover any of your information.
            </p>
            <div className="mt-4 grid w-full grid-cols-2 gap-4">
              <button
                onClick={closeDeleteAccountModalHandler}
                className="rounded border border-neutral-500 px-3 py-2 text-neutral-600"
              >
                Cancel
              </button>
              <button
                disabled={isDeleting}
                onClick={deleteUserHandler}
                className="flex justify-center rounded border bg-red-500 px-3 py-2 text-white"
              >
                {isDeleting ? <Spinner /> : <span>Delete Account</span>}
              </button>
            </div>
          </div>
        </Modal>
      </Container>
    </main>
  );
};
