import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { SEO } from '../../../components';
import { SectionHeader } from '../../../components/SectionHeader';
// import { ProgressWheelLarge } from '../../../components/ProgressWheel';
import { Container, Button } from '../../../components/Elements';
import { useAuth } from '../../auth';

export const Permissions = () => {
  const [denied, setDenied] = useState(false);
  const navigate = useRef(useNavigate());
  const { signOut } = useAuth();

  const checkUserMedia = async () => {
    try {
      const response = await window.navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true,
      });
      response.getTracks().forEach((track) => track.stop());
      navigate.current('/record');
    } catch (error) {
      console.log(error);
      setDenied(true);
    }
  };

  useEffect(() => {
    checkUserMedia();
  }, []);

  return (
    <div className="relative">
      <SEO title="Permissions" />

      {/* <ProgressWheelLarge
        step={1}
        message="Mock Interview"
        className="fixed left-0 top-1/2 -translate-y-1/2"
      /> */}
      <Container className="grid justify-items-center">
        <SectionHeader step={2} heading="Mock Interview" />
        {!denied && (
          <p className="mt-8 max-w-[327px] text-center font-light leading-loose text-navy-500">
            In order to record your practice and provide feedback, you need to allow{' '}
            <span className="text-xl font-medium uppercase">Authentic</span> to access your camera
            and microphone.
          </p>
        )}

        {denied && (
          <>
            <p className="mt-14 max-w-[380px] text-center font-light leading-loose text-navy-500">
              If you still want to use our mock interview feature, you will need to manually change
              your device's permission settings to allow us access to your camera and microphone.
            </p>
            <p className="mt-4 max-w-[380px] text-center font-light leading-loose text-navy-500">
              After changing your permissions setting, please click{' '}
              <span className="font-semibold">Check Permissions</span> below.
            </p>

            <Button onClick={checkUserMedia} className="mt-24 w-full max-w-[320px]">
              Check Permissions
            </Button>
            <Button onClick={signOut} variant="borderless" className="mt-8 w-full max-w-[320px]">
              Log Out
            </Button>
          </>
        )}
      </Container>
    </div>
  );
};
