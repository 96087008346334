import { axios } from '../configs';
import { PromptDTO, PromptsResponse } from './types';

export const PromptAPI = {
  endpoint: '/gpt-prompts',
  getGPTPrompts: async function (promptDTO: PromptDTO) {
    try {
      const response = await axios.post(this.endpoint, promptDTO);
      const { questions } = response.data as PromptsResponse;
      return questions;
    } catch (err) {
      throw err;
    }
  },
};
