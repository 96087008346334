import { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../redux';

import { AppSurvey, SEO } from '../../../components';
import { Button, Container } from '../../../components/Elements';
import { ProgressWheelLarge, SectionHeader, RecentStats } from '../../../components';
import { useTrackEvent } from '../../../hooks';

export const EndScreen = () => {
  const [showSurvey, setShowSurvey] = useState(false);
  const navigate = useNavigate();
  const { recordingCount } = useAppSelector((state) => state.userHistory);
  const { surveyCompleted } = useAppSelector((state) => state.user.settings);
  const { trackEvent } = useTrackEvent();

  useEffect(() => {
    if (recordingCount > 0) {
      trackEvent('interview_practice', 'reflection_summary', { total_recordings: recordingCount });
    }
  }, [trackEvent, recordingCount]);

  useEffect(() => {
    if (recordingCount === 1 && !surveyCompleted) {
      setShowSurvey(true);
    }

    if (recordingCount > 1 && recordingCount % 3 === 0 && !surveyCompleted) {
      setShowSurvey(true);
    }
  }, [surveyCompleted, recordingCount]);

  const navigateToGoalsHandler = () => {
    navigate('/goals/generate-prompts');
    trackEvent('interview_practice', 'goals_again');
  };

  const navigateToRecordHandler = () => {
    navigate('/record');
    trackEvent('interview_practice', 'record_another');
  };

  return (
    <>
      <SEO title="Thank you for practicing with Authentic!" />

      <Container className="grid max-w-[414px] justify-items-center text-center text-navy-500">
        <SectionHeader heading="Reflection" step={5} />
        <p className="mt-11">
          Thanks for practicing with{' '}
          <span className="text-lg">
            <span className="text-2xl">A</span>UTHENTIC!
          </span>
        </p>
      </Container>

      <div className="mt-6  bg-white px-4 py-6">
        <Container className="flex max-w-[414px] items-center justify-between">
          <div className="flex flex-col gap-2 text-navy-500">
            <p>
              Total Recordings: <span className="font-bold">{recordingCount}</span>
            </p>
            {/* <p>
            Average Duration: <span className="font-bold"></span>
          </p> */}
          </div>
          <div>
            <NavLink
              onClick={() => {
                trackEvent('interview_practice', 'video_archive', {
                  total_recordings: recordingCount,
                });
              }}
              to="/history"
              className="rounded-3xl bg-navy-500 px-4 py-2 text-white"
            >
              View History
            </NavLink>
          </div>
        </Container>
      </div>

      <Container className="grid max-w-[414px] justify-items-center pb-12">
        <div className="mt-6">
          <RecentStats eventType="interview_practice" eventPrefix="reflection" />
        </div>

        <Button onClick={navigateToRecordHandler} className="mt-10 w-full max-w-[320px]">
          Record Again
        </Button>

        <Button
          onClick={navigateToGoalsHandler}
          variant="outline"
          className="mt-6 w-full max-w-[320px]"
        >
          Back to Goal Selection
        </Button>
      </Container>

      <AppSurvey isOpen={showSurvey} onClose={() => setShowSurvey(false)} />

      {/* <ProgressWheelLarge
        className="fixed left-0 top-1/2 -translate-y-1/2"
        step={4}
        message="Reflection"
      /> */}
    </>
  );
};
