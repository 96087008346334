type MessageData = {
  filename: string;
  message: string;
  status: 'Accepted' | 'Error' | 'Done' | 'Retry' | 'InProgress';
  time: string;
  feedback?: {
    eye_gaze_naturalness: string;
    eye_gaze_naturalness_category: string;
    eye_gaze_percent: string;
    filler_percent: string;
    fillers_category: string;
    fillers_top: string;
    head_posture_naturalness: string;
    head_posture_naturalness_category: string;
    head_posture_percent: string;
    pause_percent: string;
    pauses_category: string;
    ConfidenceFeedback: '0' | '1' | '2';
  };
};
export interface Websocket {
  connected: boolean;
  messageData?: MessageData;
  error?: any;
}

export type WebsocketConnectResponse = {
  socket: Websocket;
};

export const CONNECT_WEBSOCKET = 'CONNECT_WEBSOCKET';
export const CONNECTED_WEBSOCKET = 'CONNECTED_WEBSOCKET';
export const DISCONNECTED_WEBSOCKET = 'DISCONNECTED_WEBSOCKET';
export const MESSAGE_RECEIVED = 'MESSAGE_RECEIVED';
export const FAILED_WEBSOCKET = 'FAILED_WEBSOCKET';
export const RESET_MESSAGE_DATA = 'RESET_MESSAGE_DATA';

export interface ConnectWebsocketAction {
  type: typeof CONNECT_WEBSOCKET;
  payload: Websocket;
}

export interface ConnectedWebsocketAction {
  type: typeof CONNECTED_WEBSOCKET;
  payload: Websocket;
}

export interface MessageReceivedAction {
  type: typeof MESSAGE_RECEIVED;
  payload: MessageData;
}

export interface FailedWebsocketAction {
  type: typeof FAILED_WEBSOCKET;
  payload: Websocket;
}

export interface DisconnectWebsocketAction {
  type: typeof DISCONNECTED_WEBSOCKET;
  payload: Websocket;
}

export interface ResetMessageData {
  type: typeof RESET_MESSAGE_DATA;
  payload: Websocket;
}

export type WebsocketActionTypes =
  | ConnectWebsocketAction
  | ConnectedWebsocketAction
  | MessageReceivedAction
  | FailedWebsocketAction
  | DisconnectWebsocketAction
  | ResetMessageData;
