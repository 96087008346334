import clsx from 'clsx';
import {
  updateSelectedPrompt,
  useAppDispatch,
  useAppSelector,
  getGPTPrompts,
} from '../../../redux';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Tab } from '@headlessui/react';
import { Spinner, Button } from '../../../components/Elements';
import { useTrackEvent } from '../../../hooks';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

type PromptSelectProps = {
  onClose: () => void;
};

type FormValues = {
  jobTitle: string;
  industry: string;
  seniorityLevel: string;
};

export const PromptSelect = ({ onClose }: PromptSelectProps) => {
  const dispatch = useAppDispatch();
  const {
    generatedPrompts: { gptPrompts, status: gptPromptsStatus },
    userPrompts: { recentPrompts },
  } = useAppSelector((state) => state.prompts);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  const { trackEvent } = useTrackEvent();

  const selectPromptHandler = (prompt: string) => {
    dispatch(updateSelectedPrompt({ prompt }));
    trackEvent('interview_practice', 'prompt_select', { prompt });
    onClose();
  };

  const selectRecentPromptHandler = (prompt: string, id?: string, practiceCount?: number, practiceDate?: number, userId?: string) => {
    dispatch(updateSelectedPrompt({ prompt, id, practiceCount }));
    onClose();
    trackEvent('interview_practice', 'prompt_reselect', { prompt, id, practiceCount, practiceDate, userId });
  };

  const getPromptsHandler: SubmitHandler<FormValues> = async (formValues) => {
    dispatch(getGPTPrompts(formValues));

    const { jobTitle, industry, seniorityLevel } = formValues;
    trackEvent('interview_practice', 'generate_prompts', {
      job_field: jobTitle,
      industry,
      seniority: seniorityLevel,
    });
  };

  return (
    <div className="p-4">
      <Tab.Group>
        <Tab.List>
          <div className="grid grid-cols-2 font-semibold tracking-wide text-white">
            <Tab
              className={({ selected }) =>
                clsx([
                  'border-b-2 py-2',
                  selected ? 'border-white text-white' : 'border-transparent text-gray-400',
                ])
              }
            >
              Generate Prompts
            </Tab>
            <Tab
              className={({ selected }) =>
                clsx([
                  'border-b-2 py-2',
                  selected ? 'border-white text-white' : 'border-transparent text-gray-400',
                ])
              }
              disabled={recentPrompts.length === 0}
            >
              Recent Prompts
            </Tab>
          </div>
        </Tab.List>
        <Tab.Panels className="h-[500px] overflow-y-auto p-4">
          {/* Generate Prompts Panel */}
          <Tab.Panel>
            {/* Prompt Generation Form TODO: Make reusable component*/}
            <form onSubmit={handleSubmit(getPromptsHandler)}>
              <label className="relative flex flex-col">
                <span className="pb-1 text-white">Job Title</span>
                <input
                  {...register('jobTitle', {
                    required: true,
                  })}
                  className={clsx([
                    'w-full rounded-lg p-2 text-gray-600 shadow-inner shadow-navy-200/50 ',
                  ])}
                  onFocus={() => trackEvent('interview_practice', 'job_title_textbox')}
                />
                {errors.jobTitle?.type === 'required' && (
                  <div className="absolute right-2 bottom-2 z-10 flex items-center gap-1 text-sm text-red-600">
                    <ExclamationTriangleIcon className="w-5" />
                    <span>Required</span>
                  </div>
                )}
              </label>

              <label className="mt-4 flex flex-col">
                <span className="pb-1 text-white">Field/Industry &#40;optional&#41;</span>
                <input
                  {...register('industry')}
                  className="w-full rounded-lg p-2 text-gray-600 shadow-inner shadow-navy-200/50"
                  onFocus={() => trackEvent('interview_practice', 'industry_textbox')}
                />
              </label>

              <label className="mt-4 flex flex-col">
                <span className="pb-1 text-white">Seniority Level &#40;optional&#41;</span>
                <input
                  {...register('seniorityLevel')}
                  className="w-full rounded-lg p-2 text-gray-600 shadow-inner shadow-navy-200/50"
                  onFocus={() => trackEvent('interview_practice', 'seniority_textbox')}
                />
              </label>

              <button
                className="mt-4 w-full rounded-xl bg-primary py-3 px-8 font-semibold text-navy-500 shadow shadow-navy-400"
                type="submit"
              >
                Generate Questions
              </button>
            </form>

            {gptPromptsStatus === 'loading' && (
              <div className="mt-12 grid place-items-center">
                <Spinner className="mb-12" variant="light" size="xl" />
                <p className="text-xl uppercase text-white">Generating Prompts</p>
              </div>
            )}

            {gptPromptsStatus === 'succeeded' && gptPrompts.length > 0 && (
              <div className="grid justify-items-center">
                <h1 className="mt-6 max-w-[24ch] text-center text-lg font-semibold text-white">
                  Select a prompt you would like to practice with.
                </h1>

                <ul className="mt-6 grid gap-4">
                  {gptPrompts.map((prompt, index) => (
                    <li className="" key={index}>
                      <button
                        className="flex w-full rounded-lg bg-white px-4 py-6 text-start text-navy-500"
                        onClick={() => selectPromptHandler(prompt.slice(3))}
                      >
                        {prompt.slice(3)}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            <div className="mt-4 flex justify-center">
              <Button variant="borderless" className="text-white" onClick={onClose}>
                Cancel
              </Button>
            </div>
          </Tab.Panel>

          {/* Recent Prompts Panel */}
          <Tab.Panel className="grid place-items-center">
            <h1 className="max-w-[24ch] text-center text-lg font-semibold text-white">
              Choose a recently practiced prompt.
            </h1>

            <ul className="mt-4 grid gap-4 overflow-auto pb-4">
              {recentPrompts.map(({ prompt, id, practiceDate, practiceCount, userId }) => (
                <li key={id}>
                  <button
                    onClick={() => selectRecentPromptHandler(prompt, id, practiceCount, practiceDate, userId)}
                    className="relative flex w-full rounded-lg bg-white px-3 pt-8 pb-3 text-start text-navy-600"
                  >
                    <span>{prompt}</span>
                    {practiceDate && (
                      <span className="absolute top-3 right-3 text-xs text-gray-500">
                        Last practiced on {/* TODO: Bring in Date formatter Util */}
                        {new Intl.DateTimeFormat('en-US', {
                          month: 'long',
                          day: 'numeric',
                          year: 'numeric',
                        }).format(practiceDate)}
                      </span>
                    )}
                    {practiceCount && (
                      <span className="absolute top-3 left-3 text-xs text-gray-500">
                        Practiced <span className="text-gray-500">{practiceCount}</span> times
                      </span>
                    )}
                  </button>
                </li>
              ))}
            </ul>

            <div className="flex justify-center">
              <Button variant="borderless" className="text-white" onClick={onClose}>
                Cancel
              </Button>
            </div>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};
