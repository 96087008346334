import { useState } from 'react';
import { Modal, Button } from '../../../../components/Elements';
import { AuthHeading } from '../AuthHeading';

// EULA json
import { data as EULA } from './data';

type EulaProps = {
  onAgree: () => void;
};

export const Eula = ({ onAgree }: EulaProps) => {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
  };

  const agreeAndClose = () => {
    onAgree();
    setShow(false);
  };

  return (
    <>
      <button type="button" onClick={handleShow} className="font-raleway font-bold underline" style={{paddingTop: '3px'}}>
        Terms and Conditions
      </button>
      <Modal isOpen={show} onClose={handleClose}>
        <div className="grid font-light text-neutral-600 max-w-[800px] pb-6">
          <button className="absolute top-4 right-4 font-bold" onClick={handleClose}>
            X
          </button>

          <AuthHeading>{EULA.PageHeader}</AuthHeading>

          <p className="mt-6 font-semibold italic">{EULA.Updated}</p>
          <p className="mt-4">{EULA.Intro}</p>
          <p className="mt-4">{EULA.Intro2}</p>

          <h6 className="mt-4 text-lg font-semibold">{EULA.UsageHeader}</h6>
          <p className="mt-2">
            {EULA.UsageText}
            <span>
              <a
                target="_blank"
                rel="noreferrer"
                className="text-blue-700"
                href="https://www.ets.org/legal/privacy"
              >
                {EULA.UsageLink}
              </a>
            </span>
            <span>{EULA.UsageText2}</span>
            <span>{EULA.UsageText3}</span>
            <span>{EULA.UsageText4}</span>
          </p>

          <h6 className="mt-4 text-lg font-semibold">{EULA.GeneralDataUsageHeader}</h6>
          <p className="mt-2">{EULA.GeneralDataUsageText}</p>

          <h6 className="mt-4 text-lg font-semibold">{EULA.VideoDataUsageHeader}</h6>
          <p className="mt-2">{EULA.VideoDataText}</p>

          <h6 className="mt-4 text-lg font-semibold">{EULA.AccountHeader}</h6>
          <p className="mt-2">{EULA.AccountText}</p>

          <h6 className="mt-4 text-lg font-semibold">{EULA.LicenseHeader}</h6>
          <p className="mt-2">{EULA.LicenseText}</p>
          <p>{EULA.LicenseText2}</p>

          <h6 className="mt-4 text-lg font-semibold">{EULA.RestrictionsHeader}</h6>
          <p className="mt-2">{EULA.RestrictionsText}</p>
          <ol className="ml-8 mt-2" style={{ listStyleType: 'lower-alpha' }}>
            <li className="mt-1">{EULA.RestrictionsText2}</li>
            <li className="mt-1">{EULA.RestrictionsText3}</li>
            <li className="mt-1">{EULA.RestrictionsText4}</li>
            <li className="mt-1">{EULA.RestrictionsText5}</li>
            <li className="mt-1">{EULA.RestrictionsText6}</li>
            <li>{EULA.RestrictionsText7}</li>
          </ol>

          <h6 className="mt-4 text-lg font-semibold">{EULA.UpdatesHeader}</h6>
          <p className="mt-2">{EULA.UpdatesText}</p>
          <p className="mt-2">{EULA.UpdatesText2}</p>
          <p className="mt-2">{EULA.UpdatesText3}</p>

          <h6 className="mt-4 text-lg font-semibold">{EULA.TerminationHeader}</h6>
          <p className="mt-2">{EULA.TerminationText}</p>

          <h6 className="mt-4 text-lg font-semibold">{EULA.DisclaimerHeader}</h6>
          <p className="mt-2">{EULA.DisclaimerText}</p>
          <p className="mt-2">{EULA.DisclaimerText2}</p>

          <h6 className="mt-4 text-lg font-semibold">{EULA.ExclusionHeader}</h6>
          <p className="mt-2">{EULA.ExclusionText}</p>
          <p className="mt-2">{EULA.ExclusionText2}</p>

          <h6 className="mt-4 text-lg font-semibold">{EULA.ComplianceHeader}</h6>
          <p className="mt-2">{EULA.ComplianceText}</p>

          <h6 className="mt-4 text-lg font-semibold">{EULA.AssignmentHeader}</h6>
          <p className="mt-2">{EULA.AssignmentText}</p>

          <h6 className="mt-4 text-lg font-semibold">{EULA.WaiverHeader}</h6>
          <p className="mt-2">{EULA.WaiverText}</p>

          <h6 className="mt-4 text-lg font-semibold">{EULA.EulaHeader}</h6>
          <p className="mt-2">{EULA.EulaText}</p>

          <h6 className="mt-4 text-lg font-semibold">{EULA.GoverningHeader}</h6>
          <p className="mt-2">{EULA.GoverningText}</p>

          <Button className="mt-8 w-full max-w-[500px] justify-self-center" onClick={agreeAndClose}>
            Agree To Terms
          </Button>
        </div>
      </Modal>
    </>
  );
};
