import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useAppSelector } from '../../../redux';
import { HistoryItem } from '../../video-archive/components/HistoryItem';
import { RecentStats, SEO } from '../../../components';
import { Container, Spinner } from '../../../components/Elements';
import {
  PresentationChartLineIcon,
  CalculatorIcon,
  CalendarDaysIcon,
} from '@heroicons/react/24/outline';
import { useTrackEvent } from '../../../hooks';

export const Dashboard = () => {
  const { items, status } = useAppSelector((state) => state.userHistory.history);
  const { username } = useAppSelector((state) => state.user);
  const { trackEvent } = useTrackEvent();

  useEffect(() => {
    trackEvent('dashboard', 'dashboard_start');
  }, [trackEvent]);

  let headMoveTotal = 0;
  let pauseTotal = 0;
  let eyeContactTotal = 0;

  items.forEach((item) => {
    const {
      parsedFeedback: {
        service_results: { eye_gaze_percent, pause_percent, head_posture_percent },
      },
    } = item;
    headMoveTotal += +head_posture_percent;
    pauseTotal += +pause_percent;
    eyeContactTotal += +eye_gaze_percent;
  });

  const headMoveAverage = Math.round(headMoveTotal / items.length);
  const pauseAverage = Math.round(pauseTotal / items.length);
  const eyeContactAverage = Math.round(eyeContactTotal / items.length);

  const date = new Date().toLocaleDateString('en-us', {
    weekday: 'long',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
  return (
    <>
      <SEO title="Dashboard" />

      <div className="pb-12 md:px-4">
        <Container className="grid h-full max-w-[1000px] gap-6 md:grid-cols-3">
          <div className="text-navy-500 md:col-span-2">
            <h1 className="text-2xl">
              <span className="font-bold">Welcome Back, </span> <span className="">{username}</span>
            </h1>
            <p className="text-sm text-gray-500">Today is {date}</p>
          </div>

          <h2 className="flex gap-1 text-xl text-navy-500 md:col-span-2">
            <PresentationChartLineIcon className="w-5" />
            <span>Interview Scores</span>
          </h2>

          <h2 className="hidden gap-1 text-xl text-navy-500 md:flex">
            <CalculatorIcon className="w-5" />
            <span>Stats</span>
          </h2>

          <div className="row-span-3 flex items-center justify-center rounded-xl p-4 shadow-md shadow-navy-500/25 md:col-span-2">
            {(status === 'idle' || status === 'loading') && <Spinner />}
            {status === 'succeeded' && items.length > 0 && (
              <RecentStats eventType="dashboard" eventPrefix="dashboard" />
            )}
            {status === 'succeeded' && items.length === 0 && (
              <p className="text-xl">Record your first Interview to view scores and statistics!</p>
            )}
          </div>

          <NavLink
            onClick={() => {
              trackEvent('dashboard', 'dashboard_start_practicing');
            }}
            to="/record"
            className="flex min-h-[52px] items-center justify-center gap-1 rounded-3xl bg-primary text-lg text-navy-500 shadow-sm shadow-navy-500 md:col-start-2 md:hidden"
          >
            <span className="font-medium">Start Practicing!</span>
          </NavLink>

          <h2 className="flex gap-1 text-xl text-navy-500 md:hidden">
            <CalculatorIcon className="w-5" />
            <span>Stats</span>
          </h2>

          <div className="grid min-h-[140px] place-content-center rounded-xl bg-lightblue-400/30 text-center text-navy-500 shadow-md shadow-navy-500/25">
            <h3 className="mb-2 text-gray-500">Average Eye Contact</h3>
            <span className="text-3xl font-bold">{eyeContactAverage || 0}%</span>
          </div>
          <div className="grid min-h-[140px] place-content-center rounded-xl bg-fuchsia-100/30 text-center text-navy-500 shadow-md shadow-navy-500/25">
            <h3 className="mb-2 text-gray-500">Average Head Movement</h3>
            <span className="text-3xl font-bold">{headMoveAverage || 0}%</span>
          </div>
          <div className="grid min-h-[140px] place-content-center rounded-xl bg-primary/30 text-center text-navy-500 shadow-md shadow-navy-500/25">
            <h3 className="mb-2 text-gray-500">Average Pauses</h3>
            <span className="text-3xl font-bold">{pauseAverage || 0}%</span>
          </div>

          <NavLink
            to="/record"
            className="hidden min-h-[52px] items-center justify-center gap-1 rounded-3xl bg-primary text-lg text-navy-500 shadow-sm shadow-navy-500 md:col-start-2 md:flex"
          >
            <span className="font-medium">Start Practicing!</span>
          </NavLink>

          {items.length > 0 && (
            <>
              <div className="col-span-full flex gap-1 text-xl text-navy-500">
                <CalendarDaysIcon className="w-5" />
                <span>Recent Recordings</span>
              </div>

              <div className="col-span-full rounded-xl p-4 shadow-md shadow-navy-500/25">
                <ul className="flex flex-col gap-6">
                  {items.slice(0, 10).map((item) => (
                    <HistoryItem key={item.filename}  {...item} eventType="dashboard" eventPrefix="dashboard"/>
                  ))}
                </ul>
              </div>
            </>
          )}
        </Container>
      </div>
    </>
  );
};
