import { useState, useEffect } from 'react';
import { useAppSelector, useAppDispatch, receivedMessage } from '../../../redux';
import { getRawFeedback } from '../../../utils/mmai';

export const usePolling = (
  frequencySeconds: number,
  maxAttempts: number
): [() => void, boolean] => {
  const [attempts, setAttempts] = useState(0);
  const [start, setStart] = useState(false);
  const [maxAttemptsExceeded, setMaxAttemptsExceeded] = useState(false);

  const { lastRecording } = useAppSelector((state) => state.videoUpload);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!start || !lastRecording) return;

    const timeoutId = window.setTimeout(() => {
      if (attempts < maxAttempts) {
        (async () => {
          try {
            const response = await getRawFeedback(lastRecording.fileName);

            if (response.file_status === 'Accepted' || response.file_status === 'Done') {
              dispatch(
                receivedMessage({
                  feedback: response.service_results,
                  filename: response.filename,
                  status: response.file_status,
                })
              );
            } else if (response.file_status === 'Error' || response.file_status === 'Retry') {
              dispatch(receivedMessage({ status: 'Error' }));
            } else {
              setAttempts((prev) => prev + 1);
            }
          } catch (error) {
            setAttempts((prev) => prev + 1);
          }
        })();
      } else {
        setMaxAttemptsExceeded(true);
      }
    }, frequencySeconds * 1000);

    return () => window.clearTimeout(timeoutId);
  }, [attempts, frequencySeconds, start, maxAttempts, lastRecording, dispatch]);

  const startPolling = () => {
    setStart(true);
  };

  return [startPolling, maxAttemptsExceeded];
};
