export type SectionHeaderProps = {
  heading: string;
  step: number;
};

export const SectionHeader = ({ heading, step }: SectionHeaderProps) => {
  return (
    <h1 className="mt-4 flex items-center text-navy-500">
      <span className="grid h-[51px] w-[51px] place-content-center rounded-full border-3 border-navy-500 bg-white font-open-sans text-4xl font-bold ">
        {step}
      </span>
      <span className="ml-4 text-2xl tracking-wide">{heading}</span>
    </h1>
  );
};
