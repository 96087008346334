import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

type FeedbackTimerState = {
  status: 'idle' | 'counting' | 'complete';
  timerId: number;
  timeElapsed: number;
};

const initialState: FeedbackTimerState = {
  status: 'idle',
  timerId: 0,
  timeElapsed: 0,
};

export const startFeedbackTimer = createAsyncThunk(
  'feedbackTimer/startTimer',
  async (processingTime: number, { dispatch }) => {
    let timer = 0;
    const timerId = window.setInterval(() => {
      timer++;
      dispatch(updateTimer(timer));

      if (timer >= processingTime) {
        dispatch(stopFeedbackTimer());
      }
    }, 1000);
    return timerId;
  }
);

export const feedbackTimerSlice = createSlice({
  name: 'feedbackTimer',
  initialState,
  reducers: {
    resetFeedbackTimerState(state) {
      state.status = 'idle';
      state.timerId = 0;
      state.timeElapsed = 0;
    },
    updateTimer(state, action: PayloadAction<number>) {
      state.timeElapsed = action.payload;
    },
    stopFeedbackTimer(state) {
      window.clearInterval(state.timerId);
      state.status = 'complete';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(startFeedbackTimer.fulfilled, (state, action) => {
      state.status = 'counting';
      state.timerId = action.payload;
    });
  },
});

export const { resetFeedbackTimerState, stopFeedbackTimer, updateTimer } =
  feedbackTimerSlice.actions;
